import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useSalesActivePopupStore } from '@modules/SalesPipline/SalesActivePopup/store';

import { getContactWithSpouseHousehold } from '@services/api/common/common';

import { LeftWrapper,RightWrapper } from '@modules/SalesPipline/SalesActivePopup/styles';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import NextApptDate from '@modules/NextApptDate';
import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from '@modules/SalesPipline/SalesActivePopup/data';

import {
  AMOUNT_INPUT_PROP,
  AMOUNT_TEXT_FIELD_PROP,
  getTextAreaProps,
  TEXT_FIELD_KEY_NOTE_PROP
} from '@constants/salesPipeline';
import {
  SALES_ACTIVE_CYCLE_FIELD_NAMES,
  SALES_ACTIVE_CYCLE_MEETING_STATUS_OPTIONS,
  SALES_ACTIVE_CYCLE_STAGES_OPTIONS,
  SALES_ACTIVE_CYCLE_STATUS_OPTIONS
} from '@constants/salesActiveCycle';

import { SalesActiveCycleFormFields } from '@modules/SalesPipline/SalesActivePopup/types';

import { getAppointmentDateLabel } from '@/shared/utils/getApptDateLabel';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';


const LinkedContactFetchProps = {
  getParams: getNameParam,
  requestSender: getContactWithSpouseHousehold,
  responseConverter: usersResponseConverter,
};

const INPUT_TEXT_AREA_PROPS  = getTextAreaProps();

export const PrimaryDetails = observer(() => {
  const routerStore = useStore().RouterStore;
  const { control } = useFormContext<SalesActiveCycleFormFields>();

  const {
    closeTrigger,
    linkedContactData,
    nextAppointmentDate,
    notesUtilsStore,
    onLinkedContactChange,
    resetState
  } = useSalesActivePopupStore();

  const onContactClick = useCallback(() => {
    if(linkedContactData && closeTrigger) {
      closeTrigger();
      resetState();
  
      routerStore.moveToContact({
        id: linkedContactData?.id,
        contactType: linkedContactData?.type,
        tab: CONTACT_DETAILS_TAB.overview,
      });  
    }
  }, [linkedContactData]);

  const appointmentDateLabel = getAppointmentDateLabel(nextAppointmentDate);

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Primary Details' } />
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_LABEL_WIDTH }
          isRequired={ true }
          label={ 'Linked Contact' }
        >
          <HFLinkedContact
            FetchProps={ LinkedContactFetchProps }
            UserItemProps={ {
              $isClickable: true,
              disabled: notesUtilsStore.isNotesPanelInAddOrEditMode,
              onClick: onContactClick
            } }
            control={ control }
            disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
            initLinkedContact={ linkedContactData }
            name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.contactId }
            onLinkedContactChange={ onLinkedContactChange }
          />
        </FormBlock.FormField>

      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            isRequired={ true }
            label={ 'Cycle Status' }
          >
            <HFSelect
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus }
              options={ SALES_ACTIVE_CYCLE_STATUS_OPTIONS }
              renderItem={ SaleCycleStatusOption }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            isRequired={ true }
            label={ 'Stage' }
          >
            <HFSelect
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.stage }
              options={ SALES_ACTIVE_CYCLE_STAGES_OPTIONS }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Amount ($)' }
          >
            <HFTextInput
              inputProps={ AMOUNT_INPUT_PROP }
              TextFieldProps={ AMOUNT_TEXT_FIELD_PROP }
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.amount }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Cycle Open Date' }
          >
            <HFDatePicker
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.cycleOpenDate }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Meeting Status' }
          >
            {
              <HFSelect
                control={ control }
                disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
                name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus }
                options={ SALES_ACTIVE_CYCLE_MEETING_STATUS_OPTIONS }
                resettable={ true }
              />
            }
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ appointmentDateLabel }
          >
            <FormBlock.VerticalCenteredWrapper>
              <NextApptDate
                date={ nextAppointmentDate }
                disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
                fallBack={ '-' }
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_LABEL_WIDTH }
          label={ 'Key Note/Next Step ' }
        >
          <HFTextInput
            inputProps={ INPUT_TEXT_AREA_PROPS }
            TextFieldProps={ TEXT_FIELD_KEY_NOTE_PROP }
            control={ control }
            disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
            name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.keyNote }
            placeholder={ 'Key Note/Next Step' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

