import { ADD_CONTACT_TAGS_TYPES } from '@/modules/AddContactModal/addContactStore/data/constantData';
import { CONTACT_TYPES, RELATION_TYPES } from '@constants/common';
import { DEFAULT_STEP_DATA } from '@/modules/AddContactModal/addContactStore/data/constantData';
import { FIELD_IDS } from '@constants/contactsData';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';
import { removeDuplicates as rd } from '@/shared/utils/removeDuplicates';
import { removeFalseValues } from '@/shared/utils/removeFalseValues';

import {
  BusinessPrimaryPointOfContact,
  Contacts,
  AddNewContactFieldTagItem,
  SavedTagsList,
  TagsList,
  HouseholdMemberBasicInformation
} from '@/shared/types/addNewContactPopup';
import { ContactRelatedItem } from '@/shared/types/contact';
import { LinkedContact } from '@/shared/types/linkedContact';
import {
  ContactsPayload,
  HouseholderContactsPayload,
  RelatedContactsPayload,
  RelatedEmployeesPayload
} from '@/modules/AddContactModal/addContactStore/types';


export const formatContacts = (data: Contacts): ContactsPayload => {
  const fields = [
    {
      fieldId: FIELD_IDS.MAILING_LOCATION_ID,
      country: data.address.country || null,
      state: data.address.state || null,
      zip: data.address.zip || null,
      city: data.address.city || null,
      street: data.mailingLocation || null,
      street2: data.addressLine2 || null,
    },
    {
      fieldId: FIELD_IDS.MOBILE_PHONE_ID,
      // TODO by default the phone number must be set NULL if only the country code is entered
      value: getIsOnlyCountryCode(data.mobilePhone) ? null : data.mobilePhone
    },
    {
      fieldId: FIELD_IDS.WORK_EMAIL_ID,
      value: data.email || null
    },
    {
      ...('preference' in data ? {
        fieldId: FIELD_IDS.CONTACT_PREFERENCE_ID,
        value: data.preference || null
      } : {})
    }
  ];
  return fields.filter(({ fieldId, ...otherProps }) => Object.values(otherProps).some(prop => prop !== null));
};

export const formatRules = <Rules extends Record<string, boolean>>(rules: Rules): { [K in keyof Rules]: number } => {
  const result = {} as { [K in keyof Rules]: number };
  for (const key in rules) {
    result[key] = Number(!rules[key]);
  }
  return result;
};

export const formatHouseholderContacts = (
  main: HouseholdMemberBasicInformation,
  spouse: HouseholdMemberBasicInformation,
  communicateWith: string | number,
  contacts: ContactsPayload
): HouseholderContactsPayload => {
  const getCommunicate = (index: number) => {
    if(communicateWith === 'Both'){
      return 'Yes';
    }
    return booleanToYesNo(index + 1 === communicateWith);
  };

  return [main, spouse].map((contact, index) => ({
    ...removeFalseValues(contact),
    householdOwner: booleanToYesNo(contact.householdOwner),
    contacts: contact.householdOwner ? contacts : [],
    communicateWith: getCommunicate(index),
  }));
};

export const formatRelatedContacts = (
  relatedContacts: Array<ContactRelatedItem>, spouseContact: ContactRelatedItem | null
): RelatedContactsPayload => {
  const formattedContacts = relatedContacts.map(({ id, relationType }) => ({
    relativeId: id,
    relationType
  }));

  if(spouseContact) {
    formattedContacts.push({
      relativeId: spouseContact.id,
      relationType: RELATION_TYPES.SPOUSE
    });
  }

  return formattedContacts;
};

export const formatNewContact = (
  data: BusinessPrimaryPointOfContact, linkedContact: LinkedContact | null
): RelatedEmployeesPayload => {
  const relatedEmployeesContact = [];

  if(!data.newContact && linkedContact) {
    return {
      employeeId: linkedContact.id,
      primaryContact: 'Yes'
    };
  }

  if(!getIsOnlyCountryCode(data.phoneNumber)) {
    relatedEmployeesContact.push({
      fieldId: data.phoneType,
      // TODO by default the phone number must be set NULL if only the country code is entered
      value: data.phoneNumber
    });
  }

  if(data.email) {
    relatedEmployeesContact.push({
      fieldId: data.emailType,
      value: data.email
    });
  }

  return [{
    firstName: data.firstName,
    lastName: data.lastName,
    primaryContact: 'Yes',
    relatedEmployeesContact
  }];
};

export const formatTags = ({ interest, general }: TagsList): Array<SavedTagsList> => {
  const formattedTags: Array<SavedTagsList> = [];

  [rd(interest, 'label'), rd(general, 'label')].forEach((tagsArray: Array<AddNewContactFieldTagItem>, index) => {
    tagsArray.forEach(({ type, label, id }) => {
      if(type === ADD_CONTACT_TAGS_TYPES.new) {
        const typeFormat = index ? ADD_CONTACT_TAGS_TYPES.general : ADD_CONTACT_TAGS_TYPES.interest;
        return formattedTags.push({ type: typeFormat, label });
      }
      formattedTags.push({ id });
    });
  });

  return formattedTags;
};

export const getStepData = (contactType: CONTACT_TYPES) => (
  DEFAULT_STEP_DATA[contactType]
);
