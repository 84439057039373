import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useDrag } from 'react-dnd';

import { useStore } from '@services/store/store';

import AvatarLine from '@components/AvatarLine';
import DotsMenu from '@components/DotsMenu';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';
import ViewAllContacts from '@modules/Lists/ViewAllContacts';

import {
  ListsRowItemProps
} from '@pages/NewContacts/subpages/Lists/subpages/AllLists/components/ListsTable/components/ListsTableRow/types';
import { ListsItemDotsMenuItemTypes } from './types';
import { ListDragObject } from '@/shared/types/lists';

import { LIST_ITEM_DOTS_MENU_TYPES, getMenuOptions } from './data';
import { LISTS_TYPES, TYPE_FIELDS } from '@constants/lists';
import { Roles } from '@constants/common';

import { getAvatarData, AvatarData } from '@/shared/utils/getAvatarData';
import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

export const ListsRowItem = ({
  filters,
  isCheckboxActive,
  item,
  onAddContacts,
  onCheckboxClickCb,
  onClone,
  onEditSetup,
  onExport,
  onMove,
  onRemove,
  onRowBookmarkCb,
  onViewContacts,
}: ListsRowItemProps) => {

  const { profile } = useStore().SettingsStore;

  const [, dragRef] = useDrag<ListDragObject>({
    type: TYPE_FIELDS.List,
    item: {
      listId: item.id,
    }
  });

  const contactAvatarData = useMemo(() => {
    if(item.typeList === LISTS_TYPES.Dynamic || item.typeList === LISTS_TYPES.Manual){
      return convertObjectWithNumericKeysToArray<AvatarData>(item.contactData, getAvatarData);
    }
    return [];
  }, [item]);

  const menuItems = useMemo(() => {
    return getMenuOptions(item, profile.userRole[0] as Roles);
  }, [item, profile]);

  const onCheckboxClick = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => (
    onCheckboxClickCb(item)(event,checked)
  ),[onCheckboxClickCb, item]);

  const onRowDoubleClick = useCallback(() => {
    onViewContacts(item);
  },[item, onViewContacts]);

  const onBookmarkClick = useCallback((state: boolean) => (
    onRowBookmarkCb(item.id, state)
  ),[onRowBookmarkCb, item]);

  const onMenuSelect = useCallback((action: ListsItemDotsMenuItemTypes) => {
    if(action === LIST_ITEM_DOTS_MENU_TYPES.add) {
      onAddContacts(item);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.clone) {
      onClone(item.id);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.remove) {
      onRemove(item.id);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.edit) {
      onEditSetup(item);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.move) {
      onMove(item);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.view) {
      onViewContacts(item);
    }
    if(action === LIST_ITEM_DOTS_MENU_TYPES.export) {
      onExport(item);
    }

  }, [item, onAddContacts, onMove, onClone, onEditSetup, onExport, onRemove, onViewContacts]);

  return (
    <Table.Row
      onDoubleClick={ onRowDoubleClick }
      ref={ dragRef }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: Boolean(item?.flagged),
          onStateChange: onBookmarkClick
        } }
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClick
        } }
      />
      <Table.Cell>
        <TextWithTooltip text={ item.name } />
      </Table.Cell>
      <Table.Cell>{item.typeList}</Table.Cell>
      <Table.Cell>
        <UserItem
          user={ item.listOwnerData }
        />
      </Table.Cell>
      <Table.Cell>
        {
          (item.typeList === LISTS_TYPES.Dynamic || item.typeList === LISTS_TYPES.Manual) &&
          <>
            <AvatarLine
              count={ item.contactData.count }
              isHideCounter={ item.contactData.count <= 0 && contactAvatarData.length > 0 }
              items={ contactAvatarData }
            />
            {
              item.contactData.count > 0 &&
              <ViewAllContacts
                filters={ filters }
                listId={ String(item.id) }
              />
            }
          </>
        }
        <DotsMenu<ListsItemDotsMenuItemTypes>
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuItems }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};
