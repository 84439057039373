import { differenceInDays, endOfDay, format, isBefore } from 'date-fns';

import { convertedDatesToCurrentTimezone } from '@/shared/utils/convertedDatesToCurrentTimezone';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import { ContactActivity } from '@services/store/contactDetailsActivitiesStore/types';

// need to include current day to 'days before activity' counter
const ONE_DAY = 1;

export const getActivityStartDateFields = (
  activity: ContactActivity, accountTimeZone: AccountTimeZone, timeZones: TimeZones
) => {

  const { createdDate, startDate, startTime, endDate, endTime, allDay, createdTimeZone } = activity;
  const dates = convertedDatesToCurrentTimezone({
    createdDate: createdDate || new Date(`${startDate}T${startTime}Z`),
    startDate: startDate,
    startTime: startTime,
    endDate: endDate,
    endTime: endTime,
    allDay: yesNoToBoolean(allDay),
    createdTimeZone: timeZones.find(timeZone => timeZone.value === createdTimeZone) || accountTimeZone
  }, accountTimeZone);
  
  const UTCActivityStartDate = dates.startDate;

  const isBeforeCurrentDate = isBefore(UTCActivityStartDate, endOfDay(new Date()));
  const activityStartDate = isBeforeCurrentDate ? new Date() : UTCActivityStartDate;
  const activityDate = format(activityStartDate, MONTH_DAY_YEAR_BACKSLASH);

  const numberOfDaysBeforeActivity = differenceInDays(new Date(activityDate), new Date()) + ONE_DAY;
  const typeName = numberOfDaysBeforeActivity > 1 ? 'days' : 'day';
  
  return {
    activityDate,
    numberOfDaysBeforeActivity: isBeforeCurrentDate ? 0 : numberOfDaysBeforeActivity,
    typeName
  };
};