import axios, { AxiosResponse } from 'axios';
import { action, flow, makeAutoObservable } from 'mobx';
import { isEmpty } from 'lodash';

import { Store } from '@store';

import LimitAccessPopup from '@modules/LimitAccessPopup';

import { CONTACT_TYPES, ENTITY_NAMES } from '@constants/common';
import { FIELD_IDS } from '@constants/contactsData';
import { MODAL_TYPE } from '@constants/modalTypes';
import { ROUTES } from '@constants/routes';

import { IdType } from '@/shared/types/commonTypes';
import {
  AddSpouseData,
  BusinessFormFields,
  ContactFormFields,
  IndividualFormFields,
  SplitHouseHoldData,
  UpdateResponse,
  UseFormMethods
} from './types/common';
import {
  ContactItem,
  HouseHoldContactItem,
  IndividualContactItem
} from '@/shared/types/contact';
import { ContactsData } from '@/shared/types/contactsData';

import {
  BusinessOptionsResponse,
  BusinessOptionsType,
  ConvertedPrimaryAdvisor,
  HouseholdOptionsResponse,
  HouseholdOptionsType,
  IndividualOptionsResponse,
  IndividualOptionsType,
  InitAdvisors
} from '@/shared/types/contactOptionsTypes';

import {
  addSpouse,
  getContactBusinessOptions,
  getContactHouseholdOptions,
  getContactIndividualOptions,
  splitHouseHold,
  updateContact
} from '@services/api/contacts/contacts';

import {
  businessOptionsNormalizer,
  houseHoldOptionsNormalizer,
  individualOptionsNormalizer
} from '@/shared/utils/contactOptionsNormalizers';
import { contactNormalizer } from '@services/store/contactDetailsProfileStore/normalizers/contactNormalizer';

import { convertToFormData } from '@services/store/contactDetailsProfileStore/utils';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { NOTIFICATION_TYPES } from '@constants/notifications';


export class ContactDetailsProfileStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  contact: ContactItem | null = null;
  coreStore: Store;
  householdMembers: Array<IndividualContactItem> = [];
  initAdvisors: InitAdvisors | null = null;
  notificationHelper: NotificationHelper;
  optionsForBusiness: BusinessOptionsType | null = null;
  optionsForHousehold: HouseholdOptionsType | null = null;
  optionsForIndividual: IndividualOptionsType | null = null;
  showSaveBar: boolean = false;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      setHouseholdMembers: action,
      init: flow,
      loadContactOptions: flow,
      onConfirmSave: flow,
      updateBusiness: flow,
      updateHouseHold: flow,
      updateIndividual: flow,
    });
    this.coreStore = coreStore;

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.contact
    );
  }

  async *init(id: IdType, useFormMethods: UseFormMethods){
    if(Object.keys(this.useFormMethods).length === 0) {
      this.useFormMethods = useFormMethods;
    }

    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const start = async () => {
        const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(id);
        let contactData =  contactDetailsStore.currentContact;

        if(isNeedNewContact) {
          const newContactData: ContactItem = await contactDetailsStore.getContact(id);
          contactData = newContactData;
          await contactDetailsStore.setCurrentContact(newContactData);
        }

        await this.loadContactOptions(contactData!.type);

        const currentStoreContact: ContactItem = isNeedNewContact
          ? contactData as ContactItem
          :  await contactDetailsStore.getContact(id);

        this.contact = contactNormalizer(currentStoreContact);
        
        if(this.contact?.type !== CONTACT_TYPES.HOUSEHOLD){
          this.contact.contacts = this.getGroupedContacts(this.contact.contacts);
        }

        if(this.contact?.type === CONTACT_TYPES.HOUSEHOLD){
          this.setHouseholdMembers();
          this.setHouseHoldContactDetails();
        }

        await this.useFormMethods.reset(this.contact);
        this.setInitAdvisors(contactData!.type);
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: (error: any) => {
          this.notificationHelper.load({ status: NOTIFICATION_TYPES.error });
          if(axios.isAxiosError(error) && error?.response?.status === 400) {
            this.coreStore.ContactDetailsStore.setPermission(false);
            this.coreStore.ContactDetailsStore.currentContact = null;
          }
        }
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.detectLimitAccess(this.contact?.editAllow);
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *loadContactOptions(type: CONTACT_TYPES) {
    try {
      if (type === CONTACT_TYPES.BUSINESS && !this.optionsForBusiness) {
        const response: BusinessOptionsResponse = yield getContactBusinessOptions();
        this.optionsForBusiness = businessOptionsNormalizer(response.data.data);
      }
      if (type === CONTACT_TYPES.HOUSEHOLD && !this.optionsForHousehold) {
        const response: HouseholdOptionsResponse = yield getContactHouseholdOptions();
        this.optionsForHousehold = houseHoldOptionsNormalizer(response.data.data);
      }
      if (type === CONTACT_TYPES.INDIVIDUAL && !this.optionsForIndividual) {
        const response: IndividualOptionsResponse = yield getContactIndividualOptions();
        this.optionsForIndividual = individualOptionsNormalizer(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  *addSpouse(data: AddSpouseData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const currentContactId = contactDetailsStore.currentContact!.id;
    try {
      contactDetailsStore.toggleLoadState(true);
      const response: AxiosResponse<{data: IndividualContactItem}> = yield addSpouse({
        id: currentContactId,
        ...data
      });

      const contact: IndividualContactItem = yield contactDetailsStore.getContact(currentContactId);
      contactDetailsStore.setCurrentContact(contact);

      this.contact = contact;
    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.coreStore.ContactDetailsStore.setPermission(false);
        this.coreStore.ContactDetailsStore.currentContact = null;
      }
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *splitHouseHold(data: SplitHouseHoldData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    try {
      contactDetailsStore.toggleLoadState(true);
      yield splitHouseHold({
        id:contactDetailsStore.currentContact!.id,
        ...data,
      });

      this.coreStore.RouterStore.customPush({
        pathname: ROUTES.contactsAll
      });

      if(!isEmpty(this.coreStore.AppStore.lastViewedContacts)) {
        const lastViewedContacts = this.coreStore.AppStore.lastViewedContacts;
        const isOpenedDeletedContact = lastViewedContacts.some((contact) => (
          contact.id === contactDetailsStore.currentContact!.id
        ));
        if(isOpenedDeletedContact) {
          this.coreStore.AppStore.getRecentContacts();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *onConfirmSave(data: ContactFormFields){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    this.showSaveBar = false;

    const asyncWrapper = async (func: () => any) => {
      await this.asyncRequestExecutor.wrapAsyncOperation({
        func,
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success })
      });
    };

    try {
      contactDetailsStore.toggleLoadState(true);
      if(data.type === CONTACT_TYPES.BUSINESS){
        yield asyncWrapper(() => this.updateBusiness(data));
      }

      if(data.type === CONTACT_TYPES.HOUSEHOLD){
        yield asyncWrapper(() => this.updateHouseHold(data));
      }

      if(data.type === CONTACT_TYPES.INDIVIDUAL){
        yield asyncWrapper(() => this.updateIndividual(data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateBusiness(data: BusinessFormFields){
    const resp: UpdateResponse<BusinessFormFields> = yield updateContact(convertToFormData(data));
    const businessResponseData = resp.data.data[0];

    this.detectLimitAccess(businessResponseData.editAllow);

    this.coreStore.ContactDetailsStore.setCurrentContact(businessResponseData);
    this.contact = businessResponseData;
    this.setInitAdvisors(this.contact!.type);
    
    this.useFormMethods.reset(businessResponseData);
  }

  *updateHouseHold(data: HouseHoldContactItem){
    const onlyHouseHoldData = data as HouseHoldContactItem;

    if(!isEmpty(onlyHouseHoldData.contacts)) {
      data.householderContacts.forEach(householdMember => {
        const newContactsDetails = data.contacts.filter(contact => contact.ownerId === householdMember.id);
        householdMember.contacts.push(...newContactsDetails);
      });
      data.contacts = [];
    }

    const response: UpdateResponse<IndividualFormFields> = yield updateContact(convertToFormData(onlyHouseHoldData));
    const householdResponseData = response.data.data[0];

    this.detectLimitAccess(householdResponseData.editAllow);

    this.coreStore.ContactDetailsStore.setCurrentContact(householdResponseData);
    yield this.contact = contactNormalizer(householdResponseData);

    this.setHouseholdMembers();
    this.setHouseHoldContactDetails();
    this.setInitAdvisors(this.contact!.type);

    this.useFormMethods.reset({
      ...this.contact,
    });
  }

  *updateIndividual(data: IndividualFormFields) {
    const resp: UpdateResponse<IndividualContactItem> = yield updateContact(convertToFormData(data));
    const individualResponseData = resp.data.data[0];

    this.detectLimitAccess(individualResponseData.editAllow);

    this.coreStore.ContactDetailsStore.setCurrentContact(individualResponseData);
    this.contact = individualResponseData;
    this.contact.contacts = this.getGroupedContacts(this.contact.contacts);
    this.setInitAdvisors(this.contact!.type);

    this.useFormMethods.reset(this.contact);
  }

  getGroupedContacts(contacts: ContactsData) {
    const groupedContacts = [] as ContactsData;
    Object.values(FIELD_IDS).forEach(id => {
      // eslint-disable-next-line array-callback-return
      contacts.filter(field => {
        if(field.fieldId === id) {
          groupedContacts.push(field);
        }
      });
    });
    return groupedContacts;
  }

  detectLimitAccess(editAllow?: number) {
    const isLimitAccess = typeof editAllow === 'number' && !editAllow;

    if(isLimitAccess){
      this.coreStore.ModalStore.openModal({
        modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
        component: LimitAccessPopup,
        modalProps: {}
      });
    }
  }

  setHouseholdMembers() {
    if(this.contact && this.contact.type === CONTACT_TYPES.HOUSEHOLD){
      this.householdMembers = this.contact.householderContacts;

      this.householdMembers.forEach((member, index) => {
        (this.coreStore.ContactDetailsStore.currentContact as HouseHoldContactItem)
          .householderContacts[index].firstName = member.firstName;
    
        (this.coreStore.ContactDetailsStore.currentContact as HouseHoldContactItem)
          .householderContacts[index].lastName = member.lastName;
    
        (this.coreStore.ContactDetailsStore.currentContact as HouseHoldContactItem)
          .householderContacts[index].photo = member.photo;
      });
    }
  }

  setHouseHoldContactDetails() {
    if(this.contact && this.contact.type === CONTACT_TYPES.HOUSEHOLD) {

      this.contact.householderContacts.forEach((householdMember => {
        if(!isEmpty(householdMember.contacts)) {
          const contactId = householdMember.id;
          householdMember.contacts = householdMember.contacts.map((contact) => {
            contact.ownerId = contactId;
            return contact;
          });
          householdMember.contacts = this.getGroupedContacts(householdMember.contacts);
        }
      }));
    }
  }

  setInitAdvisors(type: IdType | null) {
    let options = [] as Array<ConvertedPrimaryAdvisor>;
    if (type === CONTACT_TYPES.BUSINESS && this.optionsForBusiness) {
      options = this.optionsForBusiness.primaryAdvisers;
    }
    if (type === CONTACT_TYPES.HOUSEHOLD && this.optionsForHousehold) {
      options = this.optionsForHousehold.primaryAdvisers;
    }
    if (type === CONTACT_TYPES.INDIVIDUAL && this.optionsForIndividual) {
      options = this.optionsForIndividual.primaryAdvisers;
    }

    if(!isEmpty(options)) {
      this.initAdvisors = {
        primary: options.filter(user => user.id === this.contact?.officePrimaryAdvisor)[0] || null,
        secondary: options.filter(user => user.id === this.contact?.officeSecondaryAdvisor)[0] || null,
        primaryCsr: options.filter(user => user.id === this.contact?.officePrimaryCsr)[0] || null,
        secondaryCsr: options.filter(user => user.id === this.contact?.officeSecondaryCsr)[0] || null
      };
    }
  }

  onCancel(){
    if (this.contact!.type === CONTACT_TYPES.HOUSEHOLD){
      this.useFormMethods.reset({
        ...this.contact,
      });
    } else  {
      this.useFormMethods.reset(this.contact);
    }

    this.showSaveBar = false;
  }

  resetStateOnTabSwitch() {
    if(this.showSaveBar){
      this.onCancel();
    }
  }

  resetState() {
    this.contact = null;
    this.householdMembers = [];
    this.optionsForBusiness = null;
    this.optionsForHousehold = null;
    this.optionsForIndividual = null;
    this.initAdvisors = null;
    this.showSaveBar = false;
    this.useFormMethods = {} as UseFormMethods;
  }

  toggleShowBarVisibility(newState: boolean){
    this.showSaveBar = newState;
  }
}
