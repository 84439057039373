import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import Button from '@material-ui/core/Button';

import { StyledBubbleButtonProps } from './types';

export const StyledBubbleButton = styled(Button)<StyledBubbleButtonProps>`
  min-width: auto;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 12px;
  
  ${({ $isActive, $isSecondActiveStyle }) => !$isActive && !$isSecondActiveStyle && css`
    color: ${COLORS.primaryText};
    background-color: ${COLORS.background};
  `};
  
  ${({ $isActive }) => $isActive && css`
    background-color: ${COLORS.orange};
    color: ${COLORS.white};

    & svg path {
      fill: ${ COLORS.white };
    }
  `};

  ${({ $isSecondActiveStyle }) => $isSecondActiveStyle && css`
    color: ${COLORS.primaryText};
    background-color: ${COLORS.borders};
  `};
  
  &:hover{
    color: ${COLORS.primaryText};
    background-color: ${COLORS.select};

    & svg path {
      fill: ${ COLORS.primaryText };
    }
  }
`;
