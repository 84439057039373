import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import TextField from '@material-ui/core/TextField';

export const StyledTextField = styled(TextField)`
  min-width: 500px;
  
  & .MuiInputBase-root{
    position: relative;
    border-radius: 20px;
    height: 42px;
    background-color: ${COLORS.white};
    font-size: 16px;
    padding-left: 16px !important;
  }

  & .MuiInputBase-input::placeholder {
    color: ${COLORS.grey};
  }

  /* ignore LastPass extension, customer issue */
  & div[data-lastpass-icon-root],
  & div[data-lastpass-root] {
    display: none;
  }
`;

export const CrossIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 50px;

  & svg path {
    fill: ${COLORS.orange};
  }
`;

export const SearchWrapper = styled.div`
  width: 34px;
  height: 34px;
  background-color: ${COLORS.white};
  
  display: grid;
  place-items: center;
  
  margin-left: 12px;
  margin-right: -4px;
`;
