import { ValueLabelObj } from '@/shared/types/commonTypes';

export const TIME_BUTTONS_CONFIG: Array<ValueLabelObj<number, string>> = [
  {
    value: 15,
    label: '15m',
  },
  {
    value: 30,
    label: '30m',
  },
  {
    value: 45,
    label: '45m',
  },
  {
    value: 60,
    label: '1hr',
  },
  {
    value: 120,
    label: '2hr',
  },
];
