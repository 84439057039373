import { TrashFiltersNames } from '@pages/Trash/types';
import { ValueLabelObj } from '@/shared/types/commonTypes';

export const CLASSIFICATION: FilterTypes = {
  name: TrashFiltersNames.Classification,
  label: 'Core Classification',
  values: []
};

export const USERS: FilterTypes = {
  name: TrashFiltersNames.Users,
  label: 'Users',
  values: []
};

export const OFFICE_PRIMARY_ADVISOR: FilterTypes = {
  name: TrashFiltersNames.Office_Primary_Advisor,
  label: 'Users',
  values: []
};

export const ASSIGNED_TO: FilterTypes = {
  name: TrashFiltersNames.Clients,
  label: 'Assigned To',
  values: []
};

export const TYPE: FilterTypes = {
  name: TrashFiltersNames.Type,
  label: 'Activity Type',
  values: []
};

export const LIST_TYPE: FilterTypes = {
  name: TrashFiltersNames.List_Type,
  label: 'List Type',
  values: []
};

export const ACCOUNT_TYPE: FilterTypes = {
  name: TrashFiltersNames.Account_Type,
  label: 'Type',
  values: []
};

export const CATEGORY: FilterTypes = {
  name: TrashFiltersNames.Category,
  label: 'Task Type',
  values: []
};

export const SALES_CYCLES_STATUS: FilterTypes = {
  name: TrashFiltersNames.Sales_Cycle_Status,
  label: 'Cycle Status',
  values: []
};

export const SALES_CYCLES_MEETING_STATUS: FilterTypes = {
  name: TrashFiltersNames.Meeting_Status,
  label: 'Meeting Status',
  values: []
};

export const CLIENT_OPPORTUNITIES_SALE_TYPE: FilterTypes = {
  name: TrashFiltersNames.Sale_Type,
  label: 'Sale Type',
  values: []
};

export const CLIENT_OPPORTUNITIES_STATUS: FilterTypes = {
  name: TrashFiltersNames.Opportunity_Status,
  label: 'Opportunity Status',
  values: []
};

export const CLIENT_OPPORTUNITIES_MEETING_STATUS: FilterTypes = {
  name: TrashFiltersNames.Meeting_Status,
  label: 'Meeting Status',
  values: []
};

export type FilterTypes = {
  // eslint-disable-next-line no-restricted-globals
  name: TrashFiltersNames;
  label: string;
  values: Array<ValueLabelObj>;
};