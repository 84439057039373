import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import { ENTITY_NAMES } from '@constants/common';

export const getAccountAndPlanEntityName = (entity: ACCOUNTS_AND_PLANS_TYPES) => {
  switch (entity) {
  case ACCOUNTS_AND_PLANS_TYPES.ACCOUNT:
    return ENTITY_NAMES.account;
  case ACCOUNTS_AND_PLANS_TYPES.POLICY:
    return ENTITY_NAMES.policy;
  case ACCOUNTS_AND_PLANS_TYPES.PLAN:
    return ENTITY_NAMES.plan;
  default:
    console.log('Other variant');
    break;
  }
};