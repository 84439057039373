import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Image = styled.img`
  width: 600px;
  height: fit-content;
  margin-bottom: 52px;
`;

export const NoPermissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  background: ${COLORS.white};
  padding: 45px 0;

  & button {
    margin: 40px 0;
  }
`;

export const TextWrapper = styled.p`
  margin: 0;
  font-size: 22px;
  color: ${COLORS.grey};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
`;

export const MarkedText = styled.span`
  color: ${COLORS.primaryText};
`;
