import { CONTACT_TYPES } from '@/shared/constants/common';

//eslint-disable-next-line
export const INDIVIDUAL_TYPE_BUTTON_TOOLTIP = 'A single individual, or two linked spouses who you want to view as individual, separated records.';
//eslint-disable-next-line
export const HOUSEHOLD_TYPE_BUTTON_TOOLTIP = 'Two spouses who handle their finances together as a household, combined and viewed as a single record.';

export const CONTACT_TYPES_BUTTONS = [{
  label: CONTACT_TYPES.INDIVIDUAL,
  tooltip: INDIVIDUAL_TYPE_BUTTON_TOOLTIP,
  value: CONTACT_TYPES.INDIVIDUAL
},
{
  label: CONTACT_TYPES.HOUSEHOLD,
  tooltip: HOUSEHOLD_TYPE_BUTTON_TOOLTIP,
  value: CONTACT_TYPES.HOUSEHOLD
},
{
  value: CONTACT_TYPES.BUSINESS,
  label: CONTACT_TYPES.BUSINESS
}];
