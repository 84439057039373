import { flow, makeAutoObservable } from 'mobx';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { Store } from '@store';

import { deleteSalesCycle, saveSalesActiveCyclePipeline } from '@services/api/salesPipeline/salesActiveCycle';

import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { CloseModal } from '@/shared/types/commonTypes';
import { SalesCycleDeleteData, SaveSalesActiveCyclePipelineData } from '@/shared/types/salesActiveCycle';

export default class SalesCycleStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  coreStore: Store;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      saveSalesCycle: flow.bound,
      removeSalesCycle: flow.bound
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.salesCycle
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *saveSalesCycle(closeModal: CloseModal, data: SaveSalesActiveCyclePipelineData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      closeModal();
  
      const uniqueKey = data?.id || new Date().getTime();
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveSalesActiveCyclePipeline(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: true,
          uniqueKey
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: false,
          uniqueKey
        })
      });
      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *removeSalesCycle(data: SalesCycleDeleteData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const idsToDelete = Array.isArray(data) ? data : [data];

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => deleteSalesCycle({
          ids: idsToDelete
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          isAutoUniqueKey: true
        })
      });
      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
}