import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTACT_TYPES } from '@constants/common';
import { LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH } from '@/modules/AddContactModal/data';

import { maxInputLength } from '@/shared/utils/maxInputLength';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import { LeftColumnWrapper, RightColumnWrapper } from '@/modules/AddContactModal/components';


export const PersonalInformation = () => {
  const { options } = useAddContactPopupStore();

  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom={ false }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Personal Information' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'First Name' }
          >
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name={ '1.firstName' }
              placeholder={ 'First Name' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Last Name' }
          >
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name={ '1.lastName' }
              placeholder={ 'Last Name' }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Nickname' }
          >
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name={ '1.nickname' }
              placeholder={ 'Enter Nickname' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Birthday' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }            
              control={ control }
              name={ '1.birthdate' }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        { options.type !== CONTACT_TYPES.BUSINESS &&
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Gender' }
            >
              <HFSelect
                control={ control }
                name={ '1.gender' }
                options={ options.gender }
                placeholder={ 'Select Gender' }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        }
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
