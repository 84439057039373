import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { Trash, UserPlus } from '@/assets';

import { UserSelectFilter } from '@modules/UserSelectFilter';
import BlockButton from '@components/BlockButton';
import BubbleButton from '@components/BubbleButton';
import CoreClassificationIcon from '@components/CoreClassificationIcon';
import PreTableWrapper  from '@components/BasicLayoutComponents/PreTableWrapper';
import RowFilter from '@components/RowFilter';

import { CONTACTS_FILTER_NAMES } from '@pages/NewContacts/subpages/Contacts/subpages/AllCantacts/data';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { ButtonsGroupWrapper, BtnWrapper, BubbleFiltersWrapper, IconWrapper } from './styles';

import { PreTablePanelProps } from './types';

export const PreTablePanel = observer(({
  onAddContactClick,
  onDeleteContactsClick,
}: PreTablePanelProps) => {
  const contactsStore = useStore().ContactsStore;
  const {
    filters,
    filtersData,
    isFetching,
    table
  } = contactsStore;

  const selectedLength = table.selectedIDs.length;

  const onDeleteContactsClickWrapper = useCallback(() => {
    onDeleteContactsClick(table.selectedIDs);
  }, [table.selectedIDs, onDeleteContactsClick]);

  const onUserFilterChange = useCallback((value: Array<string>) => {
    contactsStore.setFilters({
      [CONTACTS_FILTER_NAMES.officePrimaryAdvisor]: value
    });
  }, [ contactsStore ]);

  const onClassificationChange = useCallback((value: string | number) => {
    const newValue = value === FILTER_ALL_BTN_LABEL ? [] : [value];
    contactsStore.setFilters({
      [CONTACTS_FILTER_NAMES.classification]: newValue
    });
  }, [ contactsStore ]);

  const classification = filters[CONTACTS_FILTER_NAMES.classification] || [];
  const initState = classification.length === 1 ? classification[0] : null;

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }>
      <UserSelectFilter
        closeTrigger={ isFetching }
        onChange={ onUserFilterChange }
        usersOptions={ filtersData[CONTACTS_FILTER_NAMES.officePrimaryAdvisor] }
        value={ filters[CONTACTS_FILTER_NAMES.officePrimaryAdvisor] || [] }
        withAllUserOption={ true }
      />
      <BubbleFiltersWrapper>
        <RowFilter
          allButtonLabel={ FILTER_ALL_BTN_LABEL }
          initState={ initState }
          items={ filtersData?.classificationItems || [] }
          limit={ 5 }
          onChange={ onClassificationChange }
          renderItem={ ({
            content,
            isSelected,
            isShowMore,
            onClick,
            isSelectedFromMore,
            indexForKey,
          }) => (
            <BubbleButton
              $isActive={ isSelected && !isShowMore }
              $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
              key={ indexForKey }
              onClick={ onClick }
            >
              { content === FILTER_ALL_BTN_LABEL ?
                content :
                <>
                  <IconWrapper $isActive={ isSelected && !isShowMore }>
                    <CoreClassificationIcon classificationType={ content }/>
                  </IconWrapper>
                  { content }
                </>
              }
            </BubbleButton>)
          }
        />
      </BubbleFiltersWrapper>
      <ButtonsGroupWrapper>
        {
          selectedLength > 0 && (
            <BtnWrapper>
              <BlockButton
                onClick={ onDeleteContactsClickWrapper }
                startIcon={ <Trash /> }
              >
                {`Delete ${selectedLength} ${selectedLength > 1 ? 'Contacts' : 'Contact'}`}
              </BlockButton>
            </BtnWrapper>
          )
        }
        <BtnWrapper>
          <BlockButton
            onClick={ onAddContactClick }
            startIcon={ <UserPlus /> }
          >
            New Contact
          </BlockButton>
        </BtnWrapper>
      </ButtonsGroupWrapper>
    </PreTableWrapper>
  );
});
