import { makeAutoObservable, flow, action } from 'mobx';
import axios from 'axios';
import { isEmpty } from 'lodash';
import omit from 'lodash/omit';

import { Store } from '@store';
import { NotesUtilsStore } from '@modules/NotesAndHistory';
import { NotesAndHistoryStore } from '@modules/NotesAndHistory/notesAndHistoryStore';

import { getContactNextApptDate } from '@services/api/salesPipeline/salesActiveCycle';
import { getPopupSettings, getTodoById } from '@services/api/addAndEditTask/addAndEditTask';
import { getTags } from '@services/api/tags/tags';

import { getDataWithActualLinkedItemId } from '@/shared/utils/getDataWithActualLinkedItemId';
import { getEntityRoutePath } from '@/shared/utils/getEntityRoutePath';
import { getQuickCompletedData, convertData } from './utils';

import { tagNormalizer, todoPopupSettingsNormalizer, todoItemNormalizer } from '@/shared/utils/toDosNormalizers';

import { CLONE_TASK_NORMALIZED_OMIT_PROPS, EDIT_TASK_NORMALIZED_OMIT_PROPS } from './data';
import {
  DEFAULT_CATEGORY,
  TO_DOS_LINKED_ITEMS_TYPES_SCHEME, STAGES,
  TO_DOS_FIELDS_NAMES
} from '@constants/todosData';
import { ENTITY_NAMES } from '@constants/common';
import { INIT_TODO_POPUP_SETTINGS } from '@constants/todosData';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { MODAL_TYPE } from '@constants/modalTypes';

import {
  BackendTodoFormFields,
  TASK_TYPE,
  TodoFormFields,
  TodoItem,
  TodoPopupCloseActionCallback,
  TodoPopupDeleteCallback,
  TodoPopupSaveCallback,
  TodoPopupSettings,
  TodoPopupSettingsResponse,
  TodoPopupUpdateCallback
} from '@/shared/types/todos';
import { NoteTagItem } from '@/shared/types/tags';
import { Client, CloseModal, GetNextAppointmentDateResponse, IdType, LinkedItem } from '@/shared/types/commonTypes';
import {
  InitProps,
  TodoPopupTodoItemResponse,
  TodoPopupTodoNoteTags,
  UseFormMethods
} from './types';

import { TodoDeleteConfirmationPopup, TodoEditConfirmationPopup } from '@modules/TodoPopup/components';
import { TodoPopup } from '../TodoPopup';

class TaskPopupLocalStore {
  assignedByData: Client | null = null;
  assignedToData: Client | null = null;
  cloneTaskData: TodoFormFields | null = null;
  coreStore: Store = {} as Store;
  createdByData: Client | null = null;
  createdDate: string | null = null;
  id: IdType | null = null;
  isCloneTask: boolean = false;
  isComplete: boolean = false;
  isFetching: boolean = true;
  isLinkedItem: boolean = true;
  isNew: boolean | null = null;
  isOpenedFromModalRoute: boolean = false;
  isQuickComplete: boolean = false;
  isQuickCompleteDisable: boolean = false;
  linkedContactData: LinkedItem | null = null;
  nextAppointmentDate: string | null = null;
  notesAndHistoryStore: NotesAndHistoryStore;
  notesUtilsStore: NotesUtilsStore;
  pathname: string = '';
  permission: boolean = true;
  predefinedTags: Array<NoteTagItem> = [];
  previousCopyData: Partial<TodoFormFields> = {};
  todoPopupSettings: TodoPopupSettings = INIT_TODO_POPUP_SETTINGS;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  closeModal: CloseModal = () => {};
  closeModalComponent: CloseModal = () => {};
  deleteCallback: TodoPopupDeleteCallback = () => {};
  onCloseAction: TodoPopupCloseActionCallback = () => {};
  saveCallback: TodoPopupSaveCallback = () => {};
  updateCallback: TodoPopupUpdateCallback = () => {};

  constructor() {
    makeAutoObservable(this, {
      getSettings: flow,
      init: flow.bound,
      onCancel: action.bound,
      onComplete: action.bound,
      onCompleteAndCopy: action.bound,
      onDelete: action.bound,
      onQuickComplete: action.bound,
      onSave: action.bound,
      onSaveAndCopy: action.bound,
      resetStore: action.bound,
    });

    this.notesUtilsStore = new NotesUtilsStore();
    this.notesAndHistoryStore = new NotesAndHistoryStore();
  }

  get isGlobalDisabled() {
    return this.isFetching || this.notesUtilsStore.isNotesPanelInAddOrEditMode;
  }

  get isAssignedDetailsDisabled() {
    return this.isGlobalDisabled || Boolean(this.id);
  }

  *init({
    coreStore,
    id,
    initLinkedContact,
    isCloneTask = false,
    isLinkedItem = true,
    isOpenedFromModalRoute = false,
    pathname = '',
    previousCopyData = {},
    useFormMethods,
    closeModal,
    closeModalComponent,
    deleteCallback,
    onCloseAction,
    saveCallback,
    updateCallback
  }: InitProps) {
    this.isFetching = true;
    this.coreStore = coreStore;
    this.isCloneTask = isCloneTask;
    this.isLinkedItem = isLinkedItem;
    this.isOpenedFromModalRoute = isOpenedFromModalRoute;
    this.linkedContactData = initLinkedContact;
    this.pathname = pathname;
    this.previousCopyData = isEmpty(previousCopyData) ? this.previousCopyData : previousCopyData;
    this.useFormMethods = useFormMethods;

    this.closeModal = closeModal;
    this.closeModalComponent = closeModalComponent;
    this.deleteCallback = deleteCallback;
    this.onCloseAction = onCloseAction;
    this.saveCallback = saveCallback;
    this.updateCallback = updateCallback ? updateCallback : () => {};
    
    const taskId = id || this.id;
    try {
      if(taskId) {
        this.id = this.isCloneTask ? null : taskId;

        const taskResp: TodoPopupTodoItemResponse = yield getTodoById({ id: taskId });
        const data = taskResp.data.data[0];

        const newData = {
          ...data,
          ...this.previousCopyData,
          stage: data.stage === STAGES.skipped && this.isCloneTask ? null : data.stage
        };

        this.isLinkedItem = (newData.type === TASK_TYPE.CLIENT_SPECIFIC) ?? false;

        this.isQuickCompleteDisable = newData.stage === STAGES.complete;

        this.setClientData(newData);
        this.createdByData = this.isCloneTask ? this.coreStore?.SettingsStore?.profile : newData.createdByData;
        this.isNew = this.isCloneTask ? null : Boolean(Number(newData.isNew)); 
        this.createdDate = this.isCloneTask ? null : newData.createdDate;

        const normalizedData = this.getNormalizedData(newData);

        this.linkedContactData = normalizedData.linkedItemData || null;
        this.notesUtilsStore.setCurrentLinkedContact(this.linkedContactData?.contactData);

        const omitData = omit({
          ...normalizedData,
          [TO_DOS_FIELDS_NAMES.isCloneTask]: this.isCloneTask
        },
        this.isCloneTask ? CLONE_TASK_NORMALIZED_OMIT_PROPS : EDIT_TASK_NORMALIZED_OMIT_PROPS
        );
        this.useFormMethods.reset(omitData);
        this.isCloneTask = false;
        this.isComplete = false;
      } else {
        this.createdByData = this.coreStore?.SettingsStore?.profile;
  
        this.notesUtilsStore.setCurrentLinkedContact(this.linkedContactData?.contactData || null);
        
        this.useFormMethods.reset({
          [TO_DOS_FIELDS_NAMES.category]: DEFAULT_CATEGORY,
          [TO_DOS_FIELDS_NAMES.createFUTask]: true,
          [TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor]: false,
          [TO_DOS_FIELDS_NAMES.linkedItemId]: this.linkedContactData?.id,
        });
      }
      if(this.isLinkedItem && this.linkedContactData &&
        this.linkedContactData.linkedContactType === TO_DOS_LINKED_ITEMS_TYPES_SCHEME.contactData.type) {
        this.getNextAppDate(this.linkedContactData.id);
      }

      yield this.getTodoNoteTags();

      yield this.getSettings();
    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.setPermission(false);
      }
    } finally {
      this.isFetching = false;
    }
  }

  *getSettings() {
    try {
      const todoPopupSettingsResp: TodoPopupSettingsResponse = yield getPopupSettings();
      this.todoPopupSettings = todoPopupSettingsNormalizer(todoPopupSettingsResp.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  onSave(){
    this.useFormMethods.handleSubmit(async (data) => {
      if(this.isCloneTask) {
        this.previousCopyData = {
          ...data,
          ...this.previousCopyData
        };
      }
      const convertToQuickComplete = getQuickCompletedData(data, this.isQuickComplete || this.isComplete);
      const convertToBackendData = convertData(convertToQuickComplete);
      this.setActualLinkedItemId(convertToBackendData);

      const isOnEdit = convertToBackendData.id && convertToBackendData?.requirring === 'Yes' && 
        (!this.isQuickComplete || this.isComplete);
      const onEdit = async (editData: BackendTodoFormFields) => {
        this.closeModal();
        setTimeout(() => {
          this.coreStore.ModalStore.openModal({
            modalType: MODAL_TYPE.TODO_EDIT_CONFIRMATION,
            modalProps: {
              formData: editData,
              onCloseAction: () => {
                this.onCloseAction();
                this.resetStore();
              },
              onConfirm: async (formData) => {
                await this.saveCallback(formData);
                if(this.isCloneTask) {
                  this.openTodoPopup();
                } else {
                  this.resetStore();
                }
              }
            },
            component: TodoEditConfirmationPopup
          });
        }, 0);
      };

      if(this.notesUtilsStore.alertType){
        const dataWithProcessedNotes = {
          ...convertToBackendData,
          [TO_DOS_FIELDS_NAMES.taskNotes]: this.notesUtilsStore.convertNotesByAlertType(
            convertToBackendData.taskNotes,
            this.linkedContactData
          )
        };
        if(isOnEdit){
          await onEdit(dataWithProcessedNotes);
        } else {
          this.closeModal();
          await this.saveCallback(dataWithProcessedNotes);
          if(this.isCloneTask) {
            this.openTodoPopup();
          } else {
            this.onCloseAction();
            this.resetStore();
          }
        }
        return;
      } else {
        if(this.notesUtilsStore.detectAndSetAlert(data.taskNotes, this.linkedContactData)){
          return;
        }
      }

      if(isOnEdit){
        await onEdit(convertToBackendData);
      } else {
        this.closeModal();
        await this.saveCallback(convertToBackendData);
        if(this.isCloneTask) {
          this.openTodoPopup();
        } else {
          this.onCloseAction();
          this.resetStore();
        }
      }
    })();
  }

  *getNextAppDate(id: IdType) {
    try {
      const resp: GetNextAppointmentDateResponse = yield getContactNextApptDate({ id });
      this.nextAppointmentDate = resp.data.data.nextAppointmentDate;
    } catch (error) {
      console.log(error);
    }
  }

  *getTodoNoteTags() {
    try {
      const tagsResp: TodoPopupTodoNoteTags = yield getTags({
        type: 'Task Note',
        searchPhrase: ''
      });

      this.predefinedTags = tagNormalizer(tagsResp.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  openTodoPopup() {
    if(this.isOpenedFromModalRoute) {
      const url = getEntityRoutePath(this.pathname, ENTITY_NAMES.task, 'id');
      this.coreStore.RouterStore.customPush({
        pathname: url,
      });
    } else {
      this.coreStore.ModalStore.openModal({
        modalType: MODAL_TYPE.TODO_TASK_MODAL,
        modalProps: {
          deleteCallback: () => {},
          id: this.id!,
          initLinkedContact: null,
          isCloneTask: true,
          onCloseAction: this.onCloseAction,
          saveCallback: this.saveCallback,
        },
        component: TodoPopup
      });    
    }
  }

  onCancel(){
    if(this.notesUtilsStore.alertType){
      this.notesUtilsStore.resetAlertType();
      this.isQuickComplete = false;
      return;
    } else {
      this.closeModal();
    }
    
    if(this.id && this.assignedToData?.id === this.coreStore?.SettingsStore?.profile.id) {
      this.updateCallback();
    }
    this.onCloseAction();
    this.resetStore();
  }

  onDelete(){
    const { id, requirring } = this.useFormMethods.getValues();
    this.closeModal();

    setTimeout(() => {
      this.coreStore.ModalStore.openModal({
        modalType: MODAL_TYPE.TODO_DELETE_CONFIRMATION,
        modalProps: {
          id,
          isRequirring: requirring,
          onCloseAction: this.onCloseAction,
          onConfirm: this.deleteCallback
        },
        component: TodoDeleteConfirmationPopup
      });
      this.resetStore();
    }, 0);
  }

  onQuickComplete(){
    this.isQuickComplete = true;
    this.onSave();
  }

  onComplete() {
    this.isComplete = true;
    this.onSave();
  }

  onCompleteAndCopy() {
    this.isComplete = true;
    this.isCloneTask = true;
    this.previousCopyData = {
      stage: this.useFormMethods.getValues(TO_DOS_FIELDS_NAMES.stage)
    };
    this.onSave();
  }

  onSaveAndCopy() {
    this.isCloneTask = true;
    this.onSave();
  }

  onLinkedContactChange = (newLinkedContact: LinkedItem | null) => {
    this.notesUtilsStore.setPrevLinkedContact(this.linkedContactData?.contactData || null);
    this.notesUtilsStore.setCurrentLinkedContact(newLinkedContact?.contactData || null);

    if(newLinkedContact && newLinkedContact.linkedContactType === TO_DOS_LINKED_ITEMS_TYPES_SCHEME.contactData.type) {
      this.getNextAppDate(newLinkedContact.contactData.id);
    } else {
      this.nextAppointmentDate = null;
    }

    this.linkedContactData = newLinkedContact;
  };

  getNormalizedData(data: TodoItem) {
    const normalizedData = todoItemNormalizer(data);
    if(!normalizedData.category && normalizedData.request) {
      normalizedData.category = normalizedData.request;
    }
    return normalizedData;
  }

  setActualLinkedItemId(convertToBackendData: BackendTodoFormFields) {
    if(this.linkedContactData) {
      const linkedContactType = this.linkedContactData.linkedContactType || LINKED_ITEM_TYPES.contact;
      const linkedItemId = this.linkedContactData.id;

      convertToBackendData = getDataWithActualLinkedItemId(convertToBackendData, linkedContactType, linkedItemId);
    }
  }

  setClientData(data: TodoItem) {
    this.assignedByData = data.assignedByData?.id ? data.assignedByData : null;
    this.assignedToData = data.clientData;
  }

  setPermission(state: boolean) {
    this.permission = state;
  }

  resetStore() {
    this.assignedByData = null;
    this.assignedToData = null;
    this.cloneTaskData = null;
    this.createdByData = null;
    this.createdDate = null;
    this.id = null;
    this.isCloneTask = false;
    this.isComplete = false;
    this.isFetching = true;
    this.isNew = false;
    this.isQuickComplete = false;
    this.isQuickCompleteDisable = false;
    this.linkedContactData = null;
    this.nextAppointmentDate = null;
    this.predefinedTags = [];
    this.previousCopyData = {};
    this.todoPopupSettings = INIT_TODO_POPUP_SETTINGS;

    this.closeModal = () => {};
    this.notesUtilsStore.resetNotesState();
    this.onCloseAction = () => {};
    this.saveCallback = () => {};
    this.setPermission(true);
    this.updateCallback = () => {};
  }
}

export default TaskPopupLocalStore;
