import React, { useCallback } from 'react';

import BlockButton from '@components/BlockButton';
import PopupActionsButtons from '@components/PopupActionsButtons';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Container,
  Group,
  Wrapper
} from './styles';

import { ModalFooterProps } from './types';

const BACK_BUTTONS_STYLES = {
  minWidth: '60px',
  padding: '6px 10px 6px 5px',
  marginRight: '10px'
};

const NEXT_BUTTONS_STYLES = {
  minWidth: '60px',
  padding: '6px 10px',
  marginRight: '10px'
};


export const ModalFooter = ({
  cancelHandler,
  currentStep,
  isAttentionMode,
  isButtonsDisabled,
  onAttentionCancelClick,
  onAttentionSaveClick,
  saveHandler,
  stepChangeHandler,
}: ModalFooterProps) => {

  const backChangeHandler = useCallback(() => {
    if(currentStep > 1) {
      stepChangeHandler(currentStep - 1);
    }
  }, [currentStep]);

  const nextChangeHandler = useCallback(() => {
    if(currentStep < 4) {
      stepChangeHandler(currentStep + 1);
    }
  }, [currentStep]);

  const onCancelClickHandler = isAttentionMode ? onAttentionCancelClick : cancelHandler;
  const saveLabel = isAttentionMode ? 'Okay' : 'Save';
  const onSaveClickHandler = isAttentionMode ? onAttentionSaveClick : saveHandler;

  return (
    <Container>
      {
        isAttentionMode ?
          <Wrapper>If you switch to another type, all the published information will be lost. Continue?</Wrapper>
          :
          <Group>
            <BlockButton
              color='primary'
              disabled={ currentStep === 1 || isButtonsDisabled }
              onClick={ backChangeHandler }
              startIcon={ <ChevronLeftIcon /> }
              style={ BACK_BUTTONS_STYLES }
            >
              Back
            </BlockButton>
            <BlockButton
              disabled={ currentStep === 4 || isButtonsDisabled }
              endIcon={ <ChevronRightIcon /> }
              onClick={ nextChangeHandler }
              style={ NEXT_BUTTONS_STYLES }
            >
              Next
            </BlockButton>
          </Group>
      }
      <PopupActionsButtons
        CancelProps={ {
          disabled: isButtonsDisabled
        } }
        SaveProps={ {
          disabled: isButtonsDisabled
        } }
        cancelLabel={ 'Cancel' }
        onCancelClick={ onCancelClickHandler }
        onSaveClick={ onSaveClickHandler }
        saveLabel={ saveLabel }
      />
    </Container>
  );
};
