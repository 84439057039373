import styled, { css } from 'styled-components';

import MuiBox from '@material-ui/core/Box';
import MuiIconButton from '@material-ui/core/IconButton';

import CoreClassificationIcon from '@components/CoreClassificationIcon';

import { COLORS } from '@constants/colors';

import { Close, Edit, FilledStar, Star } from '@/assets';

import { ActionsBoxProps, ContainerProps, IconProps, NameProps } from './types';

export const CloseIcon = styled(Close)`
  path {
    fill: ${COLORS.redLight};
  }
`;

export const EditIcon = styled(Edit)`
  path {
    fill: ${COLORS.orange};
  }
`;

export const StarIcon = styled(Star)``;

export const ActiveStarIcon = styled(FilledStar)``;

export const Container = styled(MuiBox)<ContainerProps>`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.3;
  `};
  
  &.MuiBox-root {
    display: flex;
    overflow: hidden;

    ${({ innerPadding }) => innerPadding && css`
      padding: ${innerPadding};
    `};
  }
      
  ${({ $isClickable, $color }) => $isClickable && css`
    color: ${$color || COLORS.orange};
    cursor: pointer;
      
    & svg path {
      fill: ${$color || COLORS.orange};
    }
    &:hover {
      & div[data-custom-style="user-name"] {
          text-decoration: underline;
      }
    }
  `};
`;

export const ContentBox = styled(MuiBox)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    width: max-content;
  }
`;

export const ActionsBox = styled(MuiBox)<ActionsBoxProps>`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({ isMarginFixed }) => isMarginFixed ? 'auto' : '10px'};
  }
`;

export const UserIcon= styled(MuiBox)`
  &.MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    min-width: 30px;
    border-radius: 15px;
    font-weight: 400;
    font-size: 13px;
    color: ${COLORS.white};
    background-color: ${COLORS.grey};
  }
`;

const Name = styled.div<NameProps>`
  max-width: ${({ labelWidth }) => labelWidth ? `${labelWidth}px` : 'auto'};
  padding-left: 10px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserName = styled(Name)`
  padding-top: 2px;
  font-size: 14px;
`;

export const SubUserName = styled(Name)`
  font-size: 11px;
`;

export const UserNamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CoreIcon = styled(CoreClassificationIcon)`
  margin-left: 12px;
  min-width: 18px;
`;

export const IconButton = styled(MuiIconButton)<IconProps>`
  &.MuiIconButton-root {
    padding: 6px;

    &:hover {
      ${({ $close }) => $close && css`background-color: ${COLORS.redBg}`}
      ${({ $edit }) => $edit && css`background-color: ${COLORS.select}`}
      ${({ $star }) => $star && css`background-color: ${COLORS.select}`}

      svg {
        path {
          ${({ $close }) => $close && css` fill: ${COLORS.red}`}
          ${({ $edit }) => $edit && css`fill: ${COLORS.orange}`}
        }
      }
    }
  }
`;
