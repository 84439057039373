import { EmailStatuses, Roles, UserStatuses } from '@constants/common';
import { FULL_NAME_ORDER } from '@constants/userSettings';
import { CalendarViewsType, ClientWithCalendarData, IdType, ValueLabelObj, ValueOf } from '@/shared/types/commonTypes';

export type ProfileType = {
  addressLine: string | null;
  addressLine2: string | null;
  businessName: string | null;
  city: string | null;
  compliance: string | null;
  email: string;
  emailSignature: string | null;
  emailVerified: EmailStatuses;
  faxNumber: string | null;
  firstName: string;
  headshot: string | null;
  id: number;
  lastName: string;
  logo: string | null;
  phoneNumber: string | null;
  signature: string | null;
  state: number;
  // eslint-disable-next-line no-restricted-globals
  status: UserStatuses;
  title: string | null;
  userRole: Roles,
  website: string | null;
  zip: string | null;
}

export type UserProfileType = ProfileType & {
  advisors: Array<ClientWithCalendarData>,
  clients: Array<ClientWithCalendarData>,
};

export type UserProfileTypeResponse = UserProfileType & {
  userRole: Array<Roles>,
};

export type Password = MainPasswordProperty & {
  confirmPassword: string,
};

export type MainPasswordProperty = {
  oldPassword: string,
  newPassword: string,
};

export enum TimeInSeconds {
  Minutes15 = '900',
  Minutes30 = '1800',
  Minutes45 = '2700',
  Hour1 = '3600',
  Hour1Minutes30 = '5400',
  Hours2 = '7200',
}


export enum LoginTabs {
  Crm = 'CRM',
  DirectMail = 'DIRECT MAIL',
}

export type FullNameOrdersType = ValueOf<typeof FULL_NAME_ORDER>

export type Organization = {
  id: number;
  // eslint-disable-next-line no-restricted-globals
  name: string;
};

export type SelectOption<V, L = V> = {
  value: V;
  label: L;
};

export type TimeOptions = Array<SelectOption<TimeInSeconds, string>>;
export type OrganizationOptions = Array<SelectOption<number, string>>;

export type ConfigData = {
  activityDefaultTime: TimeOptions;
  automaticLogout: TimeOptions;
  defaultCalendarView: Array<ValueLabelObj>;
  defaultLoginTab: Array<ValueLabelObj>;
  fullNameOrder: Array<ValueLabelObj>;
  organizations: OrganizationOptions;
}

export type ConfigDataResponse = {
  activityDefaultTime: Array<TimeInSeconds>;
  automaticLogout: Array<TimeInSeconds>;
  defaultCalendarView: Array<CalendarViewsType>;
  defaultLoginTab: Array<LoginTabs>;
  fullNameOrder: Array<FullNameOrdersType>;
  organizationId: Array<Organization>;
}

export type GetSettingsResponse = {
  configData: ConfigDataResponse;
  data: AccountSettingsType;
  message: Array<string>;
};

export type GetProfileResponce = {
  message: Array<string>;
  data: UserProfileType;
};

export type GetUserResponse = {
  data: UserProfileTypeResponse;
  message: Array<string>;
}

export type UserFUllData = {
  activityDefaultTime: string;
  activityNotification: number;
  addressLine: string;
  addressLine2: string;
  appointmentNumberMax: number;
  automaticLogout: string;
  autoRenewNotification: number;
  billingAccountGraced: number;
  billingLastAmount: string;
  billingLastDate: null,
  billingNextDate: string;
  billingNextDatePrevious: null,
  birthdayNotification: number;
  businessName: string;
  calendarEndTime: string;
  calendarStartTime: string;
  cart: string;
  categoryColors: string;
  city: string;
  compliance: string;
  creationTime: string;
  crmActive: number;
  cronflag: number;
  dayataglanceNotification: number;
  defaultCalendarView: string;
  defaultLoginTab: string;
  deleted: number;
  email: string;
  emailSignature: string;
  emailsSent: number;
  emailVerified: EmailStatuses;
  faxNumber: null,
  firstName: string;
  fullNameOrder: string;
  headshot: null,
  id: number;
  lastFailedEmailNotice: null,
  lastFailedEmailNoticeFlag: number;
  lastName: string;
  lockCount: number;
  lockTimestamp: string;
  logo: string;
  mailchimpSynced: number;
  master: number;
  membershipId: number;
  nearQuotaEmailNotice: null,
  nearQuotaEmailNoticeFlag: number;
  nylasAccountsAvailable: number;
  nylasData: null,
  optionColors: string;
  organizationId: number;
  parentId: number;
  passwordExpiration: string;
  phoneNumber: string;
  profileAlertHide: number;
  renewalDayOfMonth: number;
  resetExpires: string;
  resetLink: string;
  signature: string;
  state: number;
  // eslint-disable-next-line no-restricted-globals
  status: UserStatuses;
  taskNotification: number;
  timezone: string;
  title: string;
  unverifiedEmailNotice: null,
  unverifiedEmailNoticeFlag: number;
  userRole: Array<Roles>,
  website: string;
  zip: string;
};

export type AccountSettingsType = {
  activityDefaultTime: TimeInSeconds;
  automaticLogout: TimeInSeconds;
  calendarEndTime: string;
  calendarStartTime: string;
  defaultCalendarView: CalendarViewsType;
  defaultLoginTab: LoginTabs;
  fullNameOrder: FullNameOrdersType;
  organizationId: number;
  timezone: IdType;
}

export type StatesResponseType = {
  id: number;
  countryId: number;
  shortName: string;
  fullName: string;
};

export type SaveProfileErrorData = {
  data: {
    error: [{
      email: string
    }]
  }
}
