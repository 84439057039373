import { format, parse } from 'date-fns';

import { convertedDatesToCurrentTimezone } from '@/shared/utils/convertedDatesToCurrentTimezone';
import { convertToTwelveFormat } from '@/shared/utils/timeConverter';
import { getMeridian } from './utils';
import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import {
  HOURS_MINUTES_SECONDS,
  MONTH_DAY_YEAR_BACKSLASH,
  YEAR_MONTH_DAY,
  YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS
} from '@constants/dateFormats';

import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import {
  ContactActivity,
  ContactActivityData,
  ContactActivityFiltersNames,
  FilterData,
  FilterDataConverter
} from './types';
import { IdType } from '@/shared/types/commonTypes';

const itemConverter = (
  item: ContactActivity, accountTimeZone: AccountTimeZone, timeZones: TimeZones
): ContactActivity => {
  const startDateAndTime = {
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime
  };

  if(!yesNoToBoolean(item.allDay)) {
    const dates = convertedDatesToCurrentTimezone({
      createdDate: item.createdDate || new Date(`${item.startDate}T${item.startTime}Z`),
      startDate: item.startDate,
      startTime: item.startTime,
      endDate: item.endDate,
      endTime: item.endTime,
      allDay: yesNoToBoolean(item.allDay),
      createdTimeZone: timeZones.find(timeZone => timeZone.value === item.createdTimeZone) || accountTimeZone
    }, accountTimeZone);
  
    const [ startDate, startTime ] = format(dates.startDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');
    const [ endDate, endTime ] = format(dates.endDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

    startDateAndTime.startDate = startDate;
    startDateAndTime.startTime = startTime;
    startDateAndTime.endDate = endDate;
    startDateAndTime.endTime = endTime;
  }
  const formattedStartDate = format(
    parse(
      `${startDateAndTime.startDate} ${startDateAndTime.startTime}`,
      `${YEAR_MONTH_DAY} ${HOURS_MINUTES_SECONDS}`,
      new Date()
    ),
    MONTH_DAY_YEAR_BACKSLASH
  );
  // eslint-disable-next-line max-len
  const formattedStartTime = `${convertToTwelveFormat(startDateAndTime.startTime, false)} ${getMeridian(startDateAndTime.startTime)}`;

  return ({
    ...item,
    recurring: yesNoToBoolean(item.recurring),
    startDate: formattedStartDate,
    startTime: formattedStartTime
  });
};

export const itemsNormalizer = (
  response: ContactActivityData,
  currentUserId: IdType,
  accountTimeZone: AccountTimeZone,
  timeZones: TimeZones
): {
  filterData: FilterDataConverter,
  items: Array<ContactActivity>,
} => {
  const { filterData, ...activitiesItems } = response;

  const items = Object.values(activitiesItems) as Array<ContactActivity>;

  return {
    filterData: filterDataConverter(filterData, currentUserId),
    items: items.map((item) => itemConverter(item, accountTimeZone, timeZones)),
  };
};

const filterDataConverter = (
  filterData: FilterData, currentUserId: IdType
): FilterDataConverter => {
  if (!filterData) {
    return {} as any;
  }

  return {
    [ContactActivityFiltersNames.Clients]: getSortedUserOption(filterData.clients, currentUserId),
  };
};
