import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { AxiosResponse } from 'axios';
import { identity, isEmpty, pickBy } from 'lodash';

import { deleteNote, getNoteById, getNotesByType, saveNote } from '@services/api/notes/notes';

import { getFilterParams } from '@/shared/utils/filterUtils';
import { noteConverter, notesNormalized } from '@/shared/utils/contactNoteNormalizer';
import { getParamsToSaveContactNote } from '@services/store/contactDetailsOverview/utils';
import { getIsDifferFromFilters } from '@modules/NotesAndHistory/utils/getIsDifferFromFilters';
import { getPreparedFilters } from '@modules/NotesAndHistory/utils/getPreparedFilters';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';
import { CONTACT_TYPES } from '@constants/common';
import {
  FORM_DEFAULT_FILTERS_VALUES,
  FORM_NAMES
} from '@modules/NotesAndHistory/components/Notes/components/GeneralFilters/data';
import { INIT_NOTES_VIEW, NOTES_TAB_VALUE, VIEWS } from './data';

import { ContactItem } from '@/shared/types/contact';
import { ContactNote } from '@/shared/types/note';
import { EntityNameValues, IdType } from '@/shared/types/commonTypes';
import { InitStoreProps, Item, NotesViewType, UseFormMethods } from './types';


export class NotesAndHistoryStore {
  contact: ContactItem = {} as ContactItem;
  currentPage: number = 1;
  currentTab: string = NOTES_TAB_VALUE;
  editNote: null | Item = null;
  entityName: EntityNameValues | null = null;
  filters: any = FORM_DEFAULT_FILTERS_VALUES;
  getTags: () => void = () => {};
  isInitializedStore: boolean = false;
  isLoading: boolean = false;
  isOpenSearchPanel: boolean = false;
  lastPage: number = 1;
  notes: Array<any> = [];
  notesView: NotesViewType = INIT_NOTES_VIEW;
  searchState: boolean = false;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  onFiltersChangeReaction: IReactionDisposer;
  onNotesChangeReaction: IReactionDisposer;
  onPageChangeReaction: IReactionDisposer;


  constructor() {
    makeAutoObservable(this,{
      deleteNote: flow.bound,
      getNote: flow.bound,
      getNotes: flow.bound,
      increaseCurrentPage: action.bound,
      initStore: flow.bound,
      onAddNoteClick: flow.bound,
      resetState: action.bound,
      resetStore: action.bound,
      resetTab: action.bound,
      saveNote: flow.bound,
      setCurrentTab: action.bound,
      setEditNote: action.bound,
      setFilters: action.bound,
      setIsOpenSearchPanel: action.bound,
      setNotesView: action.bound,
      setSearchState: action.bound,
    });

    this.onFiltersChangeReaction = this.createOnFilterChangeReaction();
    this.onNotesChangeReaction = this.createOnNotesChangeReaction();
    this.onPageChangeReaction = this.createOnPageChangeReaction();
  }

  *initStore(initStoreProps: InitStoreProps) {
    const { contact, entityName, useFormMethods, getTags } = initStoreProps;
    this.contact = contact;
    this.entityName = entityName;
    this.getTags = getTags;
    this.useFormMethods = useFormMethods;

    if(this.contact && this.contact.id) {
      yield this.getNotes();
    }
  }

  async *getNotes() {
    this.setIsLoading(this.isInitializedStore);
    try {
      const preparedFilters = getPreparedFilters(this.filters);
  
      const response: AxiosResponse<any> = yield await getNotesByType({
        page: this.currentPage,
        ...getFilterParams({
          noteContactId: this.contact.id,
          ...preparedFilters
        }, true),
      });
      const data = response.data.data.data;
      this.lastPage = response.data.data.last_page;

      this.notes = [
        ...(this.notes ? this.notes : []),
        ...notesNormalized(data, this.contact)
      ];
      if(!isEmpty(this.useFormMethods)) {
        this.useFormMethods.reset({
          [CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes]: this.notes
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
      this.isInitializedStore = true;
    }
  }

  // TODO: note refactoring
  *saveNote(newNote: ContactNote) {
    this.setIsLoading(true);
    try {
      const params = getParamsToSaveContactNote(this.contact!.id, newNote);
      // TODO: note refactoring
      const response: AxiosResponse<any> = yield saveNote(params);
      const responseData = response.data.data;

      const convertedNote = noteConverter([responseData], this.contact!)[0];

      const filters = pickBy(this.filters, identity);
      const isDifferFromFilters = getIsDifferFromFilters(filters, convertedNote);

      if(newNote.id) {
        if(!isDifferFromFilters) {
          this.notes = this.notes.map((note) => {
            if(note.id === convertedNote.id) {
              return convertedNote;
            }
            return note;
          });
        } else {
          this.notes = this.notes.filter((note) => note.id !== convertedNote.id);
        }
      } else {
        if(!isDifferFromFilters) {
          this.notes.push(convertedNote);
          this.useFormMethods.reset({
            contactNotes: this.notes
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  }

  *deleteNote(id: IdType) {
    this.setIsLoading(true);
    try {
      yield deleteNote({ ids: [id] });

      this.notes = this.notes?.filter((note) => note.id !== id);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  }

  *getNote(id: IdType) { //Item
    this.setIsLoading(true);
    try {
      yield this.getTags();

      // TODO: note refactoring
      const response: AxiosResponse<any> = yield getNoteById({ id });

      let noteContactData = this.contact;
      if(this.contact.type === CONTACT_TYPES.HOUSEHOLD) {
        const { noteContactId } = response.data.data;
        noteContactData = noteContactId === this.contact.id ? this.contact :
          this.contact.householderContacts.filter(member => member.contactId === noteContactId)[0];
      }
      const respNote = noteConverter([response.data.data], noteContactData)[0];

      this.notes = this.notes.map((note) => note.id === respNote.id ? respNote : note);
      
      return respNote;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      this.setIsLoading(false);
    }
  }

  createOnPageChangeReaction() {
    return reaction(
      () => this.currentPage,
      () => {
        this.getNotes();
      }
    );
  }

  createOnNotesChangeReaction() {
    return reaction(
      () => this.notes,
      () => {
        // TODO: note refactoring
        this.useFormMethods.reset({
          [CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes]: this.notes
        });
      }
    );
  }
  
  createOnFilterChangeReaction(){
    return reaction(
      () => this.filters,
      () => {
        this.onPageChangeReaction();
    
        this.currentPage = 1;
        this.notes = [];
        this.getNotes();

        this.onPageChangeReaction = this.createOnPageChangeReaction();
      }
    );
  }

  increaseCurrentPage() {
    if(this.entityName) {
      this.currentPage = this.currentPage === this.lastPage ? this.currentPage : this.currentPage + 1;
    }
  }

  *onAddNoteClick() {
    yield this.getTags();

    this.setNotesView(VIEWS.addEdit);
  }

  setIsLoading(state: boolean) {
    this.isLoading = state;
  }

  setCurrentTab(tab: string){
    this.currentTab = tab;
  }

  setEditNote(editNote: Item | null) {
    this.editNote = editNote;
  }

  setNotesView(notesView: NotesViewType){
    this.notesView = notesView;
  }

  // TODO: note refactoring
  setFilters(newFilters: any) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
    const searchState = Object.entries(this.filters).some((filter: any) => {
      const [key, value] = filter;
      return key !== FORM_NAMES.selectFilter && value;
    });
    this.setSearchState(searchState);
  }

  setSearchState(state: boolean) {
    this.searchState = state;
  }
  
  setIsOpenSearchPanel(state: boolean) {
    this.isOpenSearchPanel = state;
  }
  
  // TODO: separate tabs in the NotesAndHistory
  resetTab() {
    this.setCurrentTab(NOTES_TAB_VALUE);
  }

  resetState() {
    this.setNotesView(INIT_NOTES_VIEW);
    this.setEditNote(null);
    this.setSearchState(false);
    // TODO: separate tabs in the NotesAndHistory
    // this.setCurrentTab(NOTES_TAB_VALUE);
  }

  resetStore() {
    this.onPageChangeReaction();
    this.onFiltersChangeReaction();
    this.onNotesChangeReaction();

    this.currentPage = 1;
    this.lastPage = 1;
    this.notes = [];
    this.setIsOpenSearchPanel(false);
    this.isInitializedStore = false;
    this.filters = FORM_DEFAULT_FILTERS_VALUES;
    this.useFormMethods = {} as UseFormMethods;
    this.contact = {} as ContactItem;
    this.entityName = null;

    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.onFiltersChangeReaction = this.createOnFilterChangeReaction();
    this.onNotesChangeReaction = this.createOnNotesChangeReaction();
  }
}
