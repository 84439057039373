import React, { useCallback, useEffect, useState } from 'react';
import { format, addDays, parse } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';

import { TimeAdder } from '@components/TimeAdder';

import { DATE_BUTTONS_CONFIG } from './data';
import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { DateButtonsProps } from './types';


export const DateButtons = ({
  disabled
}: DateButtonsProps) => {

  const { setValue, control } = useFormContext();

  const dueDate = useWatch({
    control,
    name: TO_DOS_FIELDS_NAMES.dueDate
  });

  const [ selectedValue, setSelectedValue ] = useState<number | null>(null);

  const onSelect = useCallback((value: number) => {
    if(selectedValue === value) {
      return;
    }
    setSelectedValue(value);

    const parsedDueDate = dueDate ? parse(dueDate, YEAR_MONTH_DAY, new Date()) : new Date();
    const newDate = addDays(parsedDueDate, value);
    const formattedNewDate = format(newDate, YEAR_MONTH_DAY);

    setValue(TO_DOS_FIELDS_NAMES.reviewDate, formattedNewDate);
  }, [dueDate]);

  useEffect(() => {
    setSelectedValue(null);
  }, [disabled]);

  return (
    <TimeAdder
      buttonsConfig={ DATE_BUTTONS_CONFIG }
      disabled={ disabled }
      onSelect={ onSelect }
      selectedValue={ selectedValue }
    />
  );
};
