import { cloneData } from '@/shared/utils/cloneData';

import { CONTACT_STATUSES, CONTACT_TYPES, CORE_CLASSIFICATIONS } from '@constants/common';
import { FIELD_IDS } from '@constants/contactsData';

import { IdType } from '@/shared/types/commonTypes';

const personalInformation = {
  firstName: '',
  lastName: '',
  nickname: '',
  birthdate: '',
  gender: ''
};

const contactInformation = {
  mailingLocation: '',
  addressLine2: '',
  address: {
    city: '',
    country: '',
    state: '',
    zip: '',
  },
  mobilePhone: '',
  email: '',
  rules: {
    allowCall: false,
    allowEmail: false,
    allowMail: false,
    allowText: false
  },
  preference: ''
};

const commonProfileDetails = (currentUserId: IdType) => ({
  classification: CORE_CLASSIFICATIONS.CLIENT,
  rating: '',
  officePrimaryAdvisor: currentUserId || '',
  status: CONTACT_STATUSES.active,
  sourceId: '',
  sourceCategory: ''
});

const individualValues = (currentUserId: IdType) => (
  {
    1: {
      ...personalInformation,
      ...commonProfileDetails(currentUserId),
    },
    2: { ...contactInformation }
  }
);

const householdValues = (currentUserId: IdType) => (
  {
    1: {
      main: {
        householdOwner: true,
        ...personalInformation,
      },
      spouse: {
        householdOwner: false,
        ...personalInformation,
      },
      ...commonProfileDetails(currentUserId),
    },
    2: {
      communicateWith: 'Both',
      ...contactInformation,
    }
  }
);

const businessValues = (currentUserId: IdType) => ({
  1: {
    occupationCompany: '',
    employeeCount: '',
    companyType: '',
    annualRevenue: '',
    birthdate: '',
    ...commonProfileDetails(currentUserId),
  },
  2: {
    ...contactInformation,
  },
  3: {
    email: '',
    emailType: FIELD_IDS.WORK_EMAIL_ID,
    firstName: '', 
    lastName: '',
    linkContact: '',
    phoneNumber: '',
    phoneType: FIELD_IDS.MOBILE_PHONE_ID,
  }
});

export const getDefaultValues = (type: CONTACT_TYPES, currentUserId: IdType): any => {
  switch (type) {
  case CONTACT_TYPES.INDIVIDUAL:
    return cloneData(individualValues(currentUserId));
  case CONTACT_TYPES.HOUSEHOLD:
    return cloneData(householdValues(currentUserId));
  case CONTACT_TYPES.BUSINESS:
    return cloneData(businessValues(currentUserId));
  default:
    return;
  }
};
