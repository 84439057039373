import { makeAutoObservable, flow, action } from 'mobx';

import { getActivityById, getActivityCreationSettings } from '@services/api/addAndEditActivity/addAndEditActivity';

import { activityCreationSettingsNormalizer } from './normalizer/activityCreationSettingsNormalizer';
import { activityNormalizer } from './normalizer/activityNormalizer';

import {
  ActivityCreationSettings,
  ActivityPopupItemResponse,
  ActivityPopupSettingsResponse,
} from './types';
import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import { EditActivityItem } from '@/shared/types/activityPopup';
import { IdType } from '@/shared/types/commonTypes';

class ActivityPopupLocalStore {
  activityCreationSettings: ActivityCreationSettings = {} as ActivityCreationSettings;
  editActivityItem: EditActivityItem = {} as EditActivityItem;
  isFetching: boolean = false;
  isHideModal: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      clearEditActivityItem: action.bound,
      getActivityById: flow.bound,
      getSettings: flow.bound,
      resetStore: action.bound,
      setIsHideModal: action.bound,
    });
  }

  *getActivityById(id: IdType, accountTimeZone: AccountTimeZone, timeZones: TimeZones, forDay?: string) {
    this.setIsFetching(true);
    try {
      const activityResp: ActivityPopupItemResponse = yield getActivityById({
        id,
        forDay
      });
      this.editActivityItem = activityNormalizer(activityResp.data.data, accountTimeZone, timeZones);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *getSettings() {
    this.setIsFetching(true);
    try {
      const activityCreationSettingsResp: ActivityPopupSettingsResponse = yield getActivityCreationSettings();
      this.activityCreationSettings = activityCreationSettingsNormalizer(activityCreationSettingsResp.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  clearEditActivityItem() {
    this.editActivityItem = {} as EditActivityItem;
  }

  setIsFetching(state: boolean) {
    this.isFetching = state;
  }

  setIsHideModal(state: boolean) {
    this.isHideModal = state;
  }

  resetStore() {
    this.setIsHideModal(false);
  }
}

export default ActivityPopupLocalStore;
