import { buildPath } from '@/shared/utils/buildPath';
import { COMMON_PATH_PART } from '@constants/routes/common';

export const salesCycleTabs = {
  activeSalesCycles: 'active-sales-cycles',
  clientOpportunities: 'client-opportunities',
} as const;

export const SALES_CYCLE_COMMON_PATH = 'sales-cycle';

export const SALES_CYCLE_PATH = buildPath([COMMON_PATH_PART, SALES_CYCLE_COMMON_PATH]);

export const SALES_CYCLE_ROUTE = buildPath([COMMON_PATH_PART, SALES_CYCLE_COMMON_PATH, ':tab']);

export const SALES_ACTIVE_CYCLE_PATH = buildPath([SALES_CYCLE_PATH, salesCycleTabs.activeSalesCycles]);
export const SALES_CLIENT_OPPORTUNITIES_PATH = buildPath([SALES_CYCLE_PATH, salesCycleTabs.clientOpportunities]);
