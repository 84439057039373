import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import Dialog from '@material-ui/core/Dialog';
import MuiBox from '@material-ui/core/Box';

import { DialogStyleProps, ModalContentProps } from './types';

export const StyledDialog = styled(Dialog)<DialogStyleProps>`
  visibility: ${({ $isHide }) => $isHide ? 'hidden' : 'visible'};
  
  & .MuiPaper-root {
    min-width: 200px;
    border-radius: 10px;
  }
`;

export const Header = styled(MuiBox)`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding ?? '22px 30px' };
  z-index: 5;
  box-shadow: 0 1px 5px rgba(16, 28, 91, 0.12);
  background-color: ${COLORS.white};
`;

export const HeaderTitleWrapper = styled.div`
  flex-grow: 1;
  color: ${ COLORS.header };
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
`;

export const OuterContentWrapper = styled.div<ModalContentProps>`
`;

export const Content = styled(MuiBox)<ModalContentProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  ${({ width }) => width && css`
    box-sizing: border-box;
  `}
  
  
  ${({ $disabled }) => $disabled && css`
    position: relative;
    
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10000;
      background-color: ${COLORS.white};
      opacity: 0.5;
    }

    & > * {
      overflow: hidden;
    }
  `}
`;

export const Footer = styled(MuiBox)`
  display: flex;
  padding: ${({ padding }) => padding ?? '20px 30px' };
  box-shadow: 0 -1px 5px rgba(16, 28, 91, 0.12);
  background-color: ${COLORS.white};
  z-index: 5;
`;

export const TopPanel = styled(MuiBox)`
  position: relative;
  padding: ${({ padding }) => padding ?? '10px 30px' };
  background-color: ${ COLORS.background };
`;

