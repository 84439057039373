import { AddNewContactFieldTagItem } from '@/shared/types/addNewContactPopup';

export const checkInclusion = (obj: AddNewContactFieldTagItem, arr: Array<AddNewContactFieldTagItem>) => {
  return arr.some((item) => item.id === obj.id);
};

export const excludeOption = (option: AddNewContactFieldTagItem, arr: Array<AddNewContactFieldTagItem>) => {
  if('frontId' in option && option.frontId) {
    //@ts-ignore
    return arr.filter(item => item.frontId !== option.frontId);
  } else {
    return arr.filter(item => item.id !== option.id);    
  }
};
