import { flow, makeAutoObservable } from 'mobx';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { Store } from '@store';

import { saveAccountOrPoliceOrPlan, updateAccountAndPlans } from '@services/api/accountAndPlans/accountAndPlans';

import { ENTITY_NAMES } from '@constants/common';

import { getAccountAndPlanEntityName } from '@/shared/utils/getAccountAndPlanEntityName';

import { SubmitData } from '@modules/AccountsAndPlansPopup/types';

export default class AccountAndPlanStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  coreStore: Store;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      saveAccountsAndPlans: flow.bound
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.account
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *saveAccountsAndPlans(data: SubmitData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    const otherEntityName = getAccountAndPlanEntityName(data.accountType);

    try {
      if(data.id){
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateAccountAndPlans(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: true,
            uniqueKey: data.id,
            otherEntityName
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: true,
            uniqueKey: data.id,
            otherEntityName
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveAccountOrPoliceOrPlan(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: false,
            uniqueKey: data.id,
            otherEntityName
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: false,
            uniqueKey: data.id,
            otherEntityName
          }),
        });
      }
      yield this.coreStore.ContactDetailsOverview.getContactOverView();
      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
}