import ListItemIcon from '@material-ui/core/ListItemIcon';

/* eslint-disable */
import { CORE_CLASSIFICATIONS } from '@constants/common';

import {
  ClientClassification,
  ExClientClassification,
  FinancialPlanningClientClassification,
  GeneralClassification,
  LeadClassification,
  ProspectClassification
} from '@/assets';

export default function CoreClassificationIcon(props) {
  const { classificationType, className } = props;
  let CoreIcon;
  switch (classificationType) {
  case CORE_CLASSIFICATIONS.CLIENT:
    CoreIcon = ClientClassification;
    break;
  case CORE_CLASSIFICATIONS.LEAD:
    CoreIcon = LeadClassification;
    break;
  case CORE_CLASSIFICATIONS.PROSPECT:
    CoreIcon = ProspectClassification;
    break;
  case CORE_CLASSIFICATIONS.GENERAL:
    CoreIcon = GeneralClassification;
    break;
  case CORE_CLASSIFICATIONS.EX_CLIENT:
    CoreIcon = ExClientClassification;
    break;
  case CORE_CLASSIFICATIONS.FINANCIAL_PLANNING_CLIENT:
    CoreIcon = FinancialPlanningClientClassification;
    break;
  default:
    CoreIcon = GeneralClassification;
    break;
  }
  return (
    <ListItemIcon className={ className }>
      <CoreIcon />
    </ListItemIcon>
  );
}
