import MuiBox from '@material-ui/core/Box';
import styled from 'styled-components';

export const CheckBoxContainer = styled(MuiBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .MuiFormControlLabel-root {
    align-items: start;
    padding-top: 6px;

    & .MuiCheckbox-root {
      padding: unset;
      margin: 0 0 15px;
    }
    
    & .MuiFormControlLabel-label{
      margin-top: 2px;
    }
  }
`;

export const PhoneFieldContainer = styled(MuiBox)`
  margin-bottom: 5px;
`;
