import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { LEFT_COLUMN_WIDTH } from '@/modules/AddContactModal/data';

import { getContactByNameForRelationWithoutContactId } from '@services/api/relatedContacts/relatedContacts';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import FetchAutoComplete from '@modules/FetchAutoComplete';
import FormBlock from '@components/FormBlock';
import UserItem from '@components/NewUserItem';

import { LinkContactProps } from './types';
import { LinkedContact } from '@/shared/types/linkedContact';


export const LinkContact = observer(({
  disabled
}: LinkContactProps) => {

  const store = useAddContactPopupStore();

  const linkContactHandler = useCallback((linkedContact: LinkedContact) => {
    if(linkedContact) {
      store.setLinkedContact(linkedContact);
    }
  }, [store]);

  const deleteContactHandler = useCallback(() => {
    store.setLinkedContact(null);
  }, [store]);

  return (
    <FormBlock.RowWrapper>
      <FormBlock.FormField
        $labelWidth={ LEFT_COLUMN_WIDTH }
        label={ 'Link Contact' }
      >
        { !store.linkedContact && (
          <FetchAutoComplete<LinkedContact>
            AutocompleteProps={ {
              getOptionLabel: (option: any) => option.fullName || 'label',
              renderOption: (option: any) => {
                return (
                  <UserItem
                    labelWidth={ 500 }
                    user={ option }
                  />);
              },
              blurOnSelect: true,
              clearOnBlur: true,
              clearOnEscape: true,
            } }
            FetchProps={ {
              getParams: getNameParam,
              requestSender: getContactByNameForRelationWithoutContactId,
              responseConverter: usersResponseConverter
            } }
            disabled={ disabled }
            onChange={ linkContactHandler }
            placeholder={ 'Start Typing Name...' }
          />
        )}

        { store.linkedContact && (
          <UserItem
            containerStyle={ {
              padding: 0
            } }
            onClose={ deleteContactHandler }
            user={ store.linkedContact }
            withCoreClassification
          />)
        }
      </FormBlock.FormField>
    </FormBlock.RowWrapper>
  );
});