import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { LEFT_COLUMN_WIDTH_V_THREE, RIGHT_COLUMN_WIDTH_V_THREE } from '@/modules/AddContactModal/data';

import { CheckedRadio, UnCheckedRadio } from '@/assets';

import { LeftColumnWrapperVariantThree, RightColumnWrapperVariantThree } from '@/modules/AddContactModal/components';
import FormBlock from '@components/FormBlock';

import { Container, Radio } from './styles';

const FIRST_NAME = '1.main.householdOwner';
const SECOND_NAME = '1.spouse.householdOwner';

export const HouseholdHead = () => {
  const { control, setValue } = useFormContext();

  const checkHandler = useCallback((event: any) => {
    const selectedName = event.target.value;
    const unselectedName = selectedName === SECOND_NAME ? FIRST_NAME : SECOND_NAME;

    setValue(selectedName, true);
    setValue(unselectedName, false);
  }, []);

  return (
    <>
      <LeftColumnWrapperVariantThree>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
          label={ 'Head of Household' }
        >
          <Container>
            <Controller
              control={ control }
              defaultValue={ true }
              name={ FIRST_NAME }
              render={ ({ field }) => {
                return (
                  <Radio
                    checked={ field.value }
                    checkedIcon={ <CheckedRadio /> }
                    icon={ <UnCheckedRadio /> }
                    onChange={ checkHandler }
                    size='small'
                    value={ FIRST_NAME }
                  />
                );
              } }
            />
          </Container>
        </FormBlock.FormField>
      </LeftColumnWrapperVariantThree>
      <RightColumnWrapperVariantThree>
        <FormBlock.FormField
          $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
          label={ 'Head of Household' }
        >
          <Container>
            <Controller
              control={ control }
              defaultValue={ false }
              name={ SECOND_NAME }
              render={ ({ field }) => {
                return (
                  <Radio
                    checked={ field.value }
                    checkedIcon={ <CheckedRadio /> }
                    icon={ <UnCheckedRadio /> }
                    onChange={ checkHandler }
                    size='small'
                    value={ SECOND_NAME }
                  />
                );
              } }
            />
          </Container>
        </FormBlock.FormField>
      </RightColumnWrapperVariantThree>
    </>
  );
};
