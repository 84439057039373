import { CONTACT_TYPES } from '@/shared/constants/common';

import { removeFalseValues } from '@/shared/utils/removeFalseValues';

import { cloneData } from '@/shared/utils/cloneData';

import {
  formatContacts,
  formatHouseholderContacts,
  formatNewContact,
  formatRelatedContacts,
  formatRules,
  formatTags
} from './helpers';
import {
  BusinessContactData,
  HouseHoldContactData,
  IndividualContactData,
  AddNewContactData } from '@/shared/types/addNewContactPopup';
import AddContactStore from '@/modules/AddContactModal/addContactStore/addContactStore';
import { FormatBusinessType, FormatHouseholdType, FormatIndividualType } from '../types';

const formatIndividualType = (data: IndividualContactData, store: AddContactStore): FormatIndividualType => {
  const { 1: firstStepData, 2: secondStepData, type } = cloneData(data);
  const { relatedContacts, spouseContact } = store;

  const contacts = formatContacts(secondStepData);
  console.log('contacts', contacts);
  const contactTags = formatTags(store.selectedTags);
  const relatedContact = formatRelatedContacts(relatedContacts, spouseContact);

  return {
    type,
    contacts,
    contactTags,
    relatedContact,
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(firstStepData)
  };
};

const formatHouseholdType = (data: HouseHoldContactData, store: AddContactStore): FormatHouseholdType => {
  const { 1: firstStepData, 2: secondStepData, type } = cloneData(data);
  const { main, spouse, ...restFirstStepData } = firstStepData;
  const { communicateWith, ...restSecondStepData } = secondStepData;
  const { relatedContacts, spouseContact, selectedTags } = store;

  const contacts = formatContacts(restSecondStepData);
  const contactTags = formatTags(selectedTags);
  const relatedContact = formatRelatedContacts(relatedContacts, spouseContact);

  const householderContacts = formatHouseholderContacts(
    main,
    spouse,
    communicateWith,
    contacts
  );

  return {
    type,
    contactTags,
    householderContacts,
    relatedContact,
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(restFirstStepData)
  };
};

const formatBusinessType = (data: BusinessContactData, store: AddContactStore): FormatBusinessType => {
  const { linkedContact } = store;
  const { 1: firstStepData, 2: secondStepData, 3: thirdStepData, type } = cloneData(data);

  const contacts = formatContacts(secondStepData);
  const contactTags = formatTags(store.selectedTags);

  const payload = {
    type,
    contacts,
    contactTags,
    relatedEmployees: formatNewContact(thirdStepData, linkedContact),
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(firstStepData)
  };

  return payload;
};

export const saveContactNormalizer = (data: AddNewContactData, store: AddContactStore) => {
  switch (data.type) {
  case CONTACT_TYPES.INDIVIDUAL:
    return formatIndividualType(data, store);
  case CONTACT_TYPES.HOUSEHOLD:
    return formatHouseholdType(data, store);
  case CONTACT_TYPES.BUSINESS:
    return formatBusinessType(data, store);
  default:
    return {};
  }
};
