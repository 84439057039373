import React from 'react';
import { observer } from 'mobx-react-lite';

import { ADD_CONTACT_TAGS_TYPES } from '@/modules/AddContactModal/addContactStore/data/constantData';
import { CONTACT_TYPES } from '@constants/common';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import { Tags } from '@/modules/AddContactModal/components';
import FormBlock from '@components/FormBlock';

import { TagsList } from '@/shared/types/addNewContactPopup';

export const TagsBlock = observer(() => {
  const { setSelectedTags, selectedTags, contactTags, selectedType } = useAddContactPopupStore();

  const setGeneralTags = (generalTags: TagsList['general']) => {
    setSelectedTags(generalTags, ADD_CONTACT_TAGS_TYPES.general);
  };

  const setInterestTags = (interestTags: TagsList['interest']) => {
    setSelectedTags(interestTags, ADD_CONTACT_TAGS_TYPES.interest);
  };

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Tags' } />
      <FormBlock.RowWrapper flexDirection={ 'column' }>
        <Tags
          contactTags={ selectedTags.general }
          setContactTags={ setGeneralTags }
          tagType='General'
          tagsToSelect={ contactTags.general }
        />
        { selectedType === CONTACT_TYPES.INDIVIDUAL && (
          <Tags
            contactTags={ selectedTags.interest }
            setContactTags={ setInterestTags }
            tagType='Interest'
            tagsToSelect={ contactTags.interest }
          />
        )}
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
