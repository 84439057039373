import { format } from 'date-fns';

import { convertedDatesToCurrentTimezone } from '@/shared/utils/convertedDatesToCurrentTimezone';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { CORE_CLASSIFICATIONS } from '@constants/common';
import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import { Client, ClientWithCalendarData } from '@/shared/types/commonTypes';
import { ClientConverter, EditActivityItem } from '@/shared/types/activityPopup';
import { EditActivityItemResponse } from '../types';
import { LinkedContact } from '@/shared/types/linkedContact';

const clientConverter = (clients: Array<ClientWithCalendarData>): Array<ClientConverter> => {
  if(!clients) {
    return [];
  }

  return clients.map((client: ClientWithCalendarData) => ({
    firstName: client.firstName,
    lastName: client.lastName,
    photo: client.headshot,
    id: client.id,
    calendarEndTime: client.calendarEndTime,
    calendarStartTime: client.calendarStartTime,
    classification: CORE_CLASSIFICATIONS.CLIENT,
    fullName: `${client.firstName} ${client.lastName}`,
    abbr: client.firstName.charAt(0) + client.lastName.charAt(0),
  }));
};

const contactConverter = (contact: LinkedContact): LinkedContact => {
  if(!contact) {
    return {} as LinkedContact;
  }

  return contact;
};

export const activityNormalizer = (
  item: EditActivityItemResponse, accountTimeZone: AccountTimeZone, timeZones: TimeZones
): EditActivityItem => {
  const dates = convertedDatesToCurrentTimezone({
    createdDate: item.createdDate || new Date(`${item.startDate}T${item.startTime}Z`),
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime,
    allDay: yesNoToBoolean(item.allDay),
    createdTimeZone: timeZones.find(timeZone => timeZone.value === item.createdTimeZone) || accountTimeZone
  }, accountTimeZone);

  const [ startDate, startTime ] = format(dates.startDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');
  const [ endDate, endTime ] = format(dates.endDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

  return {
    formData: {
      allDay: yesNoToBoolean(item.allDay),
      createdDate: item.createdDate,
      createdTimeZone: item.createdTimeZone,
      description: item.description,
      endDate,
      endTime,
      forDay: item?.forDay,
      id: item.id,
      name: item.name,
      primaryClientId: item.primaryClientId,
      primaryContactId: item.primaryContactId,
      recurring: yesNoToBoolean(item.recurring),
      //recurringEndDate: if "null" came from backend, we cannot set defaultValue (UseHookForm)
      recurringEndDate: item.recurringEndDate ?? undefined,
      recurringFrequency: item.recurringFrequency,
      startDate,
      startTime,
      statusId: item.statusId,
      subcategoryId: item.subcategoryId,
      type: typeConverter({
        isBackend: true,
        type: item.type,
      }),
      clients: item?.clients?.map((client: Client) => ({ userId: client.id })) || []
    },
    renderData: {
      primaryContact: contactConverter(item?.contact),
      clients: clientConverter(item?.clients)
    }
  };
};
