import React, { useCallback } from 'react';
import { useToggle } from '@customHooks';

import Counter from '@components/Counter';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomCheckboxWithoutLabel } from '@components/CustomCheckbox';

import {
  CheckboxWrapper,
  ClickArea,
  CounterWrapper,
  Header,
  IconWrapper,
  Wrapper,
} from './styles';

import { ContentToggleProps } from './types';

export const ContentToggle = ({
  CheckboxProps,
  children,
  count,
  disabled = false,
  isOpenInitValue = true,
  label,
}: ContentToggleProps) => {
  const [ isExpand, toggleExpand ] = useToggle(isOpenInitValue);
  
  const clickAreaHandler = useCallback(() => { toggleExpand(); }, [toggleExpand]);

  return (
    <Wrapper>
      <Header>
        <ClickArea onClick={ clickAreaHandler }>
          <IconWrapper>
            {
              isExpand
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />
            }
          </IconWrapper>
          {label}
          {
            Boolean(count) &&
            <CounterWrapper>
              <Counter>{ count }</Counter>
            </CounterWrapper>
          }
        </ClickArea>
        {
          CheckboxProps &&
          <CheckboxWrapper>
            <CustomCheckboxWithoutLabel
              $withPaddings={ false }
              checked={ CheckboxProps.isChecked }
              disabled={ disabled }
              indeterminate={ CheckboxProps.isIndeterminate }
              onChange={ CheckboxProps.onChange }
            />
          </CheckboxWrapper>
        }

      </Header>

      {isExpand && children}
    </Wrapper>
  );
};
