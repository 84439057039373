import { makeAutoObservable, flow, action, reaction, IReactionDisposer } from 'mobx';
import { isEmpty, merge } from 'lodash';
import { AxiosResponse } from 'axios';

import { getCountries, getStates } from '@services/api/common/common';
import getContactOptions from '@services/api/addContact/getContactOptions';
import { getTagsList } from '@services/api/tags/tags';

import { CONTACT_TYPES, ID_OF_USASTATES } from '@constants/common';
import { getDefaultValues } from './data/formValues';
import {
  initialStep,
  initialType,
  ADD_CONTACT_TAGS_TYPES,
} from './data/constantData';

import {
  businessOptionsNormalizer,
  houseHoldOptionsNormalizer,
  individualOptionsNormalizer
} from '@/shared/utils/contactOptionsNormalizers';
import {
  getContactRoute,
  getErrorStep,
  getTagsInitState,
  TagsNormalizer
} from './normilizers/parseNormilizer';
import { getStepData } from './normilizers/helpers';
import { getValidationSchema } from './data/validationSchemes';
import { saveContactNormalizer } from './normilizers/saveNormilizer';

import { InitProps, Options, OptionsResponse, ValidationSchema } from './types';
import {
  AddContactTagsTypes,
  AddNewContactData,
  AddNewContactDataFormValues,
  StepData,
  TagsList,
  UseFormMethods
} from '@/shared/types/addNewContactPopup';
import { LinkedContact } from '@/shared/types/linkedContact';
import { CloseModal, GetCountriesResponse, GetStatesResponse, IdType, ValueLabelObj } from '@/shared/types/commonTypes';
import { ContactRelatedItem } from '@/shared/types/contact';
import {
  BusinessOptionsDataBackendType,
  HouseholdOptionsDataBackendType,
  IndividualOptionsDataBackendType
} from '@/shared/types/contactOptionsTypes';
import { getStatesOptions } from '@/shared/utils/getStatesOptions';
import { getCountriesOptions } from '@/shared/utils/getOptionsUtils';
import { GetListTags } from '@/shared/types/tags';


class AddContactStore {
  contactTags = getTagsInitState();
  countriesOption: Array<ValueLabelObj> = [];
  currentUserId: IdType | null = null;
  formValues: AddNewContactDataFormValues = {} as AddNewContactDataFormValues;
  isFetching: boolean = false;
  linkedContact: LinkedContact | null= null;
  options: Options = {} as Options;
  relatedContacts: Array<ContactRelatedItem> = [];
  selectedStep: number = initialStep;
  selectedTags = getTagsInitState();
  selectedType: CONTACT_TYPES = initialType;
  spouseContact: ContactRelatedItem | null = null;
  statesOption: Array<ValueLabelObj> = [];
  stepData: StepData = [];
  useFormMethods: UseFormMethods = {} as UseFormMethods;
  validationSchema: ValidationSchema = {} as ValidationSchema;

  onDateFilterChangeDisposer: IReactionDisposer;

  saveCallback = (data: any) => {};
  closeCallback = () => {};

  constructor() {
    makeAutoObservable(this, {
      getContactOptions: flow.bound,
      getContactTags: flow.bound,
      saveContact: action.bound,
      setSelectedType: action.bound,
      resetFormValues: action.bound,
      validateForm: action.bound,
      setSelectedStep: action.bound,
      updateValues: action.bound,
      setSaveCallback: action.bound,
      setCloseCallback: action.bound,
      resetStore: action.bound,
      setCurrentUserId: action.bound,
      init: flow.bound,

      setIsFetching: action.bound,
      setExistingTags: action.bound,
      setLinkedContact: action.bound,
      setSelectedTags: action.bound,

      setSpouseContact: action.bound,
      setRelatedContacts: action.bound,
      setFormValues: action.bound,
      setValidationSchema: action.bound,

      setStepData: action.bound,
    });

    this.onDateFilterChangeDisposer = this.createOnDateFilterChangeReaction();

  }

  setIsFetching(state: boolean) {
    this.isFetching = state;
  }

  setSaveCallback(callback: any) {
    this.saveCallback = callback;
  }

  setCurrentUserId(id: IdType | null){
    this.currentUserId = id;
  }

  setCloseCallback(callback: CloseModal) {
    this.closeCallback = callback;
  }

  setExistingTags(data: TagsList) {
    this.contactTags = data;
  }

  setLinkedContact(data: LinkedContact | null) {
    this.linkedContact = data;
  }

  setSelectedType(value: CONTACT_TYPES) {
    this.selectedType = value;
  }

  setSelectedStep(step: number, formValues: AddNewContactDataFormValues) {
    this.updateValues(formValues);
    this.selectedStep = step;
  }

  setSelectedTags(data: any, type: AddContactTagsTypes) {
    if(type === ADD_CONTACT_TAGS_TYPES.general) {
      this.selectedTags.general = data;
    } else {
      this.selectedTags.interest = data;
    }
  }

  setSpouseContact(contact: ContactRelatedItem | null) {
    this.spouseContact = contact;
  }

  setRelatedContacts(data: Array<ContactRelatedItem>) {
    this.relatedContacts = data;
  }

  setFormValues(data: AddNewContactDataFormValues) {
    this.formValues = data;
  }

  setValidationSchema(schema: ValidationSchema) {
    this.validationSchema = schema;
  }

  setStepData() {
    this.stepData = getStepData(this.selectedType);
  }

  *init(props: InitProps) {
    const {
      saveCallback,
      closeModal,
      currentUserId,
      useFormMethods
    } = props;
    this.setSaveCallback(saveCallback);
    this.setCloseCallback(closeModal);
    this.setCurrentUserId(currentUserId);
    this.useFormMethods = useFormMethods;

    yield this.getOptions();
  }

  createOnDateFilterChangeReaction() {
    return reaction(
      () => this.selectedType,
      () => {
        this.getOptions();
      }
    );
  }

  *getOptions() {
    this.setIsFetching(true);

    try {
      yield this.getContactOptions();
      yield this.getStatesOption();
      yield this.getCountriesOption();

    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }  
  }

  *getContactOptions() {
    try {
      const route = getContactRoute(this.selectedType);
      const optionsResponse:OptionsResponse = yield getContactOptions(route);

      if(this.selectedType === CONTACT_TYPES.BUSINESS) {
        this.options = {
          ...businessOptionsNormalizer(optionsResponse.data.data as BusinessOptionsDataBackendType),
          type: this.selectedType
        };
      }
      if(this.selectedType === CONTACT_TYPES.HOUSEHOLD) {
        this.options = {
          ...houseHoldOptionsNormalizer(optionsResponse.data.data as HouseholdOptionsDataBackendType),
          type: this.selectedType
        };
      }
      if(this.selectedType === CONTACT_TYPES.INDIVIDUAL) {
        this.options = {
          ...individualOptionsNormalizer(optionsResponse.data.data as IndividualOptionsDataBackendType),
          type: this.selectedType
        };
      }

      if(this.currentUserId) {
        this.resetFormValues();

        this.setFormValues(getDefaultValues(this.selectedType, this.currentUserId));
        this.setStepData();
        this.setValidationSchema(getValidationSchema(this.selectedType));
  
        this.useFormMethods.reset(this.formValues);
      }
    } catch (error) {
      console.log(error);
    }
  }

  *getStatesOption() {
    if(isEmpty(this.statesOption)) {
      try {
        const response:AxiosResponse<GetStatesResponse> = yield getStates({ id: ID_OF_USASTATES });
  
        this.statesOption = getStatesOptions(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  *getCountriesOption() {
    if(isEmpty(this.countriesOption)) {
      try {
        const response:AxiosResponse<GetCountriesResponse> = yield getCountries();

        this.countriesOption = getCountriesOptions(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  saveContact() {
    const data = saveContactNormalizer({
      ...this.formValues,
      type: this.selectedType
    } as AddNewContactData, this);
    this.closeCallback();
    this.saveCallback(data);
  }

  *getContactTags() {
    try {
      const response: GetListTags = yield getTagsList();
      this.setExistingTags(TagsNormalizer(response.data.data));
    } catch (error) {
      console.log(error);
    }
  }

  resetFormValues() {
    this.spouseContact = null;
    this.linkedContact = null;
    this.relatedContacts = [];
    this.selectedTags = getTagsInitState();
    this.selectedStep = initialStep;
  }

  updateValues(formValues: AddNewContactDataFormValues) {
    this.formValues = merge(this.formValues, formValues);
  }

  validateForm(formValues: AddNewContactDataFormValues) {
    this.updateValues(formValues);
    try {
      this.validationSchema.validateSync(this.formValues, {
        abortEarly: false
      });
      return false;
    } catch (error) {
      //@ts-ignore
      return getErrorStep(error.inner);
    }
  }

  resetStore() {
    this.selectedType = initialType;
    this.currentUserId = null;
    this.options = {} as Options;
  }
}

export default AddContactStore;
