import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { useStore } from '@store';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';
import { ENTITY_NAMES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { AccountsAndPlansPopup } from '@/modules/AccountsAndPlansPopup';
import { BusinessDetails, ContactInformation } from './components';
import { SalesClientOpportunitiesDeletePopup } from '@/pages/SalesCycle/components/SalesClientOpportunities/components';
import { TodoPopup } from '@modules/TodoPopup';
import NotesAndHistory, { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';
import SalesActiveCycleDeletePopup from '@/modules/SalesPipline/SalesActiveCycleDeletePopup';
import SalesActivePopup from '@/modules/SalesPipline/SalesActivePopup';
import SalesClientOpportunityPopup from '@/modules/SalesPipline/SalesClientOpportunityPopup';
import Surface from '@components/BasicLayoutComponents/Surface';

import { NotesAndHistoryWrapper, OverviewWrapper, SURFACE_CONTENT_WRAPPER_STYLE_PROPS } from './styles';

import { ContactDetailsOverviewProps } from './types';
import { ContactOverviewFormFields } from '@/shared/types/contactOverview';
import { EntityNameValues, IdType, ValueOf } from '@/shared/types/commonTypes';
import { OnRemoveClientOpportunities } from '@/shared/types/salesCycleOpportunity';
import { SalesCycleDeleteData } from '@/shared/types/salesActiveCycle';


export const ContactDetailsOverview = observer(({
  contactId,
}: ContactDetailsOverviewProps) => {
  const modalStore = useStore().ModalStore;
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    contact,
    init,
    notesAndHistoryStore,
    notesUtilsStore,
    predefinedTags,
    resetStore,
  } = contactDetailsOverview;
  const { saveAccountsAndPlans } = useStore().ContactDetailsOverview.accountAndPlanStore;
  const { saveOpportunity, removeOpportunity } = useStore().ContactDetailsOverview.opportunityStore;
  const { saveSalesCycle, removeSalesCycle } = useStore().ContactDetailsOverview.salesCycleStore;
  const { removeTodo, saveTodo } = useStore().ContactDetailsOverview.todoStore;

  const methods = useForm<ContactOverviewFormFields>();
  const { profile } = useStore().SettingsStore;

  const onDeleteSalesCycleWrapper = useCallback((data: SalesCycleDeleteData) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_SALES_ACTIVE_CYCLE,
      component: SalesActiveCycleDeletePopup,
      modalProps: {
        onConfirm: removeSalesCycle,
        deleteData: data
      }
    });
  }, [modalStore, removeSalesCycle]);

  const onRemoveClientOpportunity = useCallback<OnRemoveClientOpportunities>((data) => {
    const onConfirm = () => removeOpportunity(data);
    modalStore.openModal({
      component: SalesClientOpportunitiesDeletePopup,
      modalProps: {
        onConfirm
      },
      modalType: MODAL_TYPE.REMOVE_CLIENT_OPPORTUNITY,
    });
  }, [modalStore, removeOpportunity]);

  const onEntityClick = useCallback((id: IdType, entityName: EntityNameValues) => {
    if(entityName === ENTITY_NAMES.task) {
      modalStore.openModal({
        modalType: MODAL_TYPE.TODO_TASK_MODAL,
        modalProps: {
          id,
          initLinkedContact: null,
          saveCallback: saveTodo,
          deleteCallback: removeTodo,
        },
        component: TodoPopup
      });
    } else if(entityName === ENTITY_NAMES.account ||
      entityName === ENTITY_NAMES.plan ||
      entityName === ENTITY_NAMES.policy
    ) {
      modalStore.openModal({
        modalType: MODAL_TYPE.ACCOUNTS_AND_PLANS_MODAL,
        modalProps: {
          id,
          outerOnSubmit: saveAccountsAndPlans
        },
        component: AccountsAndPlansPopup
      });
    } else if(entityName === ENTITY_NAMES.salesCycle) {
      modalStore.openModal({
        modalType: MODAL_TYPE.ADD_SALES_ACTIVE_CYCLE,
        modalProps: {
          id: Number(id),
          onSaveCallback: saveSalesCycle,
          onDeleteCallback: onDeleteSalesCycleWrapper
        },
        component: SalesActivePopup
      });
    } else if(entityName === ENTITY_NAMES.opportunity) {
      modalStore.openModal({
        modalType: MODAL_TYPE.ADD_CLIENT_OPPORTUNITY,
        modalProps: {
          id: Number(id),
          onSaveCallback: saveOpportunity,
          onDeleteCallback: onRemoveClientOpportunity
        },
        component: SalesClientOpportunityPopup
      });
    }
  }, [ modalStore ]);

  useEffect(() => {
    init(contactId, methods);
    return resetStore;
    // eslint-disable-next-line
  }, [contactId]);

  if(!contact) {
    return null;
  }

  return (
    <Surface.Content
      style={ SURFACE_CONTENT_WRAPPER_STYLE_PROPS }>
      <OverviewWrapper>
        <ContactInformation/>
        <BusinessDetails/>
        <NotesAndHistoryWrapper>
          {/* TODO: note refactoring */}
          {/* @ts-ignore */}
          <NotesAndHistory
            control={ methods.control }
            currentUser={ profile }
            instanceStore={ notesAndHistoryStore }
            isAddNoteBtnDisabled={ false }
            linkedContactData={ contact }
            names={ CONTACT_NOTES_AND_HISTORY_INPUT_NAMES }
            noteAndHistoryType={ NOTE_AND_HISTORY_TYPE.general }
            noteContactOptions={ notesUtilsStore.noteContactOptions }
            onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
            onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
            onEntityClick={ onEntityClick }
            predefinedTags={ predefinedTags }
            title={ 'Notes & History Timeline ' }
          />
        </NotesAndHistoryWrapper>
      </OverviewWrapper>
    </Surface.Content>
  );
});
