import ApiService from '@services/api/api.service';

const CONTINUE_SESSION_URL = 'api/client/continueSession';

const getAppSettings = () => {
  return ApiService.get('api/crm/client/getAppSettings');
};

const getContactByName = (params) => {
  return ApiService.get('/api/crm/contact/searchContactByName', { params });
};

const getViewedContact = () => {
  return ApiService.get('/api/contact/getContactLogsOpened');
};

const searchContactByNameWithHousehold = (params) => {
  return ApiService.get('/api/crm/contact/searchContactByNameWithHousehold', { params });
};

const searchContactByNamePhoneEmail = (params) => {
  return ApiService.get('/api/contact/contactSearchByNamePhoneEmail', { params });
};

const getClientByName = (params) => {
  return ApiService.get('/api/crm/contact/searchClientByName', { params });
};

const getActiveClientByName = (params) => {
  return ApiService.get('/api/crm/client/searchActiveClientByName', { params });
};

const getContactsForEmployees = (params) => {
  return ApiService.get('/api/crm/contact/searchContactByNameForEmployees', { params });
};

const getContactWithSpouseHousehold  = (params) => {
  return ApiService.get('/api/crm/contact/searchContactByNameWithSpouseHousehold', { params });
};

const getContactWithAccountPlansOpportunities  = (params) => {
  return ApiService.get('/api/crm/contact/searchContactOpportunityAccountPlans', { params });
};

const getContactSalesPipelineOpportunitiesAccountPlans = (params) => {
  return ApiService.get('/api/crm/contact/getOpportunityAccountPlansTaskByContactId', { params });
};

const searchClientsAdvisor = (params) => {
  return ApiService.get('/api/crm/client/searchUserAdvisor', { params });
};

const getCountries = () => {
  return ApiService.get('api/setting/getCountries');
};

const getStates = (params) => {
  return ApiService.get('api/setting/getStates', { params });
};

const continueSession = (token) => {
  const options = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return ApiService.get(CONTINUE_SESSION_URL);
};

const verifiedEmailByHash = (params) => {
  return ApiService.get('api/crm/client/verified_email_hash', { params });
};

const createPassword = (params) => {
  return ApiService.post('api/restore_password_token', params);
};

const restorePassword = (params) => {
  return ApiService.post('api/restore_password', params);
};

const updateClientTimeZone = (params) => {
  return ApiService.patch('api/crm/client/updateCurrentClientTimeZone', params);
};

export {
  CONTINUE_SESSION_URL,
  continueSession,
  createPassword,
  getActiveClientByName,
  getAppSettings,
  getClientByName,
  getContactByName,
  getContactsForEmployees,
  getContactSalesPipelineOpportunitiesAccountPlans,
  getContactWithAccountPlansOpportunities,
  getContactWithSpouseHousehold,
  getCountries,
  getStates,
  getViewedContact,
  restorePassword,
  searchClientsAdvisor,
  searchContactByNamePhoneEmail,
  searchContactByNameWithHousehold,
  updateClientTimeZone,
  verifiedEmailByHash,
};
