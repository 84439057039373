import React from 'react';

import { StyledLink } from './styles';

import { RouterLinkProps } from './types';

export const RouterLink = ({
  children,
  styles,
  to,
}: RouterLinkProps) => (
  <StyledLink
    style={ styles }
    to={ to }
  >
    { children }
  </StyledLink>
);