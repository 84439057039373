import styled from 'styled-components';

export const Spacer = styled.div`
  width: 1300px;
  height: 750px;
`;

export const TopPanelWrapper = styled.div`
  & button {
    flex-grow: 1;
    padding: 8px 0px;
  }
`;
