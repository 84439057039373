import * as yup from 'yup';

import { CONTACT_TYPES } from '@constants/common';
import { validationMessages as errors } from '@constants/validationMessages';
import {
  dateOutputValidation,
  simpleEmailPattern,
} from '@constants/validationPatterns';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { getIsOnlyCountryCode, phoneValidation } from '@modules/HookFormComponents/HFPhoneField/validation';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

const commonFirstStep = {
  firstName: yup.string().required(errors.invalidFirstName),
  lastName: yup.string().required(errors.invalidLastName),
  birthdate: yup
    .string()
    .nullable()
    .matches(dateOutputValidation, errors.invalidBirthday)
    .test(
      'mast be equal or less than Today',
      errors.invalidDate,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    )
};

const commonProfileDetails = {
  classification: yup.string().required(errors.invalidCoreClassification),
  officePrimaryAdvisor: yup.string().nullable().required(errors.invalidPrimaryAdvisor)
};

const commonMobilePhone = {
  mobilePhone: phoneValidation
};

const commonEmail = {
  email: yup.string().email(errors.invalidEmail)
};


const individualTypeSchema = yup.object().shape({
  1: yup.object().shape({
    ...commonFirstStep,
    ...commonProfileDetails,
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
    ...commonEmail
  }),
});

const householdTypeSchema = yup.object().shape({
  1: yup.object().shape({
    main: yup.object().shape(commonFirstStep),
    spouse: yup.object().shape(commonFirstStep),
    ...commonProfileDetails
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
    ...commonEmail
  })
});

const businessTypeSchema = yup.object().shape({
  1: yup.object().shape({
    occupationCompany: yup.string().required(errors.invalidBusinessName),
    birthdate: yup
      .string()
      .nullable()
      .matches(dateOutputValidation, errors.invalidDate)
      .test(
        'mast be equal or less than Today',
        errors.invalidDate,
        (value) => (
          isDateBefore({
            dateStringToCompare: getFormatedDateNow(),
            dateString: value,
            withEqual: true,
          })
        )
      ),
    ...commonProfileDetails
  }),
  2: yup.object().shape({
    ...commonMobilePhone,
    ...commonEmail
  }),
  3: yup.object().shape({
    firstName: yup.string()
      .test('match', errors.invalidFirstName, function (value) {
        const { lastName, phoneNumber, email } = this.parent;
        // TODO by default the phone number must be set NULL if only the country code is entered
        const isOnlyCountryCode = getIsOnlyCountryCode(phoneNumber || '');

        const isAtLeastOneFieldFilled = Boolean(lastName.length || !isOnlyCountryCode || email.length);
        if(!value && isAtLeastOneFieldFilled) {
          return false;
        }
        return true;
      }),
    lastName: yup.string()
      .test('match', errors.invalidLastName, function (value) {
        const { firstName, phoneNumber, email } = this.parent;
        // TODO by default the phone number must be set NULL if only the country code is entered
        const isOnlyCountryCode = getIsOnlyCountryCode(phoneNumber || '');

        const isAtLeastOneFieldFilled = Boolean(firstName.length || !isOnlyCountryCode || email.length);
        if(!value && isAtLeastOneFieldFilled) {
          return false;
        }
        return true;
      }),
    phoneNumber: phoneValidation,
    email: yup.string()
      .matches(simpleEmailPattern, {
        excludeEmptyString: true,
        message: errors.invalidEmail
      })
  })
});

export const getValidationSchema = (type: CONTACT_TYPES): yup.ObjectSchema<any> => {
  switch (type) {
  case CONTACT_TYPES.INDIVIDUAL:
    return individualTypeSchema;
  case CONTACT_TYPES.HOUSEHOLD:
    return householdTypeSchema;
  case CONTACT_TYPES.BUSINESS:
    return businessTypeSchema;
  default:
    return {} as yup.ObjectSchema<any>;
  }
};
