import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { format, parse, addMinutes } from 'date-fns';

import { TimeAdder } from '@components/TimeAdder';

import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import { TIME_BUTTONS_CONFIG } from './data';
import { ActivityPopupFieldsName } from '@modules/ActivityPopup/data';


export const TimeButtons = () => {

  const { getValues, setValue } = useFormContext();

  const [ selectedValue, setSelectedValue ] = useState<number | null>(null);

  const onSelect = useCallback((value: number) => {
    const {
      startTime,
      startDate,
    } = getValues();

    if((!startDate && !startTime) || (selectedValue === value)){
      return ;
    }

    const fullDate = parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date());
    const increasedFullDate = addMinutes(fullDate, value);
    const [ dateString, timeString ] = format(increasedFullDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

    setSelectedValue(value);
    setValue(ActivityPopupFieldsName.endDate, dateString);
    setValue(ActivityPopupFieldsName.endTime, timeString);
  }, []);

  return (
    <TimeAdder
      buttonsConfig={ TIME_BUTTONS_CONFIG }
      onSelect={ onSelect }
      selectedValue={ selectedValue }
    />
  );
};
