import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { COLUMNS } from '@constants/todosData';
import { ENTITY_NAMES } from '@constants/common';

import { TodosRow } from '@modules/TodosRow';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table, { useGlobalCheckbox, useRowCheckbox } from '@components/Table';
import RouterLink from '@modules/RouterLink';

import { IdType } from '@/shared/types/commonTypes';
import { TodoGridFormField, TodoItem } from '@/shared/types/todos';
import { TodosTableProps } from './types';
import { getEntityRoutePath } from '@/shared/utils/getEntityRoutePath';

export const TodosTable = observer(({
  onDeleteTodo,
}: TodosTableProps) => {
  const { url } = useRouteMatch();

  const contactDetailsTodosStore = useStore().ContactDetailsTodosStore;
  const { isLoad } = useStore().ContactDetailsStore;

  const {
    table,
    todoPopupSettings: {
      priority,
      stage
    },
    isPageActive
  } = contactDetailsTodosStore;

  const {
    getCheckboxState,
    onRowCheckboxClick
  } = useRowCheckbox({
    onGlobalCheckboxClickCallback: table.setSelectedIds,
    selectedIds: table.selectedIDs,
  });

  const {
    isGlobalCheckboxChecked,
    isGlobalCheckboxIndeterminate,
    onGlobalCheckboxClick
  } = useGlobalCheckbox({
    pageItems: table.items,
    selectedIds: table.selectedIDs,
    onGlobalCheckboxClickCallback: table.setSelectedIds
  });

  const updateFromGrid = useCallback((allFieldsObject: TodoGridFormField) => {
    contactDetailsTodosStore.updateFromGrid(allFieldsObject);
  }, [ contactDetailsTodosStore ]);

  const onRowBookmark = useCallback((id: IdType, state: boolean) => {
    contactDetailsTodosStore.updateFlags([{ id }], state);
  }, [ contactDetailsTodosStore ]);

  const taskEntityPath = getEntityRoutePath(url, ENTITY_NAMES.task, 'id');
  const addActionButtonLabel = 
    <RouterLink to={ taskEntityPath }>
      Add Task
    </RouterLink>;

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: isGlobalCheckboxChecked,
            indeterminate: isGlobalCheckboxIndeterminate,
            onChange: onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isLoad && table.items.length > 0 && table.items.map((item: TodoItem) => (
              <TodosRow
                data={ item }
                isCheckboxActive={ getCheckboxState(item) }
                isLinkedContactClickable={ false }
                key={ item.id }
                onCheckboxClickCb={ onRowCheckboxClick }
                onDeleteTodo={ onDeleteTodo }
                onRowBookmarkCb={ onRowBookmark }
                priorityOptions={ priority }
                stageOptions={ stage }
                updateFromGrid={ updateFromGrid }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isLoad && table.items.length === 0 && (
            <ContentCenter>
              <NoDataItemsView
                //@ts-ignore TODO: NoDataItemsView migrate to TS
                addActionButtonLabel={ addActionButtonLabel }
                withAction={ true }
                withInfo={ false }
              />
            </ContentCenter>
          )
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});
