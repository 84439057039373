import * as yup from 'yup';

import { dateOutputValidation, simpleEmailPattern } from '@constants/validationPatterns';
import { FIELDS_NAMES } from './data';
import { USER_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsUserCustomFields';
import { validationMessages } from '@constants/validationMessages';
import { requiredPhoneValidation } from '@modules/HookFormComponents/HFPhoneField/validation';

const commonValidation = {
  [FIELDS_NAMES.value]: yup.string()
    .required(validationMessages.required)
};

const dateValidation = {
  [FIELDS_NAMES.value]: yup.string()
    .required(validationMessages.required)
    .matches(dateOutputValidation, validationMessages.invalidDate)
};

const emailValidation = {
  [FIELDS_NAMES.value]: yup.string()
    .required(validationMessages.required)
    .matches(simpleEmailPattern, validationMessages.invalidEmail)
};

const phoneValidation = {
  [FIELDS_NAMES.value]: requiredPhoneValidation
};

export const validationSchema = yup.lazy((fields) => {
  switch (fields.currentType) {
  case USER_CUSTOM_FIELDS_TYPES.dateField:
    return yup.object().shape({ ...dateValidation });
  case USER_CUSTOM_FIELDS_TYPES.emailField:
    return yup.object().shape({ ...emailValidation });
  case USER_CUSTOM_FIELDS_TYPES.phoneField:
    return yup.object().shape({ ...phoneValidation });
  default: 
    return yup.object().shape({ ...commonValidation });
  }
});