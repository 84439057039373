import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTACT_TYPES } from '@constants/common';
import { LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH } from '@/modules/AddContactModal/data';
import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import { maxInputLength } from '@/shared/utils/maxInputLength';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import { LeftColumnWrapper, RightColumnWrapper } from '@/modules/AddContactModal/components';


export const BasicInformation = () => {
  const { options } = useAddContactPopupStore();

  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom={ false }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Basic Information' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH  }
            isRequired={ true }
            label={ 'Business Name' }>
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name='1.occupationCompany'
              placeholder='Enter Business Name'
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        { options.type === CONTACT_TYPES.BUSINESS &&
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH  }
              label={ '# Employees' }
            >
              <HFSelect
                control={ control }
                name={ '1.employeeCount' }
                options={ options.employees }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        }
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        { options.type === CONTACT_TYPES.BUSINESS &&
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Company Type' }
            >
              <HFSelect
                control={ control }
                name={ '1.companyType' }
                options={ options.companyType }
                placeholder={ 'Select Company Type' }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        }
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Annual Revenue' }
          >
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name={ '1.annualRevenue' }
              placeholder={ 'Enter Annual Revenue' }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};
