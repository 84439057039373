import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { RIGHT_COLUMN_WIDTH_V_TWO } from '@/modules/AddContactModal/data';

export const commonOptions = [
  { value: 'Both',label: 'Both' },
];


export const CommunicateWith = () => {

  const { control } = useFormContext();

  const [firstName, lastName, spouseFirstName, spouseLastName] = useWatch({
    control,
    name: ['1.main.firstName', '1.main.lastName', '1.spouse.firstName', '1.spouse.lastName']
  });

  const options = useMemo(() => {
    if(!firstName || !lastName || !spouseFirstName || !spouseLastName){
      return commonOptions;
    }

    return [
      {
        label: `${firstName} ${lastName}`,
        value: 1
      },
      {
        label: `${spouseFirstName} ${spouseLastName}`,
        value: 2
      },
      ...commonOptions,
    ];
  },[firstName, lastName, spouseFirstName, spouseLastName]);

  return (
    <FormBlock.FormField
      $labelWidth={ RIGHT_COLUMN_WIDTH_V_TWO }
      label={ 'Comm. With' }
    >
      <HFSelect
        control={ control }
        disabled={ options.length === 1 }
        name={ '2.communicateWith' }
        options={ options }
      />
    </FormBlock.FormField>
  );
};
