import { flow, makeAutoObservable } from 'mobx';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { Store } from '@store';

import { deleteClientOpportunity, saveClientOpportunity } from '@services/api/salesPipeline/clientOpportunities';

import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { ClientOpportunityFormFields, ClientOpportunityRemoveData } from '@/shared/types/salesCycleOpportunity';
import { CloseModal } from '@/shared/types/commonTypes';

export default class OpportunityStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  coreStore: Store;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      saveOpportunity: flow.bound,
      removeOpportunity: flow.bound
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.clientOpportunity
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *saveOpportunity(closeModal: CloseModal, data: ClientOpportunityFormFields){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      closeModal();

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveClientOpportunity(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: true,
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: false,
        })
      });

      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *removeOpportunity(data: ClientOpportunityRemoveData){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const idsToDelete = Array.isArray(data) ? data : [data];

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => deleteClientOpportunity({
          ids: idsToDelete
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          isAutoUniqueKey: true
        })
      });

      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
}