import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getContactWithSpouseHousehold } from '@services/api/common/common';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import {
  LeftWrapper,
  RightWrapper
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import {
  ACCOUNT_ACCOUNT_TYPES,
  DEFAULT_SELECTED,
  HELD_AWAY_STATUSES,
  LABEL_SIZES,
  MANAGED_STATUSES,
} from '@modules/AccountsAndPlansPopup/data/data';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

import {
  COMMON_FIELD_NAMES,
  BASIC_INFORMATION_FIELDS_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

const { leftLabelWidth, rightLabelWidth } = LABEL_SIZES;

const LinkedContactFetchProps = {
  getParams: getNameParam,
  requestSender: getContactWithSpouseHousehold,
  responseConverter: usersResponseConverter,
};

export const PrimaryDetails = observer(() => {
  const routerStore = useStore().RouterStore;

  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const { popupSettings, linkedContactData, closeModal, reset } = accountsAndPlansLocalStore;
  const { isNotesPanelInAddOrEditMode } = accountsAndPlansLocalStore.notesUtilsStore;
  const prevSubTypeName = useRef<string | null>(null);

  const { control, setValue,getValues } = useFormContext<FormFieldsValues>();

  const [ typeFieldValue, accountTypeValue, managedStatusValue, heldAwayStatusValue ] = useWatch<FormFieldsValues>({
    control: control,
    name: [
      BASIC_INFORMATION_FIELDS_NAMES.type,
      COMMON_FIELD_NAMES.accountType,
      BASIC_INFORMATION_FIELDS_NAMES.managedStatus,
      BASIC_INFORMATION_FIELDS_NAMES.heldAwayStatus,
    ]
  });

  const onContactClick = useCallback(() => {
    if(linkedContactData) {
      closeModal();
      reset();
  
      routerStore.moveToContact({
        id: linkedContactData?.id,
        contactType: linkedContactData?.type,
        tab: CONTACT_DETAILS_TAB.overview,
      });  
    }
  }, [linkedContactData]);

  const calculatedOptions = useMemo(() => {
    const isAccountTypeAccount = accountTypeValue === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT;
    const type = isAccountTypeAccount
      ? popupSettings.accountType
      : popupSettings.policyType;

    const accountSubtype = typeFieldValue === DEFAULT_SELECTED.typeForAccount
      ? popupSettings.accountSubTypeCashEquivalent
      : typeFieldValue === ACCOUNT_ACCOUNT_TYPES.investments ? popupSettings.accountSubTypeInvestments : [];

    const policySubType = typeFieldValue === DEFAULT_SELECTED.typeForPolicy
      ? popupSettings.policySubTypeAnnuities
      : popupSettings.policySubTypeLifeInsurance;

    const subType = isAccountTypeAccount
      ? accountSubtype
      : policySubType;

    return {
      type,
      subType,
    };
  },[
    accountTypeValue, popupSettings.accountType, popupSettings.policyType, popupSettings.accountSubTypeCashEquivalent,
    popupSettings.accountSubTypeInvestments, popupSettings.policySubTypeAnnuities,
    popupSettings.policySubTypeLifeInsurance, typeFieldValue
  ]);

  const subTypeFieldName = useMemo(() => {
    let name;
    if(accountTypeValue === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT){
      name = typeFieldValue === ACCOUNT_ACCOUNT_TYPES.cashAndEquivalent
        ? BASIC_INFORMATION_FIELDS_NAMES.subTypeCashEquivalent
        : BASIC_INFORMATION_FIELDS_NAMES.subTypeInvestments
      ;
    } else {
      name =  BASIC_INFORMATION_FIELDS_NAMES.subTypeAnnuities;
    }

    if(prevSubTypeName.current && prevSubTypeName.current !== name){
      //@ts-ignore
      setValue(prevSubTypeName.current, '');
    }

    prevSubTypeName.current = name;
    return name;
  }, [accountTypeValue, setValue, typeFieldValue]);

  useEffect(() => {
    const subtypeValue = getValues(subTypeFieldName);
    const currentSubtypeOptionIndex = calculatedOptions.subType.findIndex(option => option.value === subtypeValue);
    if(currentSubtypeOptionIndex < 0){
      setValue(subTypeFieldName, '');
    }

  },[calculatedOptions.subType, getValues, setValue, subTypeFieldName]);

  useEffect(() => {
    if(managedStatusValue === MANAGED_STATUSES.managed && heldAwayStatusValue){
      setValue(BASIC_INFORMATION_FIELDS_NAMES.heldAwayStatus, DEFAULT_SELECTED.heldAwayStatus);
    }

    if(managedStatusValue === MANAGED_STATUSES.heldAway && !heldAwayStatusValue){
      setValue(BASIC_INFORMATION_FIELDS_NAMES.heldAwayStatus, HELD_AWAY_STATUSES.movable);
    }
  }, [managedStatusValue, heldAwayStatusValue, setValue]);

  useEffect(() => {
    if(typeFieldValue === ACCOUNT_ACCOUNT_TYPES.otherAccount){
      setValue(subTypeFieldName, '');
    }
  }, [typeFieldValue, setValue, subTypeFieldName]);

  return (
    <>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Primary Details' }/>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ leftLabelWidth }
            isRequired={ true }
            label={ 'Name' }
          >
            <HFTextInput
              inputProps={ {
                placeholder: 'Enter Account Name',
                maxLength: 150,
              } }
              control={ control }
              disabled={ isNotesPanelInAddOrEditMode }
              name={ COMMON_FIELD_NAMES.name }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ leftLabelWidth }
            isRequired={ true }
            label={ 'Linked Contact' }
          >
            <HFLinkedContact
              AutocompleteProps={ {
                disablePortal: true
              } }
              FetchProps={ LinkedContactFetchProps }
              UserItemProps={ {
                $isClickable: true,
                disabled: isNotesPanelInAddOrEditMode,
                onClick: onContactClick  
              } }
              control={ control }
              disabled={ isNotesPanelInAddOrEditMode }
              initLinkedContact={ linkedContactData }
              name={ COMMON_FIELD_NAMES.contactId }
              onLinkedContactChange={ accountsAndPlansLocalStore.setLinkedContactData.bind(accountsAndPlansLocalStore) }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              isRequired={ true }
              label={ 'Type' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.type }
                options={ calculatedOptions.type }
                placeholder={ 'Type' }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Status' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.status }
                options={ popupSettings.status }
                placeholder={ 'Select Account Status' }
                resetLabel={ 'Select Account Status' }
                resettable={ true }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Sub-Type' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode ||  typeFieldValue === ACCOUNT_ACCOUNT_TYPES.otherAccount }
                name={ subTypeFieldName }
                options={ calculatedOptions.subType }
                placeholder={ 'Select Account Sub-Type' }
                resetLabel={ 'Select Account Sub-Type' }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Tax Qualification' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.taxQualification }
                options={ popupSettings.taxQualification }
                placeholder={ 'Select Tax Qualification' }
                resetLabel={ 'Select Tax Qualification' }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Managed Status' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.managedStatus }
                options={ popupSettings.managedStatus }
                placeholder={ 'Select Managed Status' }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Held Away Status' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode  || managedStatusValue ===  DEFAULT_SELECTED.managedStatus }
                name={ BASIC_INFORMATION_FIELDS_NAMES.heldAwayStatus }
                options={ popupSettings.heldAwayStatus }
                placeholder={ 'Select Held Away Status' }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});
