import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import LimitAccessPopup from '@modules/LimitAccessPopup';
import Table from '@components/Table';
import {
  AccountAndPlansCells,
  ActivitiesCells,
  ClientOpportunitiesCells,
  CommonCells,
  ContactsCells,
  ListsCells,
  SalesCyclesCells,
  TasksCells,
} from './components';
import { OutOfSightTD } from '@components/OutOfSight';

import { MODAL_TYPE } from '@constants/modalTypes';

import { getIsAbleToSetUserPermission } from '@/pages/NewUserSettings/utils';
import assertUnreachable from '@/shared/utils/assertUnreachable';

import {
  AccountsAndPlansNormalizerItem,
  ActivityNormalizerItem,
  ClientOpportunitiesNormalizerItem,
  ContactNormalizerItem,
  ListsNormalizerItem,
  SalesCyclesNormalizerItem,
  TasksNormalizerItem
} from '@services/store/trashStore/types';
import { TrashRowProps } from './types';
import { TrashTabValues } from '@pages/Trash/types';


export const TrashRow = observer(({
  currentTrashView,
  dataItem,
  isCheckboxActive,
  onCheckboxClickCb,
  onPermanentlyDeleteClick,
  onRestoreClick,
  trashStorageDays
}: TrashRowProps) => {
  const {
    item,
  } = dataItem;

  const modalStore = useStore().ModalStore;
  const { roles } = useStore().SettingsStore;
  const { filters } = useStore().TrashStore;
  const isAbleRole = getIsAbleToSetUserPermission(roles);

  const trashViewCells = useMemo(() => {
    switch(currentTrashView) {
    case TrashTabValues.Contacts: 
      return <ContactsCells item={ item as ContactNormalizerItem['item'] }/>;
    case TrashTabValues.Activities: 
      return <ActivitiesCells item={ item as ActivityNormalizerItem['item'] }/>;
    case TrashTabValues.Tasks: 
      return <TasksCells item={ item as TasksNormalizerItem['item'] }/>;
    case TrashTabValues.AccountsAndPlans: 
      return <AccountAndPlansCells item={ item as AccountsAndPlansNormalizerItem['item'] }/>;
    case TrashTabValues.Lists: 
      return <ListsCells item={ item as ListsNormalizerItem['item'] } filters={ filters }/>;
    case TrashTabValues.SalesCycles: 
      return <SalesCyclesCells item={ item as SalesCyclesNormalizerItem['item'] }/>;
    case TrashTabValues.ClientOpportunities: 
      return <ClientOpportunitiesCells item={ item as ClientOpportunitiesNormalizerItem['item'] }/>;
    default: {
      assertUnreachable(currentTrashView, `Unexpected trash view: ${currentTrashView}`);
    }
    }
  }, [ currentTrashView, item ]);

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onCheckboxClick = useCallback(() => {
    onCheckboxClickCb(dataItem);
  }, [ onCheckboxClickCb, dataItem ]);

  const isBlocked = typeof dataItem.editAllow === 'number' && !dataItem?.editAllow;

  return (
    <Table.Row
      $preventHover={ true }
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ () => { } }
      role="checkbox"
      tabIndex={ -1 }
    >
      <Table.CellWithControls
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClick
        } }
      />

      { trashViewCells }

      <CommonCells
        dataItem={ dataItem }
        isAbleRole={ isAbleRole }
        onPermanentlyDeleteClick={ onPermanentlyDeleteClick }
        onRestoreClick={ onRestoreClick }
        trashStorageDays={ trashStorageDays }
      />
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});
