import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { COLORS } from '@constants/colors';
import { MODAL_TYPE } from '@constants/modalTypes';
import { STAGES } from '@constants/todosData';

import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getDueDate } from './utils';
import { getDueDateColor } from '@/shared/utils/getDueDateColor';
import { getPriorityColor } from '@/shared/utils/getPriorityColor';
import { isFutureDate } from '@/shared/utils/isFutureDate';

import { OutOfSightTD } from '@components/OutOfSight';
import Avatar from '@components/Avatar';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { DoneMarkWithCircle, OtherTasks } from '@/assets';

import { CompletedIconWrapper, DueDate, TaskIconWrapper, TextCellWrapper } from './styles';
import { FormCellWrapper } from '@modules/TodosRow/styles';

import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import { ToDosRowProps } from './types';
import { TypeSelectedRow } from '@components/Table/components/Row/types';


export const PendingTasksRow = observer(({
  data,
  isShowCompletedTasks,
  onEditTodo,
}: ToDosRowProps) => {

  const modalStore = useStore().ModalStore;

  const isFutureDueDate = isFutureDate(data.isAssigned ? data.reviewDate : data.dueDate);

  const isBlocked = typeof data?.editAllow === 'number' && !data?.editAllow;

  const isFormDisabled = data.stage === STAGES.complete || data.stage === STAGES.skipped;

  const mainImageData = useMemo(() => getAvatarData(data.clientData).mainImageData, [ data.clientData ]);

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [
        DOTS_MENU_ITEM_TYPES.edit,
      ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ), []);

  const onEditWrapper = useCallback(() => {
    onEditTodo(data.id);
  }, [data.id, onEditTodo]);

  const onMenuSelect = useCallback((action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.edit) {
      onEditWrapper();
    }
  }, [onEditWrapper]);

  const typeSelectedRow = useMemo(() => {
    if(isFormDisabled) {
      return TypeSelectedRow.Stripes;
    }

    if(isFutureDueDate) {
      return TypeSelectedRow.Solid;
    }
    return;
  }, [ isFormDisabled, isFutureDueDate ]);

  const backgroundColorRow = isFutureDueDate ? COLORS.lightBlue : '';

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const dueDateColor = getDueDateColor(data.dueDate, isFormDisabled);

  const duDate = useMemo(() => {
    const date = data.isAssigned ? data.reviewDate : data.dueDate;
    return getDueDate(date);
  }, [getDueDate, data]);

  return (
    <Table.Row
      $backgroundColor={ backgroundColorRow }
      $preventPointerEvents={ isBlocked }
      $typeSelectedRow={ typeSelectedRow }
      hover
      onDoubleClick={ onEditWrapper }
      selected={ isFormDisabled || isFutureDueDate }
      style={ {
        height: 46
      } }
      tabIndex={ -1 }
    >
      { isShowCompletedTasks && 
        <Table.Cell>
          {
            data.stage === STAGES.complete &&
            <CompletedIconWrapper>
              <DoneMarkWithCircle/>
            </CompletedIconWrapper>
          }
        </Table.Cell>
      }
      <Table.Cell $alignItems={ 'center' }>
        <TaskIconWrapper>
          <OtherTasks/>
        </TaskIconWrapper>
        
        <TextCellWrapper>
          <TextWithTooltip
            $fontColor={ COLORS.orange }
            $fontSize={ 14 }
            text={ data.name }
          />
          <TextWithTooltip
            $fontSize={ 10 }
            text={ data.category }
          />
        </TextCellWrapper>
      </Table.Cell>
      
      <Table.Cell>
        <FormCellWrapper>
          { data.priority ?
            <ListItemWithColorIndicator
              indicatorColor={ getPriorityColor(data.priority) }
              label={ data.priority }
            /> : '-'
          }
        </FormCellWrapper>
      </Table.Cell>

      <Table.Cell>
        <FormCellWrapper>
          <div>{ data.stage }</div>
        </FormCellWrapper>
      </Table.Cell>

      <Table.Cell>
        <FormCellWrapper>
          <DueDate $color={ dueDateColor }>{ duDate }</DueDate>
        </FormCellWrapper>
      </Table.Cell>

      <Table.Cell $paddingLeft={ '20px' }>
        <Avatar
          mainImageData={ mainImageData }
        />
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '10px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
      {
        isBlocked &&
        <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});
