import { CONTACT_TYPES, TAGS_TYPES } from '@constants/common';

export const DEFAULT_STEP_DATA = {
  [CONTACT_TYPES.INDIVIDUAL]: [
    ['Basic', 'Information'],
    ['Contact', 'Information'],
    ['Spouse', 'Information'],
    ['Tags']
  ],
  [CONTACT_TYPES.HOUSEHOLD]: [
    ['Household', 'Information'],
    ['Contact', 'Information'],
    ['Add Members'],
    ['Tags']
  ],
  [CONTACT_TYPES.BUSINESS]: [
    ['Business', 'Information'],
    ['Contact', 'Information'],
    ['Point-Of-Contact'],
    ['Tags']
  ]
};

export const ADD_CONTACT_TAGS_TYPES = {
  general: TAGS_TYPES.general,
  interest: TAGS_TYPES.interest,
  new: 'New'
} as const;

export const initialType = CONTACT_TYPES.INDIVIDUAL;

export const initialStep = 1;

export const CONTACT_OPTIONS_URL = {
  individual: 'getIndividualOptions',
  household: 'getContactHouseholdOptions',
  business: 'getContactBusinessOptions'
};
