import { advisersConverter, sourceCategoryConverter, sourceConverter } from '@/shared/utils/getOptionsUtils';
import { getCountriesOptions } from './getCountriesOptions';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import {
  BusinessOptionsDataBackendType,
  BusinessOptionsType,
  HouseholdOptionsDataBackendType,
  HouseholdOptionsType,
  IndividualOptionsDataBackendType,
  IndividualOptionsType,
} from '@/shared/types/contactOptionsTypes';


export const businessOptionsNormalizer = (data: BusinessOptionsDataBackendType): BusinessOptionsType => ({
  allClientsInGroup: advisersConverter(data.allClientsInGroup),
  companyType: optionsFromValue(data.companyType),
  coreClassification: optionsFromValue(data.coreClassification),
  countries: getCountriesOptions(data.countries),
  employees: optionsFromValue(data.employees),
  primaryAdvisers: advisersConverter(data.adviser),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
});

export const houseHoldOptionsNormalizer= (data: HouseholdOptionsDataBackendType): HouseholdOptionsType => ({
  allClientsInGroup: advisersConverter(data.allClientsInGroup),
  coreClassification: optionsFromValue(data.coreClassification),
  countries: getCountriesOptions(data.countries),
  ethnicity: optionsFromValue(data.ethnicity),
  gender: optionsFromValue(data.gender),
  maritalStatus: optionsFromValue(data.maritalStatus),
  prefix: optionsFromValue(data.prefix),
  primaryAdvisers: advisersConverter(data.adviser),
  retirementStatus: optionsFromValue(data.retirementStatus),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
  suffix: optionsFromValue(data.suffix)
});

export const individualOptionsNormalizer = (data: IndividualOptionsDataBackendType): IndividualOptionsType => ({
  allClientsInGroup: advisersConverter(data.allClientsInGroup),
  coreClassification: optionsFromValue(data.coreClassification),
  countries: getCountriesOptions(data.countries),
  ethnicity: optionsFromValue(data.ethnicity),
  gender: optionsFromValue(data.gender),
  maritalStatus: optionsFromValue(data.maritalStatus),
  prefix: optionsFromValue(data.prefix),
  primaryAdvisers: advisersConverter(data.adviser),
  retirementStatus: optionsFromValue(data.retirementStatus),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
  suffix: optionsFromValue(data.suffix)
});
