import React, { ElementType } from 'react';
import { observer } from 'mobx-react';

import FormBlock from '@components/FormBlock';
import TextWithTooltip from '@components/TextWithTooltip';

import { CONTACTS_DETAILS_DATA_MAP, isLocation, isMail, isPhone } from './utils';
import { getInternationalFormatNumber } from '@modules/HookFormComponents/HFPhoneField/validation';

import { COLORS } from '@constants/colors';
import { CONTACTS_EMAIL_IDS, CONTACTS_PHONE_IDS } from '@constants/contactsData';
import { Contact } from '@/shared/types/contactsData';

import { LabelWithIconWrapper } from '@modules/ContactComponents/ContactDetails/styles';

import { ContactsProps } from './types';
import { EmailLink } from './styles';


export const Contacts = observer(({
  contactsDetails,
  states
}: ContactsProps) => (
  <>
    {
      contactsDetails.map((field: Contact) => {
        let value = '';
        if(isLocation(field)) {
          const { street, street2, city, state, zip } = field;
          const stateName = state ? states.find(item => item.value === state)?.label : false;

          value = [street, street2, city, stateName, zip].filter((Boolean)).join(', ');
        } else if(isPhone(field)) {
          value = getInternationalFormatNumber(field.value || '') || '-';
        } else {
          value = field.value;
        }
        const isEmail = isMail(field);
        const Icon = CONTACTS_DETAILS_DATA_MAP[field.fieldId].icon as ElementType || null;

        const textColor = CONTACTS_PHONE_IDS.includes(field.fieldId) || CONTACTS_EMAIL_IDS.includes(field.fieldId)
          ? `${COLORS.orange}` : '';

        return (
          <FormBlock.RowWrapper key={ `${field.fieldId} ${field.fieldName}` }>
            <FormBlock.FormField
              $labelHeight={ 20 }
              $labelWidth={ 140 }
              renderLabelContent={ () => (
                <LabelWithIconWrapper>
                  <Icon/>
                  { CONTACTS_DETAILS_DATA_MAP[field.fieldId].label }
                </LabelWithIconWrapper>
              ) }
            >
              <FormBlock.VerticalCenteredWrapper>
                {
                  isEmail ? 
                    <EmailLink href={ `mailto:${value}` }>
                      <TextWithTooltip
                        $fontColor={ textColor }
                        text={ value }
                      />
                    </EmailLink> :
                    <TextWithTooltip
                      $fontColor={ textColor }
                      text={ value }
                    />
                }
              </FormBlock.VerticalCenteredWrapper>
            </FormBlock.FormField>
          </FormBlock.RowWrapper>
        );  
      })
    }
  </>
));
