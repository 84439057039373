import React, { memo, useCallback, useMemo } from 'react';

import { OutOfSightTD } from '@components/OutOfSight';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import LinkedContactCell from '@modules/LinkedContactCell';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { getInternationalFormatNumber } from '@modules/HookFormComponents/HFPhoneField/validation';

import { CONTACTS_EMAIL_IDS, CONTACTS_PHONE_IDS } from '@constants/contactsData';

import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import { Emails, Phones } from '@/shared/types/contactsData';
import { EmployeeRowProps } from './types';

export const EmployeeRow = memo(({
  data,
  onDelete,
  onEdit,
  onOutOfSightClick,
  onView,
}: EmployeeRowProps) => {

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.view, DOTS_MENU_ITEM_TYPES.remove ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ),[]);

  const onMenuSelectCb = useCallback((action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.remove){
      onDelete(data);
    }
    if(action === DOTS_MENU_ITEM_TYPES.edit){
      onEdit(data);
    }
    if(action === DOTS_MENU_ITEM_TYPES.view){
      onView(data);
    }
  },[data, onDelete, onEdit, onView]);

  const onEditWrapper = useCallback(() => {
    onEdit(data);
  }, [onEdit, data]);

  const isBlocked = typeof data.contact.editAllow === 'number' && !data.contact.editAllow;

  const primaryPhone = useMemo(() => {
    const phone = data.contact.contacts?.find(phone => (
      CONTACTS_PHONE_IDS.includes(phone.fieldId) && phone.primary === 'Yes'
    )) as Phones;
    return getInternationalFormatNumber(phone?.value || '') || '-';
  }, [data]);

  const primaryEmail = useMemo(() => {
    const email = data.contact.contacts?.find(email => (
      CONTACTS_EMAIL_IDS.includes(email.fieldId) && email.primary === 'Yes'
    )) as Emails;
    return email?.value || '-';
  }, [data]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ onEditWrapper }
      role='checkbox'
      tabIndex={ -1 }
    >
      <LinkedContactCell
        contact={ data.contact }
      />
      <Table.Cell>
        <TextWithTooltip text={ data?.title || '' }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ primaryPhone }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ primaryEmail }/>
      </Table.Cell>
      <Table.Cell>
        {data.relationshipType}
      </Table.Cell>
      <Table.Cell>
        {data.keyContact}
      </Table.Cell>
      <Table.Cell>
        {data.primaryContact}
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelectCb }
        />
      </Table.Cell>
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});

EmployeeRow.displayName = 'EmployeeRow';
