import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { helpText } from './data';

import { getContactByNameForRelationWithoutContactId } from '@services/api/relatedContacts/relatedContacts';
import { getSearchParamsWithName, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';

import { TextBox } from './styles';

import { ContactRelatedItem } from '@/shared/types/contact';

const SEARCH_WITHOUT_SPOUSE_PARAM = { 'skipRelatedTypes[0]': 'Spouse' };

export const LinkSpouseContact = observer(() => {

  const store = useAddContactPopupStore();
  const { relatedContacts, spouseContact } = store;

  const { control } = useFormContext();

  const responseConverter = useCallback((response: AxiosResponse) => {
    const usersData = usersResponseConverter(response);
    const keys = relatedContacts.map((user: ContactRelatedItem) => user.id);
    return usersData.filter((option: ContactRelatedItem) => !keys.includes(option.id));
  }, [ relatedContacts ]);

  const setSpouseContact = useCallback((spouseContact: ContactRelatedItem | null) =>{
    store.setSpouseContact(spouseContact);
  }, [ store ]);

  return (
    <FormBlock isBorderBottom={ false } marginBottom={ 20 }>
      <FormBlock.Header title={ 'Link Existing Contact as a Spouse' }/>
      <FormBlock.RowWrapper flexDirection={ 'column' }>
        <>
          <HFLinkedContact
            FetchProps={ {
              getParams: getSearchParamsWithName(SEARCH_WITHOUT_SPOUSE_PARAM),
              requestSender: getContactByNameForRelationWithoutContactId,
              responseConverter,
            } }
            TextFieldProps={ {
              placeholder: 'Start Typing Name...'
            } }
            control={ control }
            initLinkedContact={ spouseContact }
            name={ 'contactSpouse' }
            onLinkedContactChange={ setSpouseContact }
          />
        </>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <TextBox>{helpText}</TextBox>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
