import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import {
  Filters,
  PreTablePanelWrapper,
  TodoTable,
} from '@pages/NewToDos/components';
import Surface from '@components/BasicLayoutComponents/Surface';


export const ClientSpecificTasks = observer(() => {
  const { isFiltersOpen, init, resetStore } = useStore().ToDosStore;

  useEffect(() => {
    init();
    return resetStore;
  }, [init, resetStore]);

  return (
    <Surface.Content $isWrap={ false } $isRightSidePanel>
      <Surface.Main>
        <PreTablePanelWrapper/>
        <TodoTable/>
      </Surface.Main>
      <Surface.Side isFiltersWithButtonOpen={ isFiltersOpen }>
        <Filters />
      </Surface.Side>
    </Surface.Content>
  );
});
