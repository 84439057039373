import { getTimezoneOffset } from 'date-fns-tz';

import { AccountTimeZone } from '@/shared/types/settings';

export const convertedDatesToCurrentTimezone = (event: any, accountTimeZone: AccountTimeZone) => {
  const { createdDate, startDate, startTime, endDate, endTime, allDay, createdTimeZone } = event;

  const startDateString = getDateFromString(startDate, startTime);
  const endDateString = getDateFromString(endDate, endTime);

  const utcOffset = getTimezoneOffset(createdTimeZone.label, createdDate);

  if(allDay) {
    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  return {
    startDate: getConvertedDate(startDateString, createdTimeZone, utcOffset, accountTimeZone),
    endDate: getConvertedDate(endDateString, createdTimeZone, utcOffset, accountTimeZone),
  };
};

const getDateFromString = (date: string, time: string) => {
  const dateString = date + 'T' + time + '.000';
  return new Date(dateString);
};

const getConvertedDate = (
  date: Date, createdTimeZone: AccountTimeZone, utcOffset: number, accountTimeZone: AccountTimeZone
) => {
  const localTimezoneOffset = getTimezoneOffset(accountTimeZone.label as string, date) * -1;
  const createdTimezoneOffset = getTimezoneOffset(createdTimeZone.label, date) * -1;
  const differenceLocalAndCreatedTimezoneOffset = localTimezoneOffset - createdTimezoneOffset;

  const eventLocalTime = new Date(new Date(date).getTime() + utcOffset);

  const eventDate = eventLocalTime.getTime() - differenceLocalAndCreatedTimezoneOffset;

  return new Date(eventDate);
};
