import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useStore } from '@store';

import { MODAL_ROUTE_SCHEME } from './data';

import { CustomStateProps, ModalRouteEntityType } from './types';
import { ModalPropsMap, ModalType } from '@services/store/modalStore/types';


export const ModalRoute = <L extends ModalType,>(props: ModalPropsMap[L]) => {
  const location = useLocation();
  const modalProps = location.state as CustomStateProps;
  const { id, entityName } = useParams<{ id: string, entityName: ModalRouteEntityType }>();

  const modalStore = useStore().ModalStore;

  useEffect(() => {
    modalStore.openModal({
      modalType: MODAL_ROUTE_SCHEME[entityName].modalType,
      modalProps: {
        id: Number(id),
        ...modalProps,
        ...props,
        isOpenedFromModalRoute: true
      },
      component: MODAL_ROUTE_SCHEME[entityName].component
    });
  }, []);

  return <></>;
};
