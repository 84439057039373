import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { ALL_ASSIGNED_TO_SELECT_OPTION } from '@constants/common';

import { UserSelectFilter } from '@modules/UserSelectFilter';

import { TrashFiltersNames, TrashTabValues } from '@pages/Trash/types';

import { Wrapper } from './styles';


export const UsersFilter = observer(() => {
  const trashStore = useStore().TrashStore;
  const { filters, filtersData, isPageLoads, currentTrashView } = trashStore;

  const userSelectFilterConfig = useMemo(() => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      return {
        usersOptions: filtersData?.[TrashFiltersNames.Clients],
        value: filters?.[TrashFiltersNames.Clients]
      };
    } else if(currentTrashView === TrashTabValues.Lists) {
      return {
        usersOptions: filtersData?.[TrashFiltersNames.Users],
        value: filters?.[TrashFiltersNames.Office_Primary_Advisor]
      };
    }
    return {
      usersOptions: filtersData?.[TrashFiltersNames.Users],
      value: filters?.[TrashFiltersNames.Users]
    };
  }, [currentTrashView, filtersData]);

  const allUserOption = useMemo(() => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      return ALL_ASSIGNED_TO_SELECT_OPTION;
    }
  }, [currentTrashView]);

  const onChange = useCallback((newValue: Array<string>) => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      trashStore.setFilters({
        [TrashFiltersNames.Clients]: newValue,
      });  
    } else if(currentTrashView === TrashTabValues.Lists) {
      trashStore.setFilters({
        [TrashFiltersNames.Office_Primary_Advisor]: newValue,
      });
    } else {
      trashStore.setFilters({
        [TrashFiltersNames.Users]: newValue,
      });
    }
  }, [trashStore, currentTrashView]);

  return (
    <Wrapper>
      <UserSelectFilter
        allUserOption={ allUserOption }
        closeTrigger={ isPageLoads }
        onChange={ onChange }
        usersOptions={ userSelectFilterConfig.usersOptions || [] }
        //@ts-ignore
        value={ userSelectFilterConfig.value }
      />
    </Wrapper>
  );
});
