import styled from 'styled-components';

import MuiBox from '@material-ui/core/Box';

import { ChevronLeft, ChevronRight } from '@/assets';


export const Container = styled(MuiBox)`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const Group = styled(MuiBox)`
    display: flex;
    flex-grow: 1;
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
    margin-bottom: 2px;
`;

export const ChevronRightIcon = styled(ChevronRight)`
    margin-bottom: 2px;
`;

export const Wrapper = styled.div`
    margin-right: auto;
`;
