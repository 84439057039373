import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@services/store/store';
import { useTodoPopupLocalStore } from './store';

import { ENTITY_NAMES } from '@constants/common';
import { validationSchema } from './validationScheme';

import {
  Actions,
  BtnPanel,
  Content,
} from './components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import Modal from '@components/Modal';
import NoViewPermission from '@modules/NoViewPermission';
import Preloader from '@components/Preloader';

import { Spacer, TopPanelWrapper } from './styles';

import { TodoFormFields } from '@/shared/types/todos';
import { TaskPopupProps } from './types';

export const TodoPopup = observer(({
  closeModal,
  modalProps: {
    id,
    initLinkedContact,
    isCloneTask,
    isLinkedItem,
    isOpenedFromModalRoute,
    pathname,
    previousCopyData,
    deleteCallback,
    onCloseAction = () => {},
    saveCallback,
    updateCallback
  }
}: TaskPopupProps) => {

  const store = useStore();
  const routerStore = useStore().RouterStore;
  const todoPopupLocalStore = useTodoPopupLocalStore();
  const {
    id: todoId,
    isFetching,
    notesUtilsStore,
    isCloneTask: isClone,
    permission,
    init,
    onCancel
  } = todoPopupLocalStore;

  const title = todoId ? `Task #${todoId} - Edit Task` : 'Add Task';

  const methods = useForm<TodoFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onCloseModal = useCallback(() => {
    if(isOpenedFromModalRoute) {
      routerStore.browserHistory.goBack();
    }
    closeModal();
  }, [isOpenedFromModalRoute]);

  useEffect(() => {
    init({
      coreStore: store,
      id,
      initLinkedContact,
      isCloneTask: isCloneTask || isClone,
      isLinkedItem,
      isOpenedFromModalRoute,
      pathname,
      previousCopyData,
      closeModal: onCloseModal,
      closeModalComponent: closeModal,
      deleteCallback,
      onCloseAction,
      saveCallback,
      updateCallback,
      useFormMethods: methods,
    });
    // eslint-disable-next-line
  }, []);


  return (
    <FormProvider { ...methods }>
      <Modal
        headerProps={ {
          padding: '22px 25px 22px 20px'
        } }
        onClose={ onCancel }
        title={ isFetching ? '' : title }
      >
        <TopPanelWrapper>
          <Modal.TopPanel
            padding={ '10px 25px 10px 20px' }
          >
            <BtnPanel />
          </Modal.TopPanel>
        </TopPanelWrapper>
        <Modal.Content $disabled={ Boolean(notesUtilsStore.alertType) }>
          { !permission ?
            <Spacer>
              <NoViewPermission entityName={ ENTITY_NAMES.task }/>
            </Spacer> :
            (
              isFetching ?
                <Spacer>
                  <Preloader isWithoutFullPageWrapper={ true } />
                </Spacer> :
                <Content/>
            )
          }
        </Modal.Content>
        <Modal.Footer
          padding={ '20px 25px 20px 20px' }
        >
          { !permission ?
            <BlockButton
              colorSchema={ COLOR_SCHEME_TYPES.contained }
              onClick={ onCancel }
              style={ {
                marginLeft: 'auto'
              } }
            >
              Okay
            </BlockButton> :
            <Actions/>
          }
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});
