import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { getListContactsSetupFilterParams } from '@/shared/utils/getListContactsSetupFilterParams';
import { getListContactsByType } from '@services/api/lists/lists';

import ViewAll from '@components/ViewAll';
import UserItem from '@components/NewUserItem';

import { getFilterParams } from '@/shared/utils/filterUtils';
import { LISTS_TABLE_FILTER_NAMES } from '@constants/lists';

import { BackendGridResponse } from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';
import { ViewAllContactsProps } from './types';


export const ViewAllContacts = ({
  contactGroupTypes,
  filters,
  listId,
}: ViewAllContactsProps) => {
  const [ page, setPage] = useState<number>(1);
  const [ lastPage, setLastPage] = useState<null | number>(null);
  const [ isLoad, setIsLoad] = useState<boolean>(false);
  const [ itemsToRender, setItemsToRender ] = useState<Array<LinkedContact>>([]);

  const getContacts = useCallback(async () => {
    if(typeof lastPage === 'number' && page > lastPage){
      return;
    }

    try {
      setIsLoad(true);
      const response: AxiosResponse<BackendGridResponse<Array<LinkedContact>>> =
        await getListContactsByType({
          id: listId,
          page,
          ...(filters ? getFilterParams({
            [LISTS_TABLE_FILTER_NAMES.classification]: filters.classification,
            [LISTS_TABLE_FILTER_NAMES.officePrimaryAdvisor]: filters.officePrimaryAdvisor,
            [LISTS_TABLE_FILTER_NAMES.status]: filters.status
          }) : []),
          ...getListContactsSetupFilterParams(contactGroupTypes)
        });

      setLastPage(response.data.data.last_page);

      setItemsToRender((prev => {
        return [...prev, ...response.data.data.data];
      }));
      setPage(v => v + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoad(false);
    }
  }, [contactGroupTypes, lastPage, listId, page]);

  const onTriggerIntersection = useCallback(() => {
    getContacts();
  }, [getContacts]);

  return (
    <ViewAll
      interSectionOptions={ {
        rootMargin: '400px'
      } }
      isLoad={ isLoad }
      isRenderTrigger={ true }
      onTriggerIntersection={ onTriggerIntersection }
    >
      {
        itemsToRender.map((item) => (
          <UserItem
            disableTooltipAnimation={ true }
            key={ item?.id }
            labelWidth={ 150 }
            user={ item }
            withCoreClassification={ false }
          />
        ))
      }
    </ViewAll>
  );
};
