import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { useStore } from '@store';

import Modal from '@components/Modal';

import { Content, Footer } from './components';

import { Fields, SplitToIndividualsProps } from './types';

import { STAGES_DATA } from './data';
import { CONTACT_STATUSES_OPTIONS } from '@constants/common';

import { getHouseholderContactsDefaultState, getFieldsToValidate, sanitizeData } from './utils';

import { validationSchema } from './validationSchema';

export const SplitToIndividuals = ({
  closeModal,
  modalProps: {
    householdMembers,
    primaryAdviser
  },
}: SplitToIndividualsProps) => {
  const contactDetailsProfileStore = useStore().ContactDetailsProfileStore;
  const { optionsForHousehold }= contactDetailsProfileStore;
  const methods = useForm<Fields>({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: {
      createSpouse: 'Yes',
      householderContacts: getHouseholderContactsDefaultState(householdMembers),
    },
    resolver: yupResolver(validationSchema),
  });

  const [ currentStep, setCurrentStep ] = useState<number>(1);
  const isLastStep = currentStep === STAGES_DATA.length;
  const isFirstStep = currentStep === 1;

  const onChangeStageNumber = useCallback(async (value: number) => {
    const isStepValid = await methods.trigger(getFieldsToValidate(currentStep - 1));
    if(isStepValid) {
      setCurrentStep(value);
    }
  }, [currentStep, methods]);

  const handleNext = useCallback(async () => {
    const isStepValid = await methods.trigger(getFieldsToValidate(currentStep - 1));
    if(isStepValid) {
      setCurrentStep(v => v + 1);
    }
  }, [currentStep, methods]);

  const handlePrev =  useCallback(() => setCurrentStep(v => v - 1), []);

  const handleSave = useCallback(() => {
    methods.handleSubmit(async (data) => {
      closeModal();
      contactDetailsProfileStore.splitHouseHold(sanitizeData(data));
    })();
  }, [closeModal, contactDetailsProfileStore, methods]);

  return (
    <Modal
      onClose={ closeModal }
      title={ 'Split to Individuals' }
    >
      <Modal.Content
        padding={ '30px 30px 15px 30px' }
        width={ '800px' }
      >
        <FormProvider { ...methods }>
          <Content
            classificationOptions={ optionsForHousehold?.coreClassification || [] }
            currentStep={ currentStep }
            genderOptions={ optionsForHousehold?.gender || [] }
            isLastStep={ isLastStep }
            onChangeStageNumber={ onChangeStageNumber }
            primaryAdviser={ primaryAdviser }
            sourceCategoryOptions={ optionsForHousehold?.sourceCategory || [] }
            sourceOptions={ optionsForHousehold?.source  || [] }
            stagesData={ STAGES_DATA }
            statusOptions={ CONTACT_STATUSES_OPTIONS }
          />
        </FormProvider>
      </Modal.Content>
      <Modal.Footer>
        <Footer
          isFirstStep={ isFirstStep }
          isLastStep={ isLastStep }
          onClose={ closeModal }
          onNext={ handleNext }
          onPrev={ handlePrev }
          onSave={ handleSave }
        />
      </Modal.Footer>
    </Modal>
  );
};
