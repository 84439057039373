import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';

import { useActivityPopupLocalStore } from './store';
import { useStore } from '@store';

import { convertActivityData, detectOutOfWorkHours } from './utils';
import { convertDateToUTCFormat } from '@/shared/utils/convertStartEndDateToUTCFormat';
import { MODAL_TYPE } from '@constants/modalTypes';
import { validationSchema } from './validationScheme';

import { Actions, BtnPanel, Content, EditActivityConfirmPopup } from './components';
import Modal from '@components/Modal';
import Preloader from '@components/Preloader';

import { Spacer } from './styles';

import { AddEditActivityPopupProps } from './types';
import { ActivityStatusOptions, ClientConverter, ConvertActivityData } from '@/shared/types/activityPopup';

export const ActivityPopup = observer(({
  closeModal,
  modalProps: {
    editMode,
    forDay,
    id,
    initData,
    onConfirm,
    onDelete
  }
}: AddEditActivityPopupProps) => {

  const modalStore = useStore().ModalStore;
  const { accountTimeZone, timeZones } = useStore().SettingsStore;
  const {
    activityCreationSettings: { statuses },
    clearEditActivityItem,
    editActivityItem,
    getActivityById,
    getSettings,
    isFetching,
    isHideModal,
    resetStore,
    setIsHideModal,
  } = useActivityPopupLocalStore();

  const [ load, setLoad ] = useState(editMode);
  const [ isOutOfWorkHours, setIsOutOfWorkHours ] = useState(false);
  const [ activityUsers, setActivityUsers ] = useState<Array<ClientConverter>>([]);

  const title = useMemo(() => `${editMode ? 'Edit' : 'Add '} ${'Calendar Activity'}`, [ editMode ]);

  const contactData = useMemo(() => {
    if(isEmpty(editActivityItem?.renderData?.primaryContact)) {
      return initData?.primaryContact;
    }
    return editActivityItem?.renderData?.primaryContact;
  }, [ editActivityItem, initData ]);

  const defaultValues = useMemo(() => {
    return initData ? {
      ...initData,
      statusId: statuses?.find((status) => status.label === ActivityStatusOptions.Scheduled)?.value,
      primaryContactId: contactData?.id
    } : {};
  }, [ initData, contactData, statuses ]);

  const methods: UseFormReturn<ConvertActivityData> = useForm<ConvertActivityData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const { handleSubmit, reset } = methods;

  const onEditMode = useCallback((convertedData: ConvertActivityData) => {
    setIsHideModal(true);
    const formData =  {
      ...convertedData,
      id: editActivityItem.formData.id,
      recurringChangeDate: forDay,
      withRecurring: false,
    };

    if(editActivityItem.formData.recurring) {
      modalStore.openModal({
        modalType: MODAL_TYPE.EDIT_ACTIVITY_CONFIRMATION,
        modalProps: {
          formData,
          onConfirm: (data: any) => {
            onConfirm(data);
            closeModal();
          },
          onCloseAction: () => setIsHideModal(false)
        },
        component: EditActivityConfirmPopup
      });
    } else {
      closeModal();
      onConfirm(formData);
    }
  }, [
    closeModal,
    editActivityItem?.formData?.id,
    editActivityItem?.formData?.recurring,
    forDay,
    modalStore,
    onConfirm,
    setIsHideModal
  ]);

  const onSaveClick = useCallback(() => {
    handleSubmit((data: ConvertActivityData) => {
      const convertedData = convertActivityData(data, data.allDay, accountTimeZone, timeZones);

      const convertedTime = {
        startTime: convertDateToUTCFormat(data.startDate, data.startTime).time,
        endTime: convertDateToUTCFormat(data.endDate, data.endTime).time
      };
      if(!isOutOfWorkHours && detectOutOfWorkHours(convertedTime, activityUsers)) {
        setIsOutOfWorkHours(true);
        return;
      }

      if(editMode) {
        onEditMode(convertedData);
      } else {
        closeModal();
        onConfirm(convertedData);
      }
    })();
  }, [handleSubmit, isOutOfWorkHours, activityUsers, editMode, onEditMode, closeModal, onConfirm]);

  const onCancelClick = useCallback(() => {
    if(isOutOfWorkHours) {
      setIsOutOfWorkHours(false);
    }
    closeModal();
  }, [ isOutOfWorkHours ]);

  useEffect(() => {
    getSettings();

    if(editMode && id) {
      getActivityById(id, accountTimeZone, timeZones);
    }
  }, [getSettings, editMode, id, getActivityById, accountTimeZone]);

  useEffect(() => {
    if(editMode && !isEmpty(editActivityItem)) {
      reset(editActivityItem.formData);
      setLoad(false);
      return () => clearEditActivityItem();
    }
  }, [clearEditActivityItem, editActivityItem.formData, editMode, reset, setLoad]);

  useEffect(() => {
    reset(defaultValues);
  }, [ statuses ]);

  useEffect(() => {
    return resetStore;
  }, [resetStore]);

  return (
    <>
      {
        isHideModal && (isFetching || load) && <Preloader/>
      }
      <Modal
        $isHide={ isHideModal }
        onClose={ closeModal }
        title={ title }
      >
        <FormProvider { ...methods }>
          <Modal.TopPanel>
            <BtnPanel
              $preventClicks={ load }
            />
          </Modal.TopPanel>
          <Modal.Content>
            {
              isFetching || load ? (
                <Spacer>
                  <Preloader isWithoutFullPageWrapper={ true }/>
                </Spacer>
              ) : (
                <Content
                  editMode={ editMode }
                  isOverlay={ isOutOfWorkHours }
                  onClose={ closeModal }
                  primaryContact={ contactData as any }
                  setActivityUsers={ setActivityUsers }
                />
              )
            }
          </Modal.Content>
          <Modal.Footer>
            <Actions
              $preventClicks={ load }
              close={ closeModal }
              editMode={ editMode }
              isOutOfWorkHours={ isOutOfWorkHours }
              onCancelClick={ onCancelClick }
              onDelete={ onDelete }
              onSaveClick={ onSaveClick }
              removeData={ {
                isRecurring: Boolean(editActivityItem.formData?.recurring),
                activityData: {
                  id: editActivityItem.formData?.id,
                  recurringChangeDate: forDay,
                }
              } }
            />
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </>
  );
});
