import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { useActivityPopupLocalStore } from '@modules/ActivityPopup/store';

import FormBlock from '@components/FormBlock';
import {
  ActivityNameInput,
  DateAndTime,
  Description,
  LinkedContact,
  StatusAndSubCategory,
  Users
} from './components';

import { DisableOverlay, Wrapper } from './styles';

import { ContentProps } from './types';

export const Content = observer(({
  editMode,
  isOverlay,
  onClose,
  primaryContact,
  setActivityUsers,
}: ContentProps) => {

  const { editActivityItem } = useActivityPopupLocalStore();

  const usersData = useMemo(() => {
    return editActivityItem?.renderData?.clients;
  }, [ editActivityItem ]);

  const [ linkedContact, setLinkedContact ] = useState(primaryContact);

  return (
    <Wrapper>
      {
        isOverlay && <DisableOverlay />
      }
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Activity Details' }/>
        <ActivityNameInput/>
        <StatusAndSubCategory/>
        <LinkedContact
          initLinkedContact={ linkedContact }
          onClose={ onClose }
          onLinkedContactChange={ setLinkedContact }
        />
      </FormBlock>
      <DateAndTime editMode={ editMode }/>
      <Users
        initUsersData={ usersData }
        setActivityUsers={ setActivityUsers }
      />
      <Description/>
    </Wrapper>
  );
});
