import { TodoPopup } from '@modules/TodoPopup';

import { ENTITY_NAMES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { ModalRouteScheme } from './types';

export const MODAL_ROUTE_SCHEME: ModalRouteScheme = {
  [ENTITY_NAMES.task]: {
    component: TodoPopup,
    entityName: ENTITY_NAMES.task,
    modalType: MODAL_TYPE.TODO_TASK_MODAL,
  },
};