import React, { useCallback, memo, useRef, useEffect, useState } from 'react';

import ContentToggle from '@components/ContentToggle';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';
import { CustomCheckboxWithoutLabel } from '@components/CustomCheckbox';

import {
  CheckboxWrapper,
  Item,
  ItemsWrapper,
  Label,
} from './styles';

import { CheckboxDropDownProps } from './types';

export const CheckboxDropDown = memo(({
  $isPlaceScrollOut,
  $maxHeightDropDownHeight,
  $maxWidth = 210,
  disabled = false,
  isOpenInitValue = true,
  label,
  name,
  renderItemLabel,
  setState,
  state,
  values,
}:CheckboxDropDownProps) => {
  const isMainIndeterminate = state.length !== 0 && state.length !== values.length;
  const isMainChecked = state.length > 0 && state.length === values.length;

  const mainOnChange = useCallback(() => {
    if (state.length >= 0) {
      setState(
        name,
        values.map((value) => value.value)
      );
    }

    if (isMainChecked) {
      setState(name, []);
    }

  }, [state.length, isMainChecked, setState, name, values]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { target: { value } } = event;
    const valueIndex = state.indexOf(value);
    let stateCopy = [...state];

    if (valueIndex >= 0) {
      stateCopy.splice(valueIndex, 1);
    } else {
      stateCopy.push(value);
    }

    setState(name, stateCopy);
  },
  [state, name, setState]
  );


  return (
    <ContentToggle
      CheckboxProps={ {
        isChecked: isMainChecked,
        isIndeterminate: isMainIndeterminate,
        onChange: mainOnChange,
      } }
      count={ state?.length }
      disabled={ disabled }
      isOpenInitValue={ isOpenInitValue }
      label={ label }
    >
      <ItemsWrapper
        $isPlaceScrollOut={ $isPlaceScrollOut }
        $maxHeightDropDownHeight={ $maxHeightDropDownHeight }
      >
        {values.map(({ label, value }) => (
          <Item key={ value }>
            <Label htmlFor={ String(value) }>
              {
                renderItemLabel && renderItemLabel(label, value)
              }

              {
                !renderItemLabel &&
                <Tooltip
                  displayOnlyOnOverflow={ true }
                  title={ label }
                >
                  <TextEllipsis $maxWidth={ $maxWidth }>{ label }</TextEllipsis>
                </Tooltip>
              }
            </Label>
            <CheckboxWrapper>
              <CustomCheckboxWithoutLabel
                $withPaddings={ false }
                CheckboxProps={ {
                  id: String(value),
                  name: name,
                  value: value
                } }
                checked={ state.includes(value) }
                disabled={ disabled }
                onChange={ onChange }
              />
            </CheckboxWrapper>
          </Item>
        ))}
      </ItemsWrapper>
    </ContentToggle>
  );
}
);

CheckboxDropDown.displayName = 'CheckboxDropDown';
