import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { useStore } from '@store';

import { getContactDetailsLink } from '@/shared/utils/contactRoutesUtils';
import { getEntityRoutePath } from '@/shared/utils/getEntityRoutePath';

import { ENTITY_NAMES } from '@constants/common';
import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import {
  BackButtonContent,
  ContactDetailsAccountsAndPlans,
  ContactDetailsActivities,
  ContactDetailsOverview,
  ContactDetailsPageHeader,
  ContactDetailsProfile,
  ContactDetailsToDos,
} from './components';
import { AppPage } from '@modules/AppPage';
import ModalRoute from '@modules/ModalRoute';
import NoViewPermission from '@modules/NoViewPermission';
import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';

import { ContactDetailsParamsType } from './types';

export const ContactsDetails = observer(() => {
  const routerStore = useStore().RouterStore;
  const contactDetailsStore = useStore().ContactDetailsStore;
  const { isLoad, permission, currentContact } = contactDetailsStore;
  const contactDetailsTodosStore = useStore().ContactDetailsTodosStore;
  const { onSave, onDelete, getTodos } = contactDetailsTodosStore;

  // TODO params validation
  const match = useRouteMatch<ContactDetailsParamsType>();
  const { params: { id, tab, contactType }, url } = match;

  const taskEntityPath = getEntityRoutePath(url, ':entityName', ':id');

  const onBackClick = useCallback(() => {
    routerStore.browserHistory.goBack();
  }, [routerStore]);

  useEffect(() => {
    return () => contactDetailsStore.resetState();
  }, [contactDetailsStore]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppBackButton onClick={ onBackClick }>
          <BackButtonContent />
        </AppPage.AppBackButton>
      </AppPage.AppHeader>
      <Surface>
        {
          isLoad && <Preloader />
        }
        { permission &&
          <>
            <ContactDetailsPageHeader
              contactId={ id }
              contactType={ contactType }
              currentTab={ tab }
            />
            <Switch>
              <Route path={ getContactDetailsLink(id, contactType).accounts() }>
                <ContactDetailsAccountsAndPlans
                  contactId={ id }
                />
              </Route>
              <Route path={ getContactDetailsLink(id, contactType).activities() }>
                <ContactDetailsActivities
                  contactId={ id }
                />
              </Route>
              <Route path={ getContactDetailsLink(id, contactType).overview() }>
                <ContactDetailsOverview
                  contactId={ id }
                />
              </Route>
              <Route path={ `${getContactDetailsLink(id, contactType).profile()}/:subTab` }>
                <ContactDetailsProfile
                  contactId={ id }
                  contactType={ contactType }
                />
              </Route>
              <Route path={ getContactDetailsLink(id, contactType).toDos() }>
                <ContactDetailsToDos
                  contactId={ id }
                />
                <Route path={ taskEntityPath }>
                  <ModalRoute
                    deleteCallback={ onDelete }
                    saveCallback={ onSave }
                    updateCallback={ getTodos }
                    pathname={ url }
                    initLinkedContact={ {
                      contactData: currentContact,
                      linkedContactType: LINKED_ITEM_TYPES.contact,
                      id: currentContact?.id
                    } }
                  />
                </Route>
              </Route>
            </Switch>
          </>
        }
        { !permission && 
          <Surface.Content>
            <NoViewPermission
              entityName={ ENTITY_NAMES.contact }
              onGoBackClick={ onBackClick }
            />
          </Surface.Content>
        }
      </Surface>
    </AppPage>
  );
});
