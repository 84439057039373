import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import { AppPage } from '@modules/AppPage';
import {
  ClientSpecificTasks,
  OtherTasks,
  PageHeader,
} from './components';
import ModalRoute from '@modules/ModalRoute';
import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';

import { CLIENT_SPECIFIC_TASKS_PATH, OTHER_TASKS_PATH } from '@constants/routes/toDos';
import { getEntityRoutePath } from '@/shared/utils/getEntityRoutePath';

export const NewToDos = observer(() => {
  const {
    isTodoLoads,
    getTasksWithLoad,
    onRemove,
    onSave,
    resetStore,
    setCurrentTab,
  } = useStore().ToDosStore;

  const { params: { tab }, url } = useRouteMatch<{ 'tab': string }>();

  const taskEntityPath = getEntityRoutePath(url, ':entityName', ':id');

  useEffect(() => {
    setCurrentTab(tab);
  }, [setCurrentTab, tab]);

  useEffect(() => {
    return resetStore;
  }, [resetStore]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppPageTitle title={ 'To-Dos' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isTodoLoads && <Preloader />
        }
        <PageHeader/>
        <Switch>
          <Route path={ CLIENT_SPECIFIC_TASKS_PATH }>
            <ClientSpecificTasks/>
            <Route path={ taskEntityPath }>
              <ModalRoute
                deleteCallback={ onRemove }
                pathname={ url }
                saveCallback={ onSave }
                updateCallback={ getTasksWithLoad }
              />
            </Route>
          </Route>
          <Route path={ OTHER_TASKS_PATH }>
            <OtherTasks/>
            <Route path={ taskEntityPath }>
              <ModalRoute
                deleteCallback={ onRemove }
                pathname={ url }
                isLinkedItem={ false }
                saveCallback={ onSave }
                updateCallback={ getTasksWithLoad }
              />
            </Route>
          </Route>
        </Switch>
      </Surface>
    </AppPage>
  );
});
