import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CONTACT_TYPES } from '@/shared/constants/common';
import { initialType } from './addContactStore/data/constantData';
import { MODAL_TYPE } from '@constants/modalTypes';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';
import { useStore } from '@store';

import { Stepper } from '@components/Stepper';
import { FormConstructor, TabBar, ModalFooter } from '@/modules/AddContactModal/components';
import Modal from '@components/Modal';
import Preloader from '@components/Preloader';

import { Container, Content, DisableOverlay, LoaderWrapper, StepperWrapper } from './styles';

import { AddContactModalProps } from './types';
import { AddNewContactDataFormValues, UseFormMethods } from '@/shared/types/addNewContactPopup';


export const AddContactModal = observer(({
  closeModal,
  modalProps
}: AddContactModalProps) => {

  const { saveCallback, currentUserId } = modalProps;

  const {
    formValues,
    getContactTags,
    init,
    isFetching,
    resetFormValues,
    resetStore,
    saveContact,
    selectedStep,
    selectedType,
    setSelectedStep,
    setSelectedType,
    stepData,
    validateForm,
    validationSchema,
  } = useAddContactPopupStore();

  const modalStore = useStore().ModalStore;

  const methods: UseFormMethods = useForm<AddNewContactDataFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitted, isDirty },
    getValues,
    handleSubmit,
    reset,
    trigger
  } = methods;

  const [ actionTypeToSwitch, setActionTypeToSwitch ] = useState<boolean | null>(null);

  const [ currentType, setCurrentType ] = useState<CONTACT_TYPES>(initialType);

  const stepChangeHandler = useCallback((step: number) => {
    setSelectedStep(step, getValues());
  }, [setSelectedStep, getValues]);

  const onSaveClick = useCallback(() => {
    const errorStep = validateForm(getValues());
    if(errorStep) {
      stepChangeHandler(errorStep);
    } 

    handleSubmit(saveContact)();
  }, [handleSubmit, selectedStep, validateForm, getValues, stepChangeHandler, saveContact]);

  const closeModalHandler = useCallback(() => {
    modalStore.closeModal(MODAL_TYPE.ADD_CONTACT_MODAL);
  }, [modalStore]);

  const onChangeTab = useCallback((_e: any, newType: CONTACT_TYPES) => {
    if(isDirty) {
      setActionTypeToSwitch(true);
      setCurrentType(newType);
    } else {
      setSelectedType(newType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isDirty]);

  const onAttentionCancelClick = useCallback(() => {
    setActionTypeToSwitch(null);
  }, []);

  const onAttentionSaveClick = useCallback(() => {
    reset(formValues);
    setActionTypeToSwitch(false);
    setSelectedType(currentType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypeToSwitch]);

  useEffect(() => {
    init({
      saveCallback,
      closeModal,
      currentUserId,
      useFormMethods: methods
    });
  }, []);

  useEffect(() => {
    if(isSubmitted) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    getContactTags();
    return () => {
      resetFormValues();
      resetStore();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      onClose={ closeModal }
      title={ 'New Contact' }
    >
      <FormProvider { ...methods }>
        <Modal.TopPanel>
          <TabBar
            isButtonsDisabled={ isFetching }
            onChange={ onChangeTab }
            selectedType={ selectedType }
          />
          {
            Boolean(actionTypeToSwitch) && <DisableOverlay />
          }
        </Modal.TopPanel>
        <Modal.Content>
          <Container>
            {
              isFetching &&
              <LoaderWrapper>
                <Preloader isWithoutFullPageWrapper={ true } />
              </LoaderWrapper>
            }
            {
              Boolean(actionTypeToSwitch) && <DisableOverlay />
            }
            <StepperWrapper>
              <Stepper
                activeStep={ selectedStep }
                onChangeStep={ stepChangeHandler }
                steps={ stepData }
              />
            </StepperWrapper>
            <Content>
              <FormConstructor/>
            </Content>
          </Container>
        </Modal.Content>
        <Modal.Footer>
          <ModalFooter
            cancelHandler={ closeModalHandler  }
            currentStep={ selectedStep }
            isAttentionMode={ Boolean(actionTypeToSwitch) }
            isButtonsDisabled={ isFetching }
            onAttentionCancelClick={ onAttentionCancelClick }
            onAttentionSaveClick={ onAttentionSaveClick }
            saveHandler={ onSaveClick }
            stepChangeHandler={ stepChangeHandler }
          />
        </Modal.Footer>
      </FormProvider>
    </Modal>
  );
});
