import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { useStore } from '@store';

import { ROUTES } from '@constants/routes';

import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';
import { AppPage } from '@modules/AppPage';
import { DynamicList, ManualList } from './components';

import { getPrevParentLink } from '@/shared/utils/listsRoutesUtils';

import { LISTS_DYNAMIC_TABS, LISTS_MANUAL_TABS } from '@constants/routes/contacts';
import { LISTS_TYPES } from '@constants/lists';
import { ListsItemsRouteParams } from './types';

export const ListsDetails = observer(() => {
  // TODO params validation
  const match = useRouteMatch<ListsItemsRouteParams>();

  const listsStore = useStore().ListsStore;
  const routerStore = useStore().RouterStore;

  const subStore = useMemo(() => {
    const subTabValue = match?.params.subTab;
    if(subTabValue === LISTS_DYNAMIC_TABS.contacts || subTabValue === LISTS_MANUAL_TABS.contacts){
      return listsStore.ListContactsStore;
    }

    if(subTabValue === LISTS_DYNAMIC_TABS.setup || subTabValue === LISTS_MANUAL_TABS.setup){
      return listsStore.ListSetupStore;
    }

    if(subTabValue === LISTS_DYNAMIC_TABS.criteria){
      return listsStore.ListCriteriaStore;
    }

    return listsStore.ListContactsStore;
  }, [listsStore.ListContactsStore, listsStore.ListCriteriaStore, listsStore.ListSetupStore, match?.params.subTab]);


  const onBackClick = useCallback(() => {
    const typeList = listsStore.AllListsStore.typeFilter?.typeList;
    if(typeList !== LISTS_TYPES.Dynamic && typeList !== LISTS_TYPES.Manual) {
      routerStore.customPush(getPrevParentLink(subStore?.currentListDetails?.parentId));
    } else {
      routerStore.customPush(getPrevParentLink());
    }
  }, [routerStore, subStore?.currentListDetails?.parentId]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppBackButton onClick={ onBackClick } >
          <AppPage.AppPageTitle
            title={
              <Tooltip
                displayOnlyOnOverflow={ true }
                title= { listsStore.listName }
              >
                <TextEllipsis $maxWidth={ 600 }>
                  { listsStore.listName}
                </TextEllipsis>
              </Tooltip>
            }
          />
        </AppPage.AppBackButton>
      </AppPage.AppHeader>
      <Surface>
        {
          subStore?.isLoad &&
          <Preloader />
        }
        <Switch>
          <Route
            component={ DynamicList }
            exact={ true }
            path={ ROUTES.listsDetailsDynamicList }
          />
          <Route
            component={ ManualList }
            exact={ true }
            path={ ROUTES.listsDetailsManualList }
          />
        </Switch>
      </Surface>
    </AppPage>
  );
});
