import { IdType } from '@/shared/types/commonTypes';
import {
  ContactData,
  ContactsResponseType, ConvertedFiltersData,
  FiltersData
} from '@services/store/contactsStore/types';

import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { ContactItem } from '@/shared/types/contact';

const filterDataConverter = (data: FiltersData, currentUserId: IdType): ConvertedFiltersData => {
  return {
    classification: optionsFromValue(data.classification),
    classificationItems: data.classification,
    officePrimaryAdvisor: getSortedUserOption(data.createdBy, currentUserId),
    status: optionsFromValue(data?.status),
    type: optionsFromValue(data.type),
  };
};


const contactsConverter = (data: ContactData) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).reduce((acc: Array<ContactItem>, [ key, item ]) => {
    if (isNaN(parseInt(key))) {
      return acc;
    }

    acc.push(item as ContactItem);
    return acc;
  }, []);
};

export const contactsNormalizer = (response: ContactsResponseType, currentUserId: IdType) => {
  const {
    data,
    from,
    last_page,
    per_page,
    to,
    total,
    current_page,
  } = response.data.data;

  return {
    filtersData: filterDataConverter(data.filterData, currentUserId),
    contacts: contactsConverter(data),
    pagination: {
      current_page,
      from,
      last_page,
      per_page,
      to,
      total,
    }
  };
};