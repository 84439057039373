import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTACT_STATUSES_OPTIONS, CONTACT_TYPES } from '@constants/common';
import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH_V_THREE,
  RIGHT_COLUMN_WIDTH,
} from '@/modules/AddContactModal/data';

import { useStore } from '@store';
import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import {
  LeftColumnWrapper,
  RightColumnWrapper,
  RightColumnWrapperVariantThree
} from '@/modules/AddContactModal/components';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFRank from '@modules/HookFormComponents/HFRank';
import HFUserSingleSelect from '@modules/HookFormComponents/HFUserSingleSelect';

import { ProfileDetailsProps } from './types';


export const ProfileDetails = ({
  header,
}: ProfileDetailsProps) => {
  const { profile } = useStore().SettingsStore;
  const { options, selectedType } = useAddContactPopupStore();

  const { control } = useFormContext();

  const isHouseHold = selectedType === CONTACT_TYPES.HOUSEHOLD;

  const rightColumnSize = isHouseHold ? RIGHT_COLUMN_WIDTH_V_THREE : RIGHT_COLUMN_WIDTH;

  const RightWrapper = isHouseHold ? RightColumnWrapperVariantThree : RightColumnWrapper;

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ header }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Core Classification' }
          >
            <HFSelect
              control={ control }
              name={ '1.classification' }
              options={ options.coreClassification }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            label={ 'Rank' }
          >
            <FormBlock.VerticalCenteredWrapper>
              <HFRank
                control={ control }
                name='1.rating'
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Primary Advisor' }
          >
            <HFUserSingleSelect
              control={ control }
              initLinkedContact={ profile }
              name={ '1.officePrimaryAdvisor' }
              placeholder={ 'Select Primary Advisor' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            isRequired={ true }
            label={ 'Status' }
          >
            <HFSelect
              control={ control }
              name={ '1.status' }
              options={ CONTACT_STATUSES_OPTIONS }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Source' }
          >
            <HFSelect
              control={ control }
              name={ '1.sourceId' }
              options={ options.source }
              placeholder={ 'Select Source' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            label={ 'Source Category' }
          >
            <HFSelect
              control={ control }
              name={ '1.sourceCategory' }
              options={ options.sourceCategory }
              placeholder={ 'Select Source' }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>

      {
        selectedType === CONTACT_TYPES.BUSINESS &&
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Date Founded' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disableFuture: true
                } }
                control={ control }
                name={ '1.birthdate' }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
      }
    </FormBlock>
  );
};

