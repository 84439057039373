import React from 'react';
import { observer } from 'mobx-react-lite';

import { CONTACT_TYPES } from '@constants/common';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import {
  AddRelatedContacts,
  BasicInformation,
  ContactInformation,
  CreateMembers,
  LinkSpouseContact,
  PersonalInformation,
  PrimaryPoint,
  ProfileDetails,
  TagsBlock,
} from '@/modules/AddContactModal/components';


export const FormConstructor = observer(() => {
  const { selectedType, selectedStep } = useAddContactPopupStore();

  if(selectedType === CONTACT_TYPES.INDIVIDUAL) {
    switch (selectedStep) {
    case 1:
      return (
        <>
          <PersonalInformation/>
          <ProfileDetails header='Profile Details'/>
        </>
      );
    case 2:
      return (
        <ContactInformation/>
      );
    case 3:
      return (
        <>
          <LinkSpouseContact/>
          <AddRelatedContacts/>
        </>
      );
    case 4:
      return (
        <TagsBlock/>
      );
    default:
      break;
    }
  }

  if(selectedType === CONTACT_TYPES.HOUSEHOLD) {
    switch (selectedStep) {
    case 1:
      return (
        <>
          <CreateMembers/>
          <ProfileDetails header='Household Details'/>
        </>
      );
    case 2:
      return (
        <ContactInformation/>
      );
    case 3:
      return (
        <AddRelatedContacts/>
      );
    case 4:
      return (
        <TagsBlock/>
      );
    default:
      break;
    }
  }
  if(selectedType === CONTACT_TYPES.BUSINESS) {
    switch (selectedStep) {
    case 1:
      return (
        <>
          <BasicInformation/>
          <ProfileDetails header='Profile Details'/>
        </>
      );
    case 2:
      return (
        <ContactInformation/>
      );
    case 3:
      return (
        <PrimaryPoint/>
      );
    case 4:
      return (
        <TagsBlock/>
      );
    default:
      break;
    }
  }
  return (<></>);
});
