import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useAddContactPopupStore } from '@/modules/AddContactModal/addContactStore';

import FormBlock from '@components/FormBlock';
import { RelatedList, SearchBar } from './components';

import { ContactRelatedItem } from '@/shared/types/contact';


export const AddRelatedContacts = observer(() => {

  const store = useAddContactPopupStore();
  const { relatedContacts, spouseContact } = store;

  const addRelatedContact = useCallback((relatedContact: ContactRelatedItem) => {
    store.setRelatedContacts([...relatedContacts, relatedContact]);
  }, [ relatedContacts, store ]);

  const updateRelatedContacts = useCallback((relatedContacts: Array<ContactRelatedItem>) => {
    store.setRelatedContacts(relatedContacts);
  }, [ store ]);

  const deleteRelatedContact = useCallback((relatedContact: ContactRelatedItem) => {
    const contacts = relatedContacts.filter((item: ContactRelatedItem) => item.id !== relatedContact.id);
    store.setRelatedContacts(contacts);
  }, [ store, relatedContacts ]);

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Add Related Contacts' }/>
      <FormBlock.RowWrapper flexDirection={ 'column' }>
        <SearchBar
          addContact={ addRelatedContact }
          deleteContact={ deleteRelatedContact }
          relatedContacts={ relatedContacts }
          spouseContact={ spouseContact }
        />
        <RelatedList
          contactType={ store.selectedType }
          relatedContacts={ relatedContacts }
          setRelatedContacts={ updateRelatedContacts }
        />
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
