import React, { ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@services/store/store';

import { FILTER_ALL_BTN_LABEL } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';
import { OFFICIAL_REQUEST_SUBCATEGORIES, TODOS_FILTER_NAMES } from '@constants/todosData';

import { getOverdueFilterParamsObject } from '@/shared/utils/getOverdueFilterParamsObject';

import {
  TodoMassDeleteConfirmationPopup,
  TodoMassDeleteRecurringConfirmationPopup
} from '@modules/TodoPopup/components/TodoDeleteConfirmationPopup';
import { TodosPreTablePanel } from '@modules/TodosPreTablePanel';

import { DeleteTodoParams } from '@/shared/types/todos';
import { Item } from '@components/RowFilter/types';

export const PreTablePanelWrapper = observer(() => {

  const modalStore = useStore().ModalStore;

  const {
    categoryFilter,
    filters,
    filtersData,
    isTodoLoads,
    onRemove,
    overdueFilter,
    setCategoryFilter,
    setFilters,
    setOverdueFilter,
    table,
  } = useStore().ToDosStore;
  const { profileId } = useStore().SettingsStore;

  const onOverdueFilterChange = useCallback((event: ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    const { target: { value } } = event;
    setOverdueFilter(
      value as string,
      getOverdueFilterParamsObject(value as string)
    );
  }, [setOverdueFilter]);

  const onUserFilterChange = useCallback((value: Array<string>) => {
    setFilters({
      [TODOS_FILTER_NAMES.users]: value
    });
  }, [setFilters]);

  const onCategoryFilterChange = useCallback((value: Item) => {
    const isRequest = Object.values(OFFICIAL_REQUEST_SUBCATEGORIES).some((subCategory) => subCategory === value);
    const filterName = isRequest ? TODOS_FILTER_NAMES.request : TODOS_FILTER_NAMES.category;
    const newValue = value === FILTER_ALL_BTN_LABEL
      ? null
      : {
        [filterName]: value
      };
    setCategoryFilter(newValue);
  }, [setCategoryFilter]);

  const onDeleteWrapper = (deleteParams?: DeleteTodoParams) => {
    const usualTasks = deleteParams?.filter(item => !item.withRecurring);
    const recurringTasks = deleteParams?.filter(item => item.withRecurring);

    if(usualTasks && usualTasks.length > 0) {
      modalStore.openModal({
        modalType: MODAL_TYPE.MASS_DELETE_POPUP,
        modalProps: {
          deleteData: usualTasks,
          onConfirm: async paramsToDelete => {
            await onRemove(paramsToDelete);
            if(recurringTasks && recurringTasks.length > 0) {
              openMassDeleteRecurringPopup(recurringTasks);
            }
          },
        },
        component: TodoMassDeleteConfirmationPopup,
      });  
    } else if(recurringTasks && recurringTasks.length > 0) {
      openMassDeleteRecurringPopup(recurringTasks);
    }
  };

  const openMassDeleteRecurringPopup = (recurringTasks: DeleteTodoParams) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.MASS_DELETE_POPUP,
      modalProps: {
        deleteData: recurringTasks,
        onConfirm: params => onRemove(params),
      },
      component: TodoMassDeleteRecurringConfirmationPopup,
    });
  };

  return (
    <TodosPreTablePanel
      categoryFilter={ categoryFilter }
      currentUserId={ profileId }
      filters={ filters }
      filtersData={ filtersData }
      isLoad={ isTodoLoads }
      onCategoryFilterChange={ onCategoryFilterChange }
      onMassDelete={ onDeleteWrapper }
      onOverdueFilterChange={ onOverdueFilterChange }
      onUserFilterChange={ onUserFilterChange }
      overdueFilterValue={ overdueFilter.type }
      toDosTableData={ table }
    />
  );
});
