import * as yup from 'yup';

import { validationMessages } from '@constants/validationMessages';
import { Roles } from '@constants/common';

import {
  faxValidation,
  websiteValidation
} from '@constants/validationPatterns';
import { imageValidation } from '@components/ImageUploader';

import { UserProfileInformationInputNamesType } from '@pages/NewUserSettings/types';
import { phoneValidation } from '@modules/HookFormComponents/HFPhoneField/validation';

export const getValidationScheme = (
  inputNames: UserProfileInformationInputNamesType
) => {
  return yup.object().shape({
    [inputNames.firstName]: yup.string().required(validationMessages.invalidFirstName),
    [inputNames.lastName]: yup.string().required(validationMessages.invalidLastName),
    [inputNames.email]: yup.string().email(validationMessages.invalid).required(validationMessages.invalidEmail),
    [inputNames.userRole]: yup.mixed<Roles>().oneOf(Object.values(Roles) as Roles[])
      .required(validationMessages.required),
    [inputNames.status]: yup.string().required(validationMessages.required),
    [inputNames.faxNumber]: yup.string().nullable().matches(faxValidation, validationMessages.invalidFax),
    [inputNames.website]: yup.string().nullable().matches(websiteValidation, {
      excludeEmptyString: true,
      message: validationMessages.invalidWebsite
    }),
    [inputNames.phoneNumber]: phoneValidation,
    [inputNames.headshot]: imageValidation,
    [inputNames.signature]: imageValidation,
    [inputNames.logo]: imageValidation
  });
};
