import { format, isEqual, startOfDay } from 'date-fns';

import { convertedDatesToCurrentTimezone } from '@/shared/utils/convertedDatesToCurrentTimezone';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import { NextAppointment } from '@modules/ContactDetailsSideBarPanel/store/types';

export const getStatusName = (nextAppointment: NextAppointment | null) => {
  if(nextAppointment) {
    return `[${nextAppointment.option}]`;  
  }
  return 'No Calendar Activities Scheduled';
};

export const getDuration = (
  nextAppointment: NextAppointment | null, accountTimeZone: AccountTimeZone, timeZones: TimeZones
) => {
  if(nextAppointment) {
    const { createdDate, startDate, startTime, endDate, endTime, allDay, createdTimeZone } = nextAppointment;
    
    const dates = convertedDatesToCurrentTimezone({
      createdDate: createdDate || new Date(`${startDate}T${startTime}Z`),
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,
      endTime: endTime,
      allDay: yesNoToBoolean(allDay),
      createdTimeZone: timeZones.find(timeZone => timeZone.value === createdTimeZone) || accountTimeZone
    }, accountTimeZone);
  
    const isEqualDate = isEqual(startOfDay(dates.startDate), startOfDay(dates.endDate));
  
    if(isEqualDate) {
      return `
        ${format(dates.startDate, 'LLLL dd, KK:mm aaa - ')}
        ${format(dates.endDate, 'KK:mm aaa')}
      `;
    }
  
    return `
      ${format(dates.startDate, 'LLLL dd, KK:mm aaa - ')}
      ${format(dates.endDate, 'LLLL dd, KK:mm aaa')}
    `;
  }
  return '';
};
