import React, { ChangeEvent, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { TypeFilters } from './components';
import { UserSelectFilter } from '@modules/UserSelectFilter';
import BlockButton from '@components/BlockButton';
import CustomSelect from '@components/CustomSelect';
import CustomToggle from '@components/CustomToggle';
import PreTableWrapper  from '@components/BasicLayoutComponents/PreTableWrapper';
import RouterLink from '@modules/RouterLink';

import { getEntityRoutePath } from '@/shared/utils/getEntityRoutePath';

import { AddTask, Trash } from '@/assets';
import {
  OVERDUE_FILTER_OPTIONS,
  TODOS_FILTER_NAMES
} from '@constants/todosData';
import { ALL_ASSIGNED_TO_SELECT_OPTION, ENTITY_NAMES } from '@constants/common';

import { ActionButtonsWrapper, SelectMenuWrapper, ToggleWrapper, UserFilterWrapper } from './styles';

import { TodosPreTablePanelProps } from './types';

const ADD_TASK_BUTTON_STYLES = {
  width: 'auto',
};

export const TodosPreTablePanel = observer(({
  categoryFilter,
  checkedToggle,
  currentUserId,
  filters,
  filtersData,
  isLoad,
  isShowPreviousToggle = false,
  onCategoryFilterChange,
  onChangeToggle,
  onMassDelete,
  onOverdueFilterChange,
  onUserFilterChange,
  overdueFilterValue,
  toDosTableData
}: TodosPreTablePanelProps) => {
  const { url } = useRouteMatch();
  const taskEntityPath = getEntityRoutePath(url, ENTITY_NAMES.task, 'id');

  const onUserFilterChangeWrapper = useCallback((value: Array<string>) => {
    onUserFilterChange(value);
  }, [ onUserFilterChange ]);

  const onOverdueFilterChangeWrapper = useCallback((
    event: ChangeEvent<{name?: string | undefined, value: unknown}>
  ) => {
    onOverdueFilterChange(event);
  }, [ onOverdueFilterChange ]);

  const onDeleteWrapper = useCallback(() => {
    if(onMassDelete) {
      onMassDelete(toDosTableData.selectedIDs);
    }
  }, [onMassDelete]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: toDosTableData.globalFlagged,
        onStateChange: toDosTableData.setGlobalFlaggedFilters,
      } }>

      <UserFilterWrapper>
        <UserSelectFilter
          allUserOption={ ALL_ASSIGNED_TO_SELECT_OPTION }
          closeTrigger={ isLoad }
          currentUserId={ currentUserId }
          onChange={ onUserFilterChangeWrapper }
          usersOptions={ filtersData?.[TODOS_FILTER_NAMES.users] }
          value={ filters?.[TODOS_FILTER_NAMES.users] ?? [] }
          withAllUserOption={ true }
          withAllButMeOption={ true }
        />
      </UserFilterWrapper>

      <SelectMenuWrapper>
        <CustomSelect
          $fontSize={ '12px' }
          onChange={ onOverdueFilterChangeWrapper }
          options={ OVERDUE_FILTER_OPTIONS }
          style={ {
            width: '215px',
          } }
          value={ overdueFilterValue }
        />
      </SelectMenuWrapper>

      <TypeFilters
        categoryFilter={ categoryFilter }
        onCategoryFilterChange={ onCategoryFilterChange }
      />

      <ActionButtonsWrapper>
        { isShowPreviousToggle &&
          <ToggleWrapper>
            <CustomToggle
              checked={ checkedToggle }
              label={ 'Show Previous' }
              labelPlacement={ 'end' }
              onChange={ onChangeToggle }
            />
          </ToggleWrapper>
        }
        {
          onMassDelete && toDosTableData && toDosTableData.selectedIDs.length > 0 &&
          <BlockButton
            onClick={ onDeleteWrapper }
            startIcon={ <Trash/> }
          >
            Delete All Selected
          </BlockButton>
        }
        <RouterLink
          to={ taskEntityPath }
          styles={ ADD_TASK_BUTTON_STYLES }
        >
          <BlockButton
            onClick={ () => {} }
            startIcon={ <AddTask /> }
          >
            Add Task
          </BlockButton>
        </RouterLink>
      </ActionButtonsWrapper>
    </PreTableWrapper>
  );
});

