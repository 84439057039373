import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import omit from 'lodash/omit';

import { NotesUtilsStore } from '@modules/NotesAndHistory';
import { NotesAndHistoryStore } from '@modules/NotesAndHistory/notesAndHistoryStore';

import CommonTableStore from '@services/store/commonTableStore';

import {
  getContactNextApptDate,
  getSalesCycleItem,
  getSalesCyclePopupSettings
} from '@services/api/salesPipeline/salesActiveCycle';
import { deleteTodo, saveTodo, updateTodo } from '@services/api/addAndEditTask/addAndEditTask';
import { getGridForContact } from '@services/api/todos/todos';
import { getTags } from '@services/api/tags/tags';

import { getFilterParams, getSortParams } from '@/shared/utils/filterUtils';
import { sourceConverter } from '@/shared/utils/getOptionsUtils';
import { todoItemNormalizer } from '@/shared/utils/toDosNormalizers';
import { todoItemsResponseNormalizer } from '@/shared/utils/todoItemsResponseNormalizer';

import { LINKED_TASK_FILTERS_NAME } from './data';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES } from '@constants/salesActiveCycle';
import { SALES_ACTIVE_POPUP_DEFAULT_VALUES } from '../data';
import { SortDirectionNames } from '@constants/common';

import {
  CloseModal,
  GetNextAppointmentDateResponse,
  TagsBackendResponse,
  ValueLabelObj
} from '@/shared/types/commonTypes';
import {
  SalesCyclePopupOnSaveCallback,
  SalesActiveCycleGetByIdResponse,
  SalesActiveCyclePopupSettingsResponse,
  SalesActiveCycleType,
  SalesActiveCycleStatusTypeWithoutActive,
  SalesCycleOnDeleteCallback
} from '@/shared/types/salesActiveCycle';
import { BackendTodoFormFields, DeleteTodoParams, GridResponse, TodoItem } from '@/shared/types/todos';
import { LinkedContact } from '@/shared/types/linkedContact';
import { NoteTagItem } from '@/shared/types/tags';
import { UseFormMethods, InitProps } from './types';

class SalesActivePopupStore {
  isHideModal: boolean = false;
  isLoad: boolean = false;
  itemId: SalesActiveCycleType['id'] | null = null;
  linkedContactData: LinkedContact | null  = null;
  nextAppointmentDate: string | null = null;
  notesAndHistoryStore: NotesAndHistoryStore;
  notesUtilsStore: NotesUtilsStore;
  predefinedTags: Array<NoteTagItem> = [];
  quickStatusSubmit: SalesActiveCycleStatusTypeWithoutActive | null = null;
  sourceOptions: Array<ValueLabelObj> = [];
  table: CommonTableStore<TodoItem>;
  todoTableActivePage: number = 1;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  closeTrigger: CloseModal | null = null;
  onDeleteCallback?: SalesCycleOnDeleteCallback | null = null;
  onSaveCallback: SalesCyclePopupOnSaveCallback | null = null;

  onLinkedContactDataChangeReaction: IReactionDisposer;
  onPageChangeReaction: IReactionDisposer;

  constructor() {
    makeAutoObservable(this, {
      getLinkedTasks: flow.bound,
      init: flow.bound,
      onCancel: action.bound,
      onConfirm: action.bound,
      onDelete: action.bound,
      removeTodo: flow.bound,
      resetState: action.bound,
      saveTodo: flow.bound,
      setIsHideModal: action.bound,
      setTodoTableActivePage: action.bound,
    });

    this.notesUtilsStore = new NotesUtilsStore();
    this.notesAndHistoryStore = new NotesAndHistoryStore();
    this.table = new CommonTableStore<TodoItem>();

    this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
    this.onPageChangeReaction = this.createOnPageChangeReaction();
  }

  *init({
    closeTrigger,
    id,
    onDeleteCallback,
    onSaveCallback,
    useFormMethods,
  }: InitProps){
    try {
      this.isLoad = true;

      this.useFormMethods = useFormMethods;
      this.onSaveCallback = onSaveCallback;
      this.onDeleteCallback = onDeleteCallback;
      this.closeTrigger = closeTrigger;

      const settingsResponse: SalesActiveCyclePopupSettingsResponse = yield getSalesCyclePopupSettings();
      this.sourceOptions = sourceConverter(settingsResponse.data.data.source);

      const tagsResp: TagsBackendResponse = yield getTags({
        type: 'Sales Pipe Line Note',
        searchPhrase: ''
      });
      this.predefinedTags = tagsResp.data.data;

      if(id) {
        this.itemId = id;
        this.onLinkedContactDataChangeReaction();

        const itemResponse: SalesActiveCycleGetByIdResponse = yield getSalesCycleItem({ id });
        this.linkedContactData = itemResponse.data.data.contactData;

        yield this.getLinkedTasks();

        this.notesUtilsStore.setCurrentLinkedContact(itemResponse.data.data.contactData);

        this.nextAppointmentDate = itemResponse.data.data.nextAppointmentDate || null;
        this.useFormMethods.reset(
          omit(itemResponse.data.data, ['contactData', 'nextAppointmentDate'])
        );

        this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *onLinkedContactDataChange() {
    if(this.linkedContactData){
      try {
        this.isLoad = true;
        const nextAppointmentDateResponse:GetNextAppointmentDateResponse = yield getContactNextApptDate({
          id: this.linkedContactData.id
        });

        const nextAppointmentDate = nextAppointmentDateResponse.data.data.nextAppointmentDate;

        this.nextAppointmentDate = nextAppointmentDate;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }
    }else {
      this.nextAppointmentDate = null;
    }
  }

  *getLinkedTasks() {
    this.table.items = [];
    this.todoTableActivePage = 1;

    if(this.linkedContactData) {
      this.isLoad = true;
      try {
        yield this.getLinkedTasksWithoutLoading();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }
    }
  }

  *getLinkedTasksWithoutLoading() {
    const response: GridResponse = yield getGridForContact({
      ...getFilterParams({ [LINKED_TASK_FILTERS_NAME.salesPipelineId]: this.itemId }),
      ...getSortParams(LINKED_TASK_FILTERS_NAME.dueReviewDate, SortDirectionNames.Asc),
      page: this.todoTableActivePage,
    });
    
    const normalized = todoItemsResponseNormalizer(response.data.data.data);
    this.table.items = this.table.items.concat(normalized.items.map((item: TodoItem) => todoItemNormalizer(item)));
    this.table.setPaginationData(response.data.data);
  }

  *saveTodo(data: BackendTodoFormFields) {
    this.isLoad = true;
    try {
      if(data.id) {
        yield updateTodo(data);
      } else {
        yield saveTodo(data);
      }
      yield this.getLinkedTasks();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *removeTodo(params: DeleteTodoParams) {
    this.isLoad = true;
    try {
      yield deleteTodo(params);
      yield this.getLinkedTasks();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  createOnLinkedContactDataReaction() {
    return reaction(
      () => this.linkedContactData,
      () => this.onLinkedContactDataChange()
    );
  }

  createOnPageChangeReaction() {
    return reaction(
      () => this.todoTableActivePage,
      () => {
        if(this.todoTableActivePage !== 1 && this.linkedContactData) {
          this.getLinkedTasksWithoutLoading();
        }
      },
    );
  }

  onLinkedContactChange = (newLinkedContact: LinkedContact | null) => {
    this.notesUtilsStore.setPrevLinkedContact(this.linkedContactData);
    this.notesUtilsStore.setCurrentLinkedContact(newLinkedContact);

    this.linkedContactData = newLinkedContact;
  };

  onConfirm(status?: SalesActiveCycleStatusTypeWithoutActive) {
    this.useFormMethods.handleSubmit((data) => {
      if(typeof status ==='string'){
        this.quickStatusSubmit = status;
      }

      let calculatedData =  {
        ...data,
        [SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus]: this.quickStatusSubmit || data.salesCycleStatus
      };

      if(this.notesUtilsStore.alertType) {
        calculatedData = {
          ...calculatedData,
          [SALES_ACTIVE_CYCLE_FIELD_NAMES.notes]: this.notesUtilsStore.convertNotesByAlertType(
            calculatedData.notes,
            this.linkedContactData
          )
        };
      } else {
        if(this.notesUtilsStore.detectAndSetAlert(calculatedData.notes, this.linkedContactData)){
          return;
        }
      }

      if(this.onSaveCallback && this.closeTrigger){
        this.onSaveCallback(this.closeTrigger, calculatedData);
        this.quickStatusSubmit = null;
      }
    })();
  }

  onCancel() {
    if(this.notesUtilsStore.alertType){
      this.notesUtilsStore.resetAlertType();
      this.quickStatusSubmit = null;
    } else {
      this.closeTrigger && this.closeTrigger();
    }
  }

  onDelete() {
    if(this.itemId && this.onDeleteCallback) {
      this.closeTrigger && this.closeTrigger();
      this.onDeleteCallback(this.itemId);
    }
  }

  setTodoTableActivePage() {
    if(this.todoTableActivePage < this.table.totalPages) {
      this.todoTableActivePage = this.todoTableActivePage + 1;
    }
  }

  setIsHideModal(state: boolean) {
    this.isHideModal = state;
  }

  resetState() {
    this.closeTrigger = null;
    this.onDeleteCallback = null;
    this.onSaveCallback = null;

    this.isHideModal = false;
    this.isLoad = false;
    this.itemId = null;
    this.linkedContactData = null;
    this.nextAppointmentDate = null;
    this.predefinedTags = [];
    this.sourceOptions = [];
    this.useFormMethods = {} as UseFormMethods;

    this.notesUtilsStore.resetNotesState();
    this.table.resetTable();
  }
}

export default SalesActivePopupStore;
