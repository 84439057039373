import MuiBox from '@material-ui/core/Box';
import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { Alert } from '@/assets';

export const Content = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 15px;
  padding: 15px 20px;
  border-left: 2px solid ${COLORS.orange};
  border-radius: 4px;
  background-color: ${COLORS.background};
`;

export const AlertIcon = styled(Alert)`
  min-height: 44px;
  min-width: 44px;
`;

export const NotificationMessage = styled(MuiBox)`
  margin-left: 19px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${COLORS.helpText};

  & b {
    color: ${COLORS.header};
    font-weight: 500;
  }
`;
