import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  height: 750px;
  
  background-color: ${COLORS.background};
  gap: 10px;
`;

export const NotesAndHistoryWrapper = styled.div`
  width: 500px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  background-color: ${COLORS.white};
  box-shadow: inset 1px 1px 1px ${COLORS.greyLight}
`;
