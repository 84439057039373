import React, { useCallback, useMemo } from 'react';

import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { getInternationalFormatNumber } from '@modules/HookFormComponents/HFPhoneField/validation';

import { USER_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsUserCustomFields';

import { DescriptionWrapper } from './styles';

import { CustomFieldRowProps } from './types';

const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'custom-field-name';

export const CustomFieldRow = ({
  data,
  onDelete,
  onEdit,
}: CustomFieldRowProps) => {
  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.delete ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ),[]);
  
  const onMenuSelect = useCallback((type :DotsMenuItemTypes) => {
    if(type === DOTS_MENU_ITEM_TYPES.edit) {
      onEdit(data);
    }
    if(type === DOTS_MENU_ITEM_TYPES.delete) {
      onDelete(data);
    }
  },[data, onDelete, onEdit]);
  
  const onEditWrapper = useCallback(() => {
    onEdit(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEdit]);
  
  const value = useMemo(() => {
    if(data.type === USER_CUSTOM_FIELDS_TYPES.phoneField) {
      return getInternationalFormatNumber(data.value.toString() || '') || '-';
    }
    return data.value;
  },[]);

  return (
    <Table.Row
      hover
      role="checkbox"
      tabIndex={ -1 }
    >
      <Table.Cell
        dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
        onClick={ onEditWrapper }
      >
        <TextWithTooltip
          dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
          text={ data.name }
        />
      </Table.Cell>
      <Table.Cell>
        <DescriptionWrapper title={ `${value}` }>
          { value }
        </DescriptionWrapper>
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};
