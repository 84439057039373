import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useActivityPopupLocalStore } from '@modules/ActivityPopup/store';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';

import { ActivityPopupFieldsName, LABEL_WIDTH } from '@modules/ActivityPopup/data';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

import { getContactWithSpouseHousehold } from '@/shared/services/api/common/common';
import { getNameParam, usersResponseConverter, } from '@/shared/utils/fetchAutocompleteUtils';

import { LinkedContactProps } from './types';
import { LinkedContact as LinkedContactType } from '@/shared/types/linkedContact';

export const LinkedContact = observer(({
  initLinkedContact,
  onClose,
  onLinkedContactChange
}: LinkedContactProps) => {
  const routerStore = useStore().RouterStore;
  const { resetStore, clearEditActivityItem } = useActivityPopupLocalStore();

  const { control } = useFormContext();

  const onContactClick = useCallback(() => {
    clearEditActivityItem();
    resetStore();
    onClose();
    routerStore.moveToContact({
      id: initLinkedContact?.id,
      contactType: initLinkedContact?.type,
      tab: CONTACT_DETAILS_TAB.overview,
    });
  }, [initLinkedContact]);

  return (
    <FormBlock.RowWrapper>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH.common }
        label={ 'Linked Contact' }
      >
        <HFLinkedContact<LinkedContactType>
          FetchProps={ {
            getParams: getNameParam,
            requestSender: getContactWithSpouseHousehold,
            responseConverter: usersResponseConverter,
          } }
          control={ control }
          initLinkedContact={ initLinkedContact }
          name={ ActivityPopupFieldsName.primaryContactId }
          onLinkedContactChange={ onLinkedContactChange }
          UserItemProps={ {
            $isClickable: true,
            onClick: onContactClick
          } }
        />
      </FormBlock.FormField>
    </FormBlock.RowWrapper>
  );
});
