import { flow, makeAutoObservable } from 'mobx';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { Store } from '@store';

import { updateTodo, saveTodo, deleteTodo } from '@services/api/addAndEditTask/addAndEditTask';

import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { BackendTodoFormFields, DeleteTodoParams } from '@/shared/types/todos';

export default class TodoStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  coreStore: Store;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      saveTodo: flow.bound,
      removeTodo: flow.bound
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.task
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *saveTodo(data: BackendTodoFormFields) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      if(data.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateTodo(data),
          onError: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.error,
            otherEntityName: ENTITY_NAMES.task
          }),
          onSuccess: () => this.notificationHelper.update({
            status: NOTIFICATION_TYPES.success,
            otherEntityName: ENTITY_NAMES.task
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveTodo(data),
          onError: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.error,
            otherEntityName: ENTITY_NAMES.task
          }),
          onSuccess: () => this.notificationHelper.create({
            status: NOTIFICATION_TYPES.success,
            otherEntityName: ENTITY_NAMES.task
          }),
        });
      }
      yield this.coreStore.ContactDetailsOverview.getContactOverView();
      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *removeTodo(params: DeleteTodoParams) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteTodo(params),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          otherEntityName: ENTITY_NAMES.task,
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          otherEntityName: ENTITY_NAMES.task,
        })
      });
      yield this.coreStore.ContactDetailsOverview.getContactOverView();
      yield this.coreStore.ContactDetailsOverview.initNotesAndHistoryStore();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
}