import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useFieldArray } from 'react-hook-form';

import { useStore } from '@store';

import { useLazyLoading } from '@/shared/customHooks/useLazyLoading';

import { MODAL_TYPE } from '@constants/modalTypes';
import { NOTE_AND_HISTORY_TYPE, VIEWS } from '@modules/NotesAndHistory/data';

import {
  generalNotesProcessor,
  standardNotesProcessor
} from '@modules/NotesAndHistory/components/Notes/components/NotesView/utils';
import { getFormatedUTCDateString } from '@/shared/utils/getFormatedUTCDateString';
import { getFormattedTitleFromNoteText } from './utils';

import { AddEditNote, NotesView, } from './components';
import { DeleteNotePopup } from '@modules/DeleteNotePopup';
import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import Preloader from '@components/Preloader';

import { Spacer, Wrapper } from './styles';

import { NoteAndHistoryProps, Item } from '@modules/NotesAndHistory/types';

const keyName = 'useFormNoteId';

export const Notes = observer(({
  $padding,
  control,
  creationDateFieldName = 'created',
  currentUser,
  deleteConfirmationText,
  disableAddNoteTooltipText,
  instanceStore,
  isAbleToEdit,
  isAddNoteBtnDisabled,
  isShowRowWithTitleAndAddButton = true,
  linkedContactData,
  names,
  noteAndHistoryType,
  noteContactOptions = [],
  onAddEditEnd = () => {},
  onAddEditStart = () => {},
  onEntityClick,
  predefinedTags = [],
  title = 'Notes',
}: NoteAndHistoryProps) => {
  const {
    deleteNote,
    editNote,
    entityName,
    getNote,
    increaseCurrentPage,
    isLoading,
    notesView,
    onAddNoteClick,
    saveNote,
    setEditNote,
    setNotesView
  } = instanceStore;

  const modalStore = useStore().ModalStore;

  const [scrollPosition, setScrollPosition] = useState(0);
  
  const [onScroll, containerRef] = useLazyLoading<HTMLDivElement>({
    delay: 100,
    marginFromBottom: 10,
    onIntersection: increaseCurrentPage
  });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: names.notes,
    keyName
  });

  const onCancelAddNoteClick = useCallback(() => {
    setNotesView(VIEWS.grid);
    setEditNote(null);
  }, [setEditNote, setNotesView]);

  const findItemIndexAndDoCallback = useCallback((
    item: Item,
    callback: (index: number) => void
  ) => {
    const indexOfItem = fields.findIndex(field => field[keyName] === item[keyName]);
    if(indexOfItem >= 0) {
      callback(indexOfItem);
    }
  }
  , [fields]);

  const onAddNoteClickWrapper = useCallback(() => {
    if(containerRef.current) {
      setScrollPosition(containerRef.current.scrollTop);
    }
    onAddNoteClick();
  }, [onAddNoteClick]);

  const onItemEditClick = useCallback((item: Item) => async() => {
    let editNote = item;
    if(entityName && item) {
      // TODO: note refactoring
      //@ts-ignore
      editNote = await getNote(item.id);
    }
    setEditNote(editNote);
    if(containerRef.current) {
      setScrollPosition(containerRef.current.scrollTop);
    }
    setNotesView(VIEWS.addEdit);
  }, [setEditNote, setNotesView, scrollPosition]);

  const onItemDeleteClick = useCallback((item: Item) => () => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_NOTES_FROM_OVERVIEW,
      modalProps: {
        onConfirm: () => {
          findItemIndexAndDoCallback(item, remove);
          if(entityName) {
            deleteNote(item.id);
          }
        },
        message: deleteConfirmationText,
      },
      component: DeleteNotePopup
    });
  }, [findItemIndexAndDoCallback, remove]);

  const onSaveNoteClick = useCallback((data: Item) => {
    if(entityName) {
      setNotesView(VIEWS.grid);
      setEditNote(null);

      if(!data.title) {
        data.title = getFormattedTitleFromNoteText(data.noteText);
      }
      // TODO: note refactoring
      //@ts-ignore
      saveNote(data);
      return;
    }

    const date = getFormatedUTCDateString();
    if(!data.title) {
      data.title = getFormattedTitleFromNoteText(data.noteText);
    }

    if(editNote) {
      findItemIndexAndDoCallback(editNote, (index) => {
        update(index, {
          ...editNote,
          ...data,
          [creationDateFieldName]: date
        });
      });
    } else {
      append({
        ...data,
        clientData: currentUser,
        [creationDateFieldName]: date,
      });
    }
    setNotesView(VIEWS.grid);
    setEditNote(null);
  }, [
    append,
    creationDateFieldName,
    currentUser,
    editNote,
    entityName,
    findItemIndexAndDoCallback,
    setEditNote,
    setNotesView,
    update,
  ]);

  return (
    <Wrapper $padding={ $padding }>
      { isLoading &&
        <Spacer>
          <Preloader isWithoutFullPageWrapper={ true } />
        </Spacer>
      }
      {/*Use hidden inputs to store data at form state*/}
      {
        fields.map((item,index) =>(
          <ControlledHiddenInput
            control={ control }
            defaultValue={ item }
            key={ item?.useFormNoteId }
            name={ `${names.notes}.${index}` }
          />
        ))
      }
      {
        notesView === VIEWS.grid &&
        <NotesView
          containerRef={ containerRef }
          control={ control }
          creationDateFieldName={ creationDateFieldName }
          disableAddNoteTooltipText={ disableAddNoteTooltipText }
          instanceStore={ instanceStore }
          isAbleToEdit={ isAbleToEdit }
          isAddNoteBtnDisabled={ isAddNoteBtnDisabled }
          isShowRowWithTitleAndAddButton={ isShowRowWithTitleAndAddButton }
          noteAndHistoryType={ noteAndHistoryType }
          notes={ fields || [] }
          notesName={ names.notes }
          notesProcessor={ noteAndHistoryType === NOTE_AND_HISTORY_TYPE.module ?
            standardNotesProcessor : generalNotesProcessor
          }
          onAddNoteClick={ onAddNoteClickWrapper }
          onDeleteClick={ onItemDeleteClick }
          onEditClick={ onItemEditClick }
          onScroll={ onScroll }
          scrollPosition={ scrollPosition }
          title={ title }
        />
      }
      {
        notesView === VIEWS.addEdit &&
        <AddEditNote
          editNote={ editNote }
          instanceStore={ instanceStore }
          linkedContactData={ linkedContactData }
          names={ names }
          noteAndHistoryType={ noteAndHistoryType }
          noteContactOptions={ noteContactOptions }
          onAddEditEnd={ onAddEditEnd }
          onAddEditStart={ onAddEditStart }
          onCancelAddNoteClick={ onCancelAddNoteClick }
          onEntityClick={ onEntityClick }
          onSaveNoteClick={ onSaveNoteClick }
          predefinedTags={ predefinedTags }
        />
      }
    </Wrapper>
  );
});
