import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import UserItem from '@components/NewUserItem';

import { Search } from '@/assets';

import { getFullName, getParamsToSearchContactNamePhoneEmail } from '@/shared/utils/fetchAutocompleteUtils';
import { searchContactByNamePhoneEmail } from '@services/api/common/common';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { CONTACT_TYPES } from '@constants/common';
import { LinkedContact } from '@/shared/types/linkedContact';

import { CrossIconWrapper, SearchWrapper, StyledTextField } from './styles';



const contactResponseConverter = (response: any) => {
  const data = response?.data?.data?.data;
  return data ? Object.values(data) : [];
};


export const ContactsSearch = observer(() => {
  const routerStore = useStore().RouterStore;

  const [inputValue, setInputValue] = useState<string>('');

  const [open, setOpen] = useState(false);

  const onOpenPopover = () => {
    setOpen(true);
  };

  const onClosePopover = () => {
    setOpen(false);
  };

  const onDeleteWrapper = useCallback(() => {
    onClosePopover();
    setInputValue('');
  },[]);

  const onChange = useCallback((data: LinkedContact) => {
    setInputValue('');

    if(data) {
      routerStore.moveToContact({
        id: data?.id,
        contactType: data?.type,
        tab: CONTACT_DETAILS_TAB.overview,
      });
    }
  }, [routerStore, setInputValue]);

  const searchValidation = useMemo(() => (value: string) => (
    value.replace(/^@/, '').replace(/^\(/, '').replace(/^\)/, '').replace(/^\./, '')
  ), []);

  return (
    <>
      <FetchAutoComplete
        AutocompleteProps={ {
          blurOnSelect: true,
          clearOnBlur: true,
          filterOptions: (options: any) => options,
          getOptionLabel: (option: any) => option.fullName || getFullName(option) || 'label',
          onClose: onClosePopover,
          onOpen: onOpenPopover,
          open: open,
          renderOption: (option: any) => {
            if(option.type === CONTACT_TYPES.HOUSEHOLD && !option.householderContacts ||
              option.type === CONTACT_TYPES.INDIVIDUAL && (!option.firstName || !option.lastName) ||
              option.type === CONTACT_TYPES.BUSINESS && !option.occupationCompany && !option.fullName) {
              return null;
            }
            return (
              <UserItem
                containerStyle={ {
                  paddingLeft: '10px',
                } }
                user={ option }
                withCoreClassification={ true }
              />);
          },
          renderInput: (params) => (
            <StyledTextField
              { ...params }
              InputProps={ {
                ...params.InputProps,
                endAdornment: (
                  <SearchWrapper>
                    <Search/>
                  </SearchWrapper>
                )
              } }
              placeholder={ 'Type to search Contacts, Phones, Emails...' }
              variant='outlined'
            />
          )
        } }
        FetchProps={ {
          getParams: getParamsToSearchContactNamePhoneEmail,
          requestSender: searchContactByNamePhoneEmail,
          responseConverter: contactResponseConverter
        } }
        onChange={ onChange }
        outSideInputValue={ inputValue }
        searchValidation={ searchValidation }
        setOutSideInputValue={ setInputValue }
      />
      {
        inputValue.length > 0 &&
        <CrossIconWrapper>
          <CustomIconButton
            onClick={ onDeleteWrapper }
            type={ ICON_BUTTON_TYPES.cross }
          />
        </CrossIconWrapper>
      }
    </>
  );
});
