import { CONTACT_TYPES } from '@/shared/constants/common';
import { AddNewContactFieldTagItem, TagsList } from '@/shared/types/addNewContactPopup';

import {
  ADD_CONTACT_TAGS_TYPES,
  CONTACT_OPTIONS_URL
} from '@/modules/AddContactModal/addContactStore/data/constantData';

export const getContactRoute = (selectedType: CONTACT_TYPES) => {
  switch (selectedType) {
  case CONTACT_TYPES.HOUSEHOLD:
    return CONTACT_OPTIONS_URL.household;
  case CONTACT_TYPES.BUSINESS:
    return CONTACT_OPTIONS_URL.business;
  default:
    return CONTACT_OPTIONS_URL.individual;
  }
};

export const getErrorStep = (errors: any) => {
  return parseInt(errors[0].path.slice(0, 1));
};

export const TagsNormalizer = (tags: Array<AddNewContactFieldTagItem>): TagsList => {
  const interest: Array<AddNewContactFieldTagItem> = [];
  const general: Array<AddNewContactFieldTagItem> = [];

  tags.forEach(tag => {
    const { type, id, label } = tag;
    if(type === ADD_CONTACT_TAGS_TYPES.general) {
      return general.push({ label, id, type });
    }
    interest.push({ label, id, type });
  });

  return { interest, general };
};

export const getTagsInitState = (): TagsList => {
  return {
    interest: [],
    general: []
  };
};
