import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { checkInclusion } from '@/modules/AddContactModal/components/Tags/helpers';

import { SelectedCheckbox, UnSelectedCheckbox, Search } from '@/assets';
import CustomAutocomplete from '@components/CustomAutocomplete';
import TextWithTooltip from '@components/TextWithTooltip';

import { TagWrapper, Container, StyledTextField } from './styles';
import { TagSearchProps } from './types';


export const TagSearch = ({
  onChange,
  options,
  placeholder,
  selectedTags
}: TagSearchProps) => {

  const changeHandler = (_e: any, _v: any, _r: any, details?: any) => {
    onChange(details?.option);
  };

  return (
    <Container>
      <CustomAutocomplete
        AutocompleteProps={ {
          disableClearable: true,
          disableCloseOnSelect: true,
          getOptionLabel: (option: any) => option.label || 'label',
          getOptionSelected: () => false,
          multiple: true,
          noOptionsText: 'No results',
          onChange: changeHandler,
          options: options,
          renderTags: () => null,
          renderInput: (params) => (
            <StyledTextField
              { ...params }
              InputProps={ {
                ...params.InputProps,
                endAdornment: <Search /> 
              } }
              placeholder={ placeholder }
              variant='outlined'
            />
          ),
          renderOption: (option: any) => {
            const checked = checkInclusion(option, selectedTags);
            return (
              <TagWrapper checked={ checked }>
                <Checkbox
                  checked={ checked }
                  checkedIcon={ <SelectedCheckbox /> }
                  icon={ <UnSelectedCheckbox /> }
                  style={ { marginRight: 8, padding: 0 } }
                />
                <TextWithTooltip text={ option.label || '' } />
              </TagWrapper>
            );
          },
        } }
      />
    </Container>
  );
};
