import { FIELD_IDS } from '@constants/contactsData';

export const LEFT_COLUMN_WIDTH = 144;
export const RIGHT_COLUMN_WIDTH = 122;

export const LEFT_COLUMN_WIDTH_V_TWO = 146;
export const RIGHT_COLUMN_WIDTH_V_TWO = 99;

export const LEFT_COLUMN_WIDTH_V_THREE = 147;
export const RIGHT_COLUMN_WIDTH_V_THREE = 146;

enum PHONE_LABEL {
  Home = 'Home',
  Mobile = 'Mobile',
  Other = 'Other',
  Work = 'Work',
}

export const PHONE_OPTIONS = [
  {
    value: FIELD_IDS.MOBILE_PHONE_ID,
    label: PHONE_LABEL.Mobile
  },
  {
    value: FIELD_IDS.WORK_PHONE_ID,
    label: PHONE_LABEL.Work
  },
  {
    value: FIELD_IDS.HOME_PHONE_ID,
    label: PHONE_LABEL.Home
  },
  {
    value: FIELD_IDS.OTHER_PHONE_ID,
    label: PHONE_LABEL.Other
  },
];

enum EMAIL_LABEL {
  Home = 'Home',
  Other = 'Other',
  Work = 'Work',
}

export const EMAIL_OPTIONS = [
  {
    value: FIELD_IDS.WORK_EMAIL_ID,
    label: EMAIL_LABEL.Work
  },
  {
    value: FIELD_IDS.HOME_EMAIL_ID,
    label: EMAIL_LABEL.Home
  },
  {
    value: FIELD_IDS.OTHER_EMAIL_ID,
    label: EMAIL_LABEL.Other
  },
];
