import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { ItemProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${ COLORS.background };
  border-radius: 5px;
`;

export const Item = styled.button<ItemProps>`
  position: relative;
  flex-grow: 1;
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background-color: transparent;

  &:hover {
    ${({ disabled }) => !disabled && `
      background-color: ${COLORS.borders};
      cursor: pointer;
    `};
  }

  &:after {
    position: absolute;
    content: '';
    top: 3px;
    bottom: 3px;
    right: 0;
    width: 1px;
    background-color: ${ COLORS.borders };
  }

  &:last-child:after{
    display: none;
  }
`;
