import { ACCOUNT_ACCOUNT_TYPES } from '@modules/AccountsAndPlansPopup/data/data';
import { capitalize } from '@/shared/utils/capitalize';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { ValueLabelObj } from '@/shared/types/commonTypes';

export const CONTACT_RANK_A = {
  value: 1,
  label: 'A'
};
export const CONTACT_RANK_B = {
  value: 2,
  label: 'B'
};
export const CONTACT_RANK_C = {
  value: 3,
  label: 'C'
};
export const CONTACT_RANK_D = {
  value: 4,
  label: 'D'
};
export const CONTACT_RANK_F = {
  value: 5,
  label: 'F'
};

export const CONTACT_RANKS = [
  CONTACT_RANK_A,
  CONTACT_RANK_B,
  CONTACT_RANK_C,
  CONTACT_RANK_D,
  CONTACT_RANK_F,
];

export enum CONTACT_TYPES  {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Business',
  HOUSEHOLD = 'Household'
}

export enum CORE_CLASSIFICATIONS {
  CLIENT = 'Client',
  LEAD = 'Lead',
  PROSPECT = 'Prospect',
  GENERAL = 'General',
  EX_CLIENT = 'Ex-Client',
  FINANCIAL_PLANNING_CLIENT = 'Financial Planning Client',
}

export enum RELATION_TYPES {
  CHILD = 'Child',
  EX_SPOUSE = 'Ex-Spouse',
  GRANDPARENT = 'Grandparent',
  GRANDCHILD = 'Grandchild',
  OTHER = 'Other',
  PARENT = 'Parent',
  SIBLING = 'Sibling',
  SPOUSE = 'Spouse'
}

export const ADD_SPOUSE_TYPES = {
  combineHousehold: 'combineHousehold',
  linkedSeparateRecord: 'linkedSeparateRecord',
};

export const POPUP_RADIO_VALUES = {
  all: 'all',
  current: 'current',
};

export const NOT_ASSIGN_SELECT_OPTION: ValueLabelObj = {
  value: 'none',
  label: 'Not Assigned',
};

export const ALL_USERS_SELECT_OPTION = {
  label: 'All Users',
  value: 'all'
};

export const ALL_BUT_ME_OPTION = {
  label: 'All But Me',
  value: 'exceptCurrentUser'
};

export const CONTACT_STATUSES = {
  active: 'Active',
  deceased: 'Deceased',
} as const;

export const CONTACT_STATUSES_OPTIONS = optionsFromValue(Object.values(CONTACT_STATUSES));

export const CONTACT_CLASSIFICATION = [
  {
    value: 'Prospect',
    label: 'Prospect'
  },
  {
    value: 'Lead',
    label: 'Lead'
  },
  {
    value: 'Client',
    label: 'Client'
  },
  {
    value: 'General',
    label: 'General'
  },
  {
    value: 'Ex-Client',
    label: 'Ex-Client'
  },
  {
    value: 'Financial Planning Client',
    label: 'Financial Planning Client'
  }
];

export enum Roles {
  Master = 'Master',
  Admin = 'Admin',
  Owner = 'Owner',
  Advisor = 'Advisor',
  Standart = 'Standard',
  Limited = 'Limited',
}

export const USER_ROLES_FOR_SEARCH = {
  advisors: 'advisors',
  clients: 'clients'
};

export const PAGE_TITLES = {
  userSettings: 'User Settings'
};

export enum EmailStatuses {
  Verified = 'Verified',
  Unverified = 'Unverified',
  Pending = 'Pending',
}

export enum UserStatuses {
  Active = 'Active',
  Inactive = 'Inactive',
}


export enum EMPLOYEE_RELATIONSHIPS {
  CURRENT_EMPLOYEE = 'Current Employee',
  FORMER_EMPLOYEE = 'Former Employee',
  BUSINESS_ASSOCIATE = 'Business Associate',
  ADVISOR = 'Advisor',
  REFERRAL = 'Referral',
  OTHER = 'Other',
}

export const HOUSEHOLD_FORM_PREFIX = 'householderContacts' as const ;

export const FILTER_ALL_BTN_LABEL = 'All';

export enum SortDirectionNames {
  Desc = 'desc',
  Asc = 'asc',
}

export const CALENDAR_VIEWS = {
  month: 'month',
  week: 'week',
  day: 'day',
} as const;

export const CALENDAR_VIEW_OPTIONS = Object.values(CALENDAR_VIEWS).map((value) => ({
  label: capitalize(value),
  value,
}));

export enum LISTS_TYPES {
  Folder = 'Folder',
  Dynamic = 'Dynamic',
  Manual = 'Manual'
}

export const MONTH_OPTIONS: Array<ValueLabelObj> = [
  {
    label: 'January',
    value: '01'
  },
  {
    label: 'February',
    value: '02'
  },
  {
    label: 'March',
    value: '03'
  },
  {
    label: 'April',
    value: '04'
  },
  {
    label: 'May',
    value: '05'
  },
  {
    label: 'June',
    value: '06'
  },
  {
    label: 'July',
    value: '07'
  },
  {
    label: 'August',
    value: '08'
  },
  {
    label: 'September',
    value: '09'
  },
  {
    label: 'October',
    value: '10'
  },
  {
    label: 'November',
    value: '11'
  },
  {
    label: 'December',
    value: '12'
  },
];

export const GENDER_OPTIONS: Array<ValueLabelObj> = [
  {
    value: 'Male',
    label: 'Male'
  },
  {
    value: 'Female',
    label: 'Female'
  }
];

export const RETIREMENT_STATUS_OPTIONS: Array<ValueLabelObj> = [
  {
    value: 'Retired',
    label: 'Retired'
  },
  {
    value: 'Still Employed',
    label: 'Still Employed'
  }
];

export const ACCOUNT_TYPES_OPTIONS: Array<ValueLabelObj> = [
  {
    value: ACCOUNT_ACCOUNT_TYPES.cashAndEquivalent,
    label: ACCOUNT_ACCOUNT_TYPES.cashAndEquivalent
  },
  {
    value: ACCOUNT_ACCOUNT_TYPES.investments,
    label: ACCOUNT_ACCOUNT_TYPES.investments
  },
  {
    value: ACCOUNT_ACCOUNT_TYPES.otherAccount,
    label: ACCOUNT_ACCOUNT_TYPES.otherAccount
  },
];

export const NOTE_TYPES = {
  note: 'Note',
  call: 'Call',
  voiceMail: 'Voice Mail',
  inPerson: 'In Person',
  text: 'Text',
  clientService: 'Client Service',
  socialMedia: 'Social Media',
  email: 'Email',
} as const;

export const ID_OF_USASTATES = 187;

export const VIEW_TYPES = {
  table: 'table',
  kanban: 'kanban'
} as const;

export enum HISTORY_ACTIONS {
  PUSH = 'PUSH',
  POP = 'POP',
  REPLACE = 'REPLACE'
}

export enum ImageUploaderCategories {
  User = 'user',
  DefaultContact = 'default contact',
  BusinessContact = 'business contact'
}

export enum ImageUploaderTypes {
	Headshot = 'headshot',
	Signature = 'signature',
	Logo = 'logo'
}

export const ENTITY_NAMES = {
  account: 'Account',
  accountAndPlans: 'Account and Plan',
  activity: 'Activity',
  clientOpportunity: 'Client Opportunity',
  contact: 'Contact',
  customField: 'Custom Field',
  employee: 'Employee',
  folder: 'Folder',
  list: 'List',
  note: 'Note',
  opportunity: 'Opportunity',
  otherTask: 'Other Task',
  password: 'Password',
  plan: 'Plan',
  policy: 'Policy',
  profile: 'Profile',
  relatedContact: 'Related Contact',
  salesCycle: 'Sales Cycle',
  settings: 'Settings',
  tag: 'Tag',
  task: 'Task',
  ticket: 'Ticket',
  user: 'User',
} as const;

export const TAGS_TYPES = {
  general: 'General',
  interest: 'Interest',
  opportunities: 'Opportunities'
} as const;

export const LOCALSTORAGE_SESSION_KEY = 'sessionData';
export const SESSION_END_TIME_KEY = 'endTime';
export const SESSION_DURATION_KEY = 'duration';

export const ALL_ASSIGNED_TO_SELECT_OPTION = {
  label: 'Assigned To',
  value: 'all'
};

export const WEBSOCKET_MESSAGE_TYPES = {
  updateContactsList: 'lastOpenedContactMessageType',
  updateKanban: 'salesPipelineMessageType'
};

export const MAX_LENGTH_OF_TAG = 50;
export const TAGS_FRONT_ID_KEY = 'frontId';
