import styled from 'styled-components';

import { Cross } from '@/assets';
import { COLORS } from '@constants/colors';
import { BOX_SHADOW } from '@constants/style';

export const WrapperProfilePanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 100%;
  margin-left: 20px;
  padding: 0px 5px;
  position: relative;
  background-color: ${COLORS.white};
  box-shadow: ${ BOX_SHADOW };
  border-radius: 10px;
`;

export const CloseIcon = styled(Cross)`
  position: absolute;
  right: 0;
  min-height: 12px;
  min-width: 12px;
  margin: 4px 20px 0 0;
  cursor: pointer;

  path {
    fill: ${COLORS.primaryText};
  }
`;

export const ProfilePanelHeader = styled.div`
  padding: 20px 15px 0 15px;
`;

export const ProfilePanelBody = styled.div`
  overflow: auto;
  padding: 20px 15px 20px 15px;
`;
