import { ValueLabelObj } from '@/shared/types/commonTypes';

export const DATE_BUTTONS_CONFIG: Array<ValueLabelObj<number, string>> = [
  {
    value: 1,
    label: '1d', // 1 day
  },
  {
    value: 2,
    label: '2d', // 2 days
  },
  {
    value: 3,
    label: '3d',
  },
  {
    value: 5,
    label: '5d',
  },
  {
    value: 7,
    label: '1w', // 1 week
  },
];
