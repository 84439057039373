import styled, { css } from 'styled-components';
import { MenuProps } from '@material-ui/core/Menu';

import { Dots } from '@/assets';
import { Button, Menu, MenuItem } from '@material-ui/core';

import { COLORS } from '@constants/colors';

import { DotsMenuButtonProps, IconWrapperProps, StyledMenuItemProps } from './types';

const SIZE = '20px';

export const DotsMenuButton = styled(Button)<DotsMenuButtonProps>`
  width: ${SIZE};
  min-width: ${SIZE};
  height: ${SIZE};
  padding: 0 !important;
  border-radius: 50%;
  background-color: ${COLORS.background};
  color: ${COLORS.primaryText};
  
  &:hover{
    ${({ $isActive }) => !$isActive && css`
      background-color: ${COLORS.bgActions};
    `};
  }
  
  ${({ $isActive }) =>  $isActive && css`
    background-color: ${COLORS.orange};
    color: ${COLORS.white};
  `};
  
  ${({ $marginRight }) => $marginRight && css`
    margin-right: ${$marginRight};
  `}
  
  ${({ $marginTop }) => $marginTop && css`
    margin-top: ${$marginTop};
  `}

  ${({ $marginBottom }) => $marginBottom && css`
    margin-bottom: ${$marginBottom};
  `}

  ${({ $marginLeft }) => $marginLeft && css`
    margin-left: ${$marginLeft};
  `}
`;

export const DotsMenuStyledDotIcon = styled(Dots)`
  & > path {
    fill: currentColor;
  }
`;

export const StyledMenu = styled(Menu)<MenuProps>``;

export const IconWrapper = styled.div<IconWrapperProps>`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  
   & > svg {
     width: 18px;
     height: 18px;
     
      & > path{
        ${({ $fillColor }) => $fillColor && css`
            fill: ${$fillColor};
        `};
      }
   }
`;

export const LabelWrapper = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`;


export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 20px;

    &:hover {
      background-color: transparent;
    }
  
    &:hover ${LabelWrapper} {
      color: ${COLORS.orange};
    }

    &:hover ${IconWrapper} {
      ${({ $iconHoverColor }) => $iconHoverColor && css`
          & > svg > path {
            fill: ${$iconHoverColor};
          }
      `};
    }

    ${({ $topSeparator }) => $topSeparator && css`
      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: ${COLORS.dividingLines};
      }
    `};
`;


