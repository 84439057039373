import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStore } from '@store';

import { AdditionalDetails, AssignedDetails, PrimaryDetails } from '@modules/TodoPopup/components';

import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';

import { TaskDetailsWrapper } from './style';


export const TaskDetails = observer(() => {
  
  const { control } = useFormContext();
  const { id: currentUserId } = useStore().SettingsStore.profile;

  const [ assignId ] = useWatch({
    control,
    name: [
      TO_DOS_FIELDS_NAMES.assignId,
    ]
  });

  const todoPopupLocalStore = useTodoPopupLocalStore();

  const {
    createdByData,
  } = todoPopupLocalStore;

  const isAssignedToEqualCurrentUser = useMemo(() => (
    assignId == currentUserId
  ), [assignId, createdByData]);

  return (
    <TaskDetailsWrapper>
      <HFHiddenInput 
        control={ control }
        defaultValue={ currentUserId }
        name={ TO_DOS_FIELDS_NAMES.createdBy }
      />
      <PrimaryDetails/>
      <AdditionalDetails
        isAssignedToEqualCurrentUser={ isAssignedToEqualCurrentUser }
      />
      <AssignedDetails
        isAssignedToEqualCurrentUser={ isAssignedToEqualCurrentUser }
      />
    </TaskDetailsWrapper>
  );
});
