import React, { memo } from 'react';

import { ListsRowItem, ListsRowFolder, ListsRowFolderWithLink } from './components';

import { ListsTableRowProps } from './types';

export const ListsTableRow = memo((props: ListsTableRowProps) => {

  if(props.isLink){
    return (
      <ListsRowFolderWithLink
        name={ props.name }
        onLinkClick={ props.onLinkClick }
      />
    );
  }

  if(props.isItem){
    return (
      <ListsRowItem
        filters={ props.filters }
        isCheckboxActive={ props.isCheckboxActive }
        item={ props.item }
        onAddContacts={ props.onAddContacts }
        onCheckboxClickCb={ props.onCheckboxClickCb }
        onClone={ props.onClone }
        onEditSetup={ props.onEditSetup }
        onExport={ props.onExport }
        onMove={ props.onMove }
        onRemove={ props.onRemove }
        onRowBookmarkCb={ props.onRowBookmarkCb }
        onViewContacts={ props.onViewContacts }
      />
    );
  }

  if(props.isFolder){
    return (
      <ListsRowFolder
        item={ props.item }
        moveToFolder={ props.moveToFolder }
        onDelete={ props.onDelete }
        onDropList={ props.onDropList }
        onRename={ props.onRename }
      />
    );
  }

  return null;
});

ListsTableRow.displayName = 'ListsTableRow';
