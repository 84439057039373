import * as yup from 'yup';

import { dateOutputValidation } from '@constants/validationPatterns';
import { STAGES, TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import { validationMessages } from '@constants/validationMessages';

import { IdType } from '@/shared/types/commonTypes';

export const validationSchema = yup.object().shape({
  [TO_DOS_FIELDS_NAMES.name]: yup.string().required(validationMessages.required),
  [TO_DOS_FIELDS_NAMES.linkedItemId]: yup.string()
    .nullable()
    .when(TO_DOS_FIELDS_NAMES.isLinkedContact, {
      is: (isLinkedContact: boolean) => isLinkedContact,
      then: schema => schema.required(validationMessages.required)
    }),
  [TO_DOS_FIELDS_NAMES.priority]: yup.string()
    .nullable()
    .test(
      'priority and stage with forNextMeeting',
      validationMessages.required,
      (value, context) => {
        const stage = context.parent[TO_DOS_FIELDS_NAMES.stage];
        if(!value && stage === STAGES.forNextMeeting || value && stage !== STAGES.forNextMeeting) {
          return true;
        }
        return false;
      },
    ),
  [TO_DOS_FIELDS_NAMES.stage]: yup.string().nullable().required(validationMessages.required),
  [TO_DOS_FIELDS_NAMES.assignId]: yup.string().nullable().required(validationMessages.required),
  [TO_DOS_FIELDS_NAMES.dueDate]: yup
    .string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'test',
      validationMessages.required,
      (value, context) => {
        const isAssigned = context.parent[TO_DOS_FIELDS_NAMES.isAssigned];

        if(!isAssigned && !value) {
          return false;
        }
        return true;
      },
    ),
  [TO_DOS_FIELDS_NAMES.reviewDate]: yup
    .string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'test',
      validationMessages.required,
      (value, context) => {
        const assignId = context.parent[TO_DOS_FIELDS_NAMES.assignId];
        const createFUTask = context.parent[TO_DOS_FIELDS_NAMES.createFUTask];
        const createFUTaskForAssignerToMonitor = context.parent[TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor];
        const createdBy = context.parent[TO_DOS_FIELDS_NAMES.createdBy];
        const id = context.parent[TO_DOS_FIELDS_NAMES.id];
        const isAssigned = context.parent[TO_DOS_FIELDS_NAMES.isAssigned];

        const isAssignedToEqualCurrentUser = assignId == createdBy;
    
        if(id && !isAssigned) {
          return true;
        }

        if(!value && (isAssigned || createFUTaskForAssignerToMonitor ||
          isAssigned || (!isAssignedToEqualCurrentUser && createFUTask))) {
          return false;
        }
        return true;
      },
    ),
  [TO_DOS_FIELDS_NAMES.frequency]: yup.string()
    .nullable()
    .when(TO_DOS_FIELDS_NAMES.requirring, {
      is: (requirring: boolean) => requirring,
      then: schema => schema.required(validationMessages.required)
    }),
  [TO_DOS_FIELDS_NAMES.endDate]: yup.string()
    .nullable()
    .when(TO_DOS_FIELDS_NAMES.requirring, {
      is: (requirring: boolean) => requirring,
      then: schema => schema
        .required(validationMessages.required)
        .matches(dateOutputValidation, validationMessages.invalidDate)
    }),
  [TO_DOS_FIELDS_NAMES.createFUTaskForAssignerToMonitor]: yup.boolean()
    .nullable()
    .when([TO_DOS_FIELDS_NAMES.assignedBy, TO_DOS_FIELDS_NAMES.assignId, TO_DOS_FIELDS_NAMES.id], {
      is: (assignedBy: IdType, assignId: IdType, id: IdType) => {
        if((Number(assignedBy) === Number(assignId)) && !id) {
          return false;
        }
        return assignedBy;
      },
      otherwise: schema => schema.transform(() => false)
    }),
});
