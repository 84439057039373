import React from 'react';

import Avatar from '@components/Avatar';
import Label from '@components/Label';
import Tooltip from '@components/Tooltip/Tooltip';

import { getUserTitle } from './utils';
import { getAvatarData } from '@/shared/utils/getAvatarData';

import {
  ActionsBox,
  ActiveStarIcon,
  CloseIcon,
  Container,
  ContentBox,
  CoreIcon,
  EditIcon,
  IconButton,
  StarIcon,
  SubUserName,
  UserName,
  UserNamesWrapper,
} from './styles';

import { UserItemProps } from './types';
import { COLORS } from '@constants/colors';

function UserItem({
  $color,
  $isClickable,
  additionalUserContent,
  containerStyle = {},
  disabled,
  disableTooltipAnimation,
  innerPadding = '5px',
  isHouseholdInTwoLines,
  isLinkedContactType,
  isMarginFixed,
  isStarActive,
  labelWidth = 0,
  onClick,
  onClose,
  onEdit,
  onStar,
  reverseNames,
  user,
  withCoreClassification,
}: UserItemProps) {
  const title = getUserTitle(user, reverseNames, isHouseholdInTwoLines);
  const { mainImageData, subImageData } = getAvatarData(user);

  return (
    <Container
      $color={ $color }
      $isClickable={ $isClickable }
      disabled={ disabled }
      innerPadding={ innerPadding }
      style={ containerStyle }
    >
      <ContentBox onClick={ onClick }>
        <Avatar
          $subType={ true }
          mainImageData={ mainImageData }
          subImageData={ subImageData }
        />
        {
          withCoreClassification &&
          'classification' in user &&
          <CoreIcon classificationType={ (user as any)?.coreClassification || user?.classification } />
        }
        { typeof title === 'string' &&
          <Tooltip
            data-custom-style={ 'user-name' }
            disableAnimation={ disableTooltipAnimation }
            displayOnlyOnOverflow
            title={ title }
          >
            <UserName labelWidth={ labelWidth } >
              { title }
            </UserName>
          </Tooltip>
        }
        { isHouseholdInTwoLines && typeof title !== 'string' &&
          <UserNamesWrapper>
            <Tooltip
              data-custom-style={ 'user-name' }
              disableAnimation={ disableTooltipAnimation }
              displayOnlyOnOverflow
              title={ title[0] }
            >
              <UserName labelWidth={ labelWidth }>{ title[0] }</UserName>
            </Tooltip>
            <Tooltip
              data-custom-style={ 'sub-user-name' }
              disableAnimation={ disableTooltipAnimation }
              displayOnlyOnOverflow
              title={ title[1] }
            >
              <SubUserName labelWidth={ labelWidth }>{ title[1] }</SubUserName>
            </Tooltip>
          </UserNamesWrapper>
        }
        { isLinkedContactType && 'linkedContactType' in user && user.linkedContactType &&
          <Label
            $bgColor={ COLORS.dividingLines }
            $color={ COLORS.helpText }
            StyleProps={ {
              marginRight: 0
            } }
          >
            { user.linkedContactType }
          </Label>
        }
        { additionalUserContent }
      </ContentBox>
      <ActionsBox isMarginFixed={ isMarginFixed }>
        {
          onStar &&
          <IconButton
            $star
            onClick={ onStar }
          >
            {
              isStarActive
                ? <ActiveStarIcon />
                : <StarIcon/>
            }
          </IconButton>
        }
        {
          onClose &&
          <IconButton
            $close
            onClick={ onClose }
          >
            <CloseIcon/>
          </IconButton>
        }
        {
          onEdit &&
          <IconButton
            $edit
            onClick={ onEdit }
          >
            <EditIcon />
          </IconButton>
        }
      </ActionsBox>
    </Container>
  );
}

export default UserItem;
