import React, { useCallback, useMemo } from 'react';

import { DoneMarkWithCircle, Task } from '@/assets';

import Avatar from '@components/Avatar';
import DotsMenu from '@components/DotsMenu';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import Table, { TypeSelectedRow } from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getDateContent } from '@/shared/utils/getDateContent';
import { getDueDateColor } from '@/shared/utils/getDueDateColor';
import { getFullName } from '@/shared/utils/fetchAutocompleteUtils';
import { getPriorityColor } from '@/shared/utils/getPriorityColor';

import { COLORS } from '@constants/colors';
import { COMMON_COLUMN_LEFT_PADDING } from '../../data';
import { NOTES_TASK_DOTS_MENU_OPTIONS, NOTES_TASK_DOTS_MENU_TYPES } from './data';
import { STAGES } from '@constants/todosData';

import { IconOuterWrapper, IconWrapper, InfoIconWrapper, InfoTextWrapper, InfoWrapper } from './styles';

import { NotesTaskAction } from './types';
import { NotesTasksTableRowProps } from './types';


const TABLE_NAME_CELL_STYLE = {
  style: {
    paddingLeft: 0
  }
};

const TABLE_COMMON_CELL_STYLE = {
  style: {
    paddingLeft: COMMON_COLUMN_LEFT_PADDING
  }
};

const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'todo-name';


export const NotesTasksTableRow = ({
  task,
  onTaskEdit,
}: NotesTasksTableRowProps) => {

  const {
    category,
    clientData,
    dueDate,
    isAssigned,
    name,
    priority,
    reviewDate,
    stage,
  } = task;

  const mainImageData = useMemo(() => getAvatarData(clientData).mainImageData, [ clientData ]);
  const clientFullName = useMemo(() => getFullName(clientData), [ clientData ]);

  const isCompleted = stage === STAGES.complete || stage === STAGES.skipped;

  const date = useMemo(() => {
    const actualDate = isAssigned ? reviewDate : dueDate;
    return {
      converted: getDateContent({
        dateString: actualDate,
        fallBackContent: '-'
      }),
      color: getDueDateColor(actualDate, isCompleted)
    };
  }, [dueDate, reviewDate, isAssigned]);

  const onTaskEditWrapper = useCallback(() => {
    onTaskEdit(task.id);
  }, [task.id, onTaskEdit]);

  const onMenuSelect = useCallback((action: NotesTaskAction) => {
    if (action === NOTES_TASK_DOTS_MENU_TYPES.viewEdit){
      onTaskEditWrapper();
    }
  }, [onTaskEditWrapper]);

  return (
    <Table.Row
      { ...(
        isCompleted
          ? {
            $typeSelectedRow: TypeSelectedRow.Stripes,
            selected: true
          }
          : {}
      ) }
      onDoubleClick={ onTaskEditWrapper }
    >
      <Table.Cell
        TableCellProps={ TABLE_NAME_CELL_STYLE }
        dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
        onClick={ onTaskEditWrapper }
      >
        <IconOuterWrapper>
          {
            stage === STAGES.complete &&
            <IconWrapper>
              <DoneMarkWithCircle/>
            </IconWrapper>
          }
        </IconOuterWrapper>
        <InfoWrapper>
          <InfoIconWrapper>
            <Task/>
          </InfoIconWrapper>
          <InfoTextWrapper>
            <TextWithTooltip
              $fontColor={ COLORS.orange }
              dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
              text={ name }
            />
            <TextWithTooltip
              $fontColor={ COLORS.primaryText }
              $fontSize={ 10 }
              text={ category }
            />
          </InfoTextWrapper>
        </InfoWrapper>
      </Table.Cell>
      <Table.Cell TableCellProps={ TABLE_COMMON_CELL_STYLE }>
        { priority ? 
          <ListItemWithColorIndicator
            indicatorColor={ getPriorityColor(priority) }
            label={ priority }
          /> :
          '-'
        }
      </Table.Cell>
      <Table.Cell TableCellProps={ TABLE_COMMON_CELL_STYLE } >
        <TextWithTooltip
          $fontColor={ date.color || COLORS.primaryText }
          text={ date.converted }
        />
      </Table.Cell>
      <Table.Cell>
        <Avatar
          mainFullName={ clientFullName }
          mainImageData={ mainImageData }
        />
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '10px' }
          items={ NOTES_TASK_DOTS_MENU_OPTIONS }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};
