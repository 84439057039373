import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { TwoLevelStyleProps } from '@modules/TwoLevelFilter/types';

export const FilterWrapper = styled.div<TwoLevelStyleProps>`
  display: flex;
  width: auto;
  min-height: 34px;
  padding-left: ${ ({ $isBeforeLine }) => $isBeforeLine && '8px' };
  padding-right: ${ ({ $isAfterLine }) => $isAfterLine && '8px' };
  margin-right: 5px;
  position: relative;
  box-sizing: border-box;

  & svg {
    ${ ({ $disabled }) => $disabled && 'opacity: 0.3'};
  }

  & > button {
    width: inherit;
    padding: 0 10px !important;
    border-radius: ${ ({ $borderRadius }) => $borderRadius && css`${$borderRadius}` };

    box-sizing: border-box;
    

    & > span {
      gap: 8px;
      justify-content: ${ ({ $isSelected }) => $isSelected && 'flex-start'};
    }
  }

  &::before, &::after {
    display: block;
    width: 1px;
    height: 60%;
    position: absolute;
    top: 20%;
    background-color: ${COLORS.borders};
  }

  ${ ({ $isBeforeLine }) => $isBeforeLine && css`
      &::before {
        content: '';
        left: 0px;
      }`};

  ${ ({ $isAfterLine }) => $isAfterLine && css`
      &::after {
        content: '';
        right: 0px;
      }`};
`;

export const FilterName = styled.div<TwoLevelStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 1px;

  & div:first-child {
    font-size: ${ ({ $isSelected }) => $isSelected ? '9px' : '12px'};
    line-height: 12px;
    color: ${ ({ $isSelected, $secondColor }) => $isSelected && $secondColor && css`${$secondColor}` };
  }

  & div:last-child {
    font-size: 12px;
    line-height: 12px;  
  }
`;

export const CategoryLabel = styled.div`
`;

export const ArrowIconWrapper = styled.div`
  margin-top: -1px;
`;
