import {
  addDays,
  addMinutes,
  addWeeks,
  format,
  getHours,
  getMinutes,
  isBefore,
  parse,
  set,
  setHours,
  startOfHour
} from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { isEmpty } from 'lodash';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { CALENDAR_VIEWS } from '@constants/common';
import {
  HOURS_MINUTES_SECONDS,
  TIMEZONE,
  YEAR_MONTH_DAY,
  YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS
} from '@constants/dateFormats';

import { AccountTimeZone, TimeZones } from '@/shared/types/settings';
import { Client, IdType } from '@/shared/types/commonTypes';
import { ClientConverter, ConvertActivityData, StartEndDateAndTime, TimeData } from '@/shared/types/activityPopup';
import { ConvertStartEndDateToUTCFormatItem, EventData } from './types';

export const detectOutOfWorkHours = (data: EventData, users: Array<ClientConverter>): boolean => {
  const { startTime, endTime } = data || {};
  if(!startTime || !endTime) {
    return false;
  }

  return users.some((user: ClientConverter) => {
    const { calendarEndTime, calendarStartTime } = user || {};
    if(!calendarEndTime && !calendarStartTime) {
      return false;
    }

    const [userStartHours] = calendarStartTime.split(':');
    const [userEndHours] = calendarEndTime.split(':');

    const calendarEnd = startOfHour(setHours(new Date(), Number(userEndHours)));
    let calendarStart = startOfHour(setHours(new Date(), Number(userStartHours)));

    if(isBefore(calendarEnd, calendarStart)) {
      calendarStart = startOfHour(addDays(calendarStart, -1));
    }

    const [startHours] = startTime.split(':');
    const [endHours] = endTime.split(':');
  
    let activityEnd = startOfHour(setHours(new Date(), Number(endHours)));
    let activityStart = startOfHour(setHours(new Date(), Number(startHours)));

    if(isBefore(activityEnd, activityStart)) {
      activityStart = startOfHour(addDays(activityStart, -1));
    }

    const isBeginOut = isBefore(activityStart, calendarStart);
    const isEndOut = isBefore(calendarEnd, activityEnd);

    return isBeginOut || isEndOut;
  });
};

export const getDateFromCurrWithOneWeek = (startDateString: string): string => {
  const start = parse(startDateString, YEAR_MONTH_DAY, new Date());
  const withOneWeekAdd = addWeeks(start, 1);
  return format(withOneWeekAdd, YEAR_MONTH_DAY);
};

export const convertSecondStringToMinutes = (secondsString: string) => Number(secondsString) / 60;

export const getTimeDataBasedOnView = ({ view, startDateObj, duration }: TimeData) => {
  if(view === CALENDAR_VIEWS.day || view === CALENDAR_VIEWS.week){
    // if open from button add activity we don't pass endDate
    const calculatedEndDateObj = addMinutes(startDateObj , duration);
    return getTimeData(startDateObj, calculatedEndDateObj);
  }

  if(view === CALENDAR_VIEWS.month){
    const now = new Date();
    const currHours = getHours(now);
    const currMinutes = getMinutes(now);
    const startOfStartDate = set(startDateObj, { hours: currHours, minutes: currMinutes });
    const newEndDateObj = addMinutes(startOfStartDate, duration);
    return getTimeData(startOfStartDate, newEndDateObj);
  }

  if(!view){
    const startDateObj = new Date();
    const endDateObj = addMinutes(startDateObj, duration);
    return getTimeData(startDateObj, endDateObj);
  }
};

export const getTimeData = (startDateObj: Date, endDateObj: Date, isAllDay?: string): StartEndDateAndTime => {
  const start = format(startDateObj, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
  const end = format(endDateObj, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
  const [startDate, startTime] = start.split(' ');
  const [endDate, endTime] = end.split(' ');

  if(isAllDay) {
    return {
      endDate,
      startDate,
    };
  }

  return {
    endDate,
    endTime,
    startDate,
    startTime,
  };
};

const FIELDS_TO_YES_NO = [
  'allDay',
  'recurring'
];

const TIMES_RANGE = [
  'startTime',
  'endTime'
];

export const convertActivityData = (
  data: ConvertActivityData,
  withoutTime: boolean,
  accountTimeZone: AccountTimeZone,
  timeZones: TimeZones
): ConvertActivityData => {
  const convertData = (Object.entries(data).reduce((acc, item) => {
    const [name, value] = item as [string, unknown];
    // eslint-disable-next-line no-mixed-operators
    if(withoutTime && TIMES_RANGE.includes(name)) {
      return acc;
    }

    if(FIELDS_TO_YES_NO.includes(name)) {
      acc[name] = booleanToYesNo(value as boolean);
      return acc;
    }

    if(name === 'clients' && Array.isArray(value)) {
      acc[name] = value.map((item: Client & { userId: IdType }) => ({
        // if we update from popup we need use userId, because here we use useFieldArray from useForm
        // and we can't use id field because it reserved by useForm
        // but if update activity by drag and drop we use data from grid list with id
        id: item.userId || item.id
      }));
      return acc;
    }

    if(name === 'type') {
      acc[name] = typeConverter({ isBackend: false, type: value });
      return acc;
    }

    acc[name] = value;
    return acc;
  },{} as Record<string, unknown>)
  ) as ConvertActivityData;

  const convertedToUTCData = convertStartEndDateToUTCFormat({
    startDate: convertData.startDate,
    startTime: convertData.startTime,
    endDate: convertData.endDate,
    endTime: convertData.endTime,
    isAllDay: yesNoToBoolean(convertData.allDay),
    createdDate: convertData.createdDate,
    createdTimeZone: timeZones.find(timeZone => timeZone.value === convertData.createdTimeZone) || accountTimeZone
  }, accountTimeZone);

  convertData.startDate = convertedToUTCData.startDate;
  convertData.startTime = convertedToUTCData.startTime;
  convertData.endDate = convertedToUTCData.endDate;
  convertData.endTime = convertedToUTCData.endTime;
  convertData.createdTimeZone = convertData.createdTimeZone || accountTimeZone.value;
  convertData.createdDate = convertData.createdDate ||
    format(new Date(), YEAR_MONTH_DAY) + 'T' + format(new Date(), `${HOURS_MINUTES_SECONDS}${TIMEZONE}`);

  return convertData;
};

const convertStartEndDateToUTCFormat = (item: ConvertStartEndDateToUTCFormatItem, accountTimeZone: AccountTimeZone) => {
  const { startDate, startTime, endDate, endTime, isAllDay, createdDate, createdTimeZone } = item;

  if(isAllDay) {
    return item;
  }

  if(createdDate && !isEmpty(createdTimeZone)) {
    const { date: convertedStartDate, time: convertedStartTime } = convertDateToUTCFormatEditMode(
      startDate, startTime, createdTimeZone, createdDate, accountTimeZone
    );
    const { date: convertedEndDate, time: convertedEndTime } = convertDateToUTCFormatEditMode(
      endDate, endTime, createdTimeZone, createdDate, accountTimeZone
    );

    return {
      startDate: convertedStartDate,
      startTime: convertedStartTime,
      endDate: convertedEndDate,
      endTime: convertedEndTime
    };
  }

  const { date: convertedStartDate, time: convertedStartTime } = convertDateToUTCFormat(startDate, startTime);
  const { date: convertedEndDate, time: convertedEndTime } = convertDateToUTCFormat(endDate, endTime);

  return {
    startDate: convertedStartDate,
    startTime: convertedStartTime,
    endDate: convertedEndDate,
    endTime: convertedEndTime
  };
};

const convertDateToUTCFormatEditMode = (
  date: string, time: string, createdTimeZone: AccountTimeZone, createdDate: Date, accountTimeZone: AccountTimeZone
) => {
  const localTimezoneOffset = getTimezoneOffset(accountTimeZone.label, new Date(`${date} ${time}`)) * -1;
  const createdTimezoneOffset = getTimezoneOffset(createdTimeZone.label, new Date(`${date} ${time}`)) * -1;
  const difference = localTimezoneOffset - createdTimezoneOffset;

  const eventLocalTime = new Date(new Date(`${date} ${time}`).getTime() + difference);
  const createdDateTimezoneOffset = getTimezoneOffset(createdTimeZone.label, createdDate);
  const utcDate = eventLocalTime.getTime() - createdDateTimezoneOffset;
  
  const dateStringArray = format(utcDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');
  
  return {
    date: dateStringArray[0],
    time: dateStringArray[1]
  };
};

const convertDateToUTCFormat = (date: string, time: string) => {
  const offsetCurrentTimezone = new Date().getTimezoneOffset() * 60 * 1000;
  const eventDate = new Date(`${date} ${time}`).getTime();

  const dateWithCurrentTimezoneOffset = new Date(offsetCurrentTimezone + eventDate);
  
  const dateStringArray = format(dateWithCurrentTimezoneOffset, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');
  
  return {
    date: dateStringArray[0],
    time: dateStringArray[1]
  };
};