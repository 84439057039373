import React from 'react';

import { Item, Wrapper } from './styles';

import { TimeAdderProps } from './types';

export const TimeAdder = ({
  buttonsConfig,
  disabled,
  onSelect,
  selectedValue
}: TimeAdderProps) => (
  <Wrapper>
    {
      buttonsConfig.map(({ value, label }) => (
        <Item
          $selected={ value === selectedValue }
          disabled={ disabled }
          key={ label }
          onClick={ () => onSelect(value) }
        >
          { label }
        </Item>
      ))
    }
  </Wrapper>
);