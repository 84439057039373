import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { COLORS } from '@constants/colors';
import { TagWrapperProps } from './types';

export const Container = styled.div`
  flex: 1;
`;

export const StyledTextField = styled(TextField)``;

export const TagWrapper = styled.div<TagWrapperProps>`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 28px;
  padding: 0px 10px;
  ${({ checked }) => checked && css` background-color: ${COLORS.select};`};
  font-size: 14px;
  box-sizing: border-box;
`;