import React from 'react';

import { CONTACT_TYPES_BUTTONS } from './data';

import PopupButtonPanelItems from '@components/PopupButtonPanelItems';

import { TabBarProps } from './types';


export const TabBar = ({
  isButtonsDisabled,
  onChange,
  selectedType
}: TabBarProps) => {
  return (
    <PopupButtonPanelItems
      ButtonProps={ {
        disabled: isButtonsDisabled
      } }
      currentActionType={ selectedType }
      elemMarginRight={ '10px' }
      onClick={ onChange }
      options={ CONTACT_TYPES_BUTTONS }
    />
  );
};
