import { makeAutoObservable, flow, reaction, IReactionDisposer } from 'mobx';
import axios from 'axios';

import { Store } from '@store';
import CommonTableStore from '@services/store/commonTableStore';

import {
  getEmployees,
  deleteEmployee,
  saveEmployee,
} from '@services/api/contacts/contacts';
import { MODAL_TYPE } from '@constants/modalTypes';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';

import { GridResponse, SaveEmployeeErrorResponse } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { ContactItem } from '@/shared/types/contact';
import { EmployeeItem, EmployeeOwnFields } from '@/shared/types/employee';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { getMultipleSortParams, setWhereNameAndOrderNameFilterParams } from '@/shared/utils/filterUtils';
import { employeeNormalizers } from './normalizers';
// eslint-disable-next-line max-len
import { ChangePrimaryContactPopup } from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/BusinessContentTabs/components/BusinessEmployees/components';

class EmployeeStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  isPageActive = false;
  notificationHelper: NotificationHelper;
  onPageChangeReaction: IReactionDisposer;
  onSortChangeReaction: IReactionDisposer;
  table: CommonTableStore<EmployeeItem>;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow,
      getEmployeesList: flow,
      deleteEmployee: flow,
      deleteEmployeeWithoutUpdatePage: flow.bound,
      getEmployeesListWithLoad: flow,
      saveEmployees: flow.bound
    });

    this.coreStore = coreStore;
    this.table = new CommonTableStore<EmployeeItem>();

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.employee
    );

    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.onSortChangeReaction = this.createOnSortChangeReaction();
  }

  createOnPageChangeReaction() {
    return reaction(
      () => this.table.currentPage,
      () => {
        if (this.isPageActive) {
          this.getEmployeesListWithLoad();
        }
      }
    );
  }

  createOnSortChangeReaction() {
    return reaction(
      () => this.table.multipleSorting,
      () => {
        if (this.isPageActive) {
          this.getEmployeesListWithLoad();
        }
      }
    );
  }

  *getEmployeesList(contactId: IdType) {
    if (!this.isPageActive) {
      return;
    }

    this.table.clearItems(true);

    const employeesResponse: GridResponse = yield getEmployees({
      contactId,
      page: this.table.currentPage,
      ...getMultipleSortParams(this.table.multipleSorting)
    });

    this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.contactProfileEmployees], {
      page: this.table.currentPage,
      ...setWhereNameAndOrderNameFilterParams({
        whereFilters: {},
        orderFilters: this.table.multipleSorting
      }),
    });

    this.table.setPaginationData(employeesResponse.data.data);
    this.table.items = employeesResponse.data.data.data;
  }

  *getEmployeesListWithLoad() {
    if (!this.isPageActive) {
      return;
    }

    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => this.getEmployeesList(contactDetailsStore.currentContact!.id),
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error })
      });
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *init(contactId: IdType) {
    this.isPageActive = true;

    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData:ContactItem = yield contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }
      this.setFiltersFromServer();

      yield this.getEmployeesList(contactId);
    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.coreStore.ContactDetailsStore.setPermission(false);
        this.coreStore.ContactDetailsStore.currentContact = null;
      }
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.contactProfileEmployees]
    ))?.value;

    this.table.onPageChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
  }

  async *deleteEmployeeWithoutUpdatePage(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      yield await deleteEmployee({ id });
    } catch (error) {
      console.error(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *deleteEmployee(id: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteEmployee({ id }),
        onError: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.error,
          message: 'The deleting of the Employee is failed',
          customAction: 'errorDeleteEmployee'
        }),
        onSuccess: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.success,
          message: 'The Employee has been removed successfully',
          customAction: 'deleteEmployee'
        })
      });

      yield this.getEmployeesList(contactDetailsStore.currentContact!.id);

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.error(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveEmployees(data: EmployeeOwnFields) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const contactId = contactDetailsStore.currentContact!.id;

    let isValidationError = false;

    try {
      contactDetailsStore.toggleLoadState(true);
      const normalizedData = employeeNormalizers(data);

      if(normalizedData.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveEmployee({
            contactId,
            relatedEmployees: [normalizedData]
          }),
          onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
          continueOnError: false,
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveEmployee({
            contactId,
            relatedEmployees: [normalizedData]
          }),
          onError: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success }),
          continueOnError: false,
        });
      }

      yield this.getEmployeesList(contactId);
    } catch (error) {
      if(axios.isAxiosError(error)) {
        isValidationError = true;
        const errorResponse = error.response?.data as SaveEmployeeErrorResponse;
        const relatedEmployeesError = errorResponse.data.error.relatedEmployees;
        
        if(relatedEmployeesError) {
          this.coreStore.ModalStore.openModal({
            modalType: MODAL_TYPE.CONFIRM_BUSINESS_EMPLOYEE_PRIMARY_CONTACT_CHANGE,
            modalProps: {
              data,
              onSaveEmployee: this.saveEmployees,
            },
            component: ChangePrimaryContactPopup
          });
        }
      }
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  reset() {
    this.table.resetTable();
    this.isPageActive = false;
  }
}

export default EmployeeStore;
