import axios from 'axios';
import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';

import { Store } from '@store';

import CommonTableStore from '@services/store/commonTableStore';
import { getContactsTodos, massFlagUpdate, updateFromGrid } from '@services/api/todos/todos';
import { deleteTodo, getPopupSettings, saveTodo, updateTodo } from '@services/api/addAndEditTask/addAndEditTask';

import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { getOverdueFilterParamsObject } from '@/shared/utils/getOverdueFilterParamsObject';
import {
  getMultipleSortParams,
  getFilterParams,
  getGlobalFlagged,
  setWhereNameAndOrderNameFilterParams,
} from '@/shared/utils/filterUtils';
import { normalizer } from './normalizers';
import { todoItemNormalizer, todoPopupSettingsNormalizer } from '@/shared/utils/toDosNormalizers';

import {
  DEFAULT_OVERDUE_FILTER_TYPE,
  INIT_OVERDUE_FILTER_STATE,
  INIT_STAGE_FILTER_STATE,
  STAGES,
  TO_DOS_FIELDS_NAMES,
  TODOS_COLUMNS_IDS,
  TODOS_FILTER_NAMES
} from '@constants/todosData';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';

import { ContactItem } from '@/shared/types/contact';
import {
  BackendTodoFormFields,
  CategoryFilter,
  DeleteTodoParams, OverdueFilter,
  TodoGridFormField,
  TodoItem,
  TodoPopupSettings,
  TodoPopupSettingsResponse
} from '@/shared/types/todos';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import {
  FilterData,
  Filters,
  GridResponse,

} from './types';
import { NoteTagItem } from '@/shared/types/tags';
import { isNeedToUpdatePage } from '@services/store/todosStore/utils';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES, SortDirectionNames } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

export class ContactDetailsTodosStore {
  categoryFilter: CategoryFilter | null = null;
  contact: ContactItem | null = null;
  coreStore: Store;
  filterData: FilterData = {} as FilterData;
  filters: Filters = {
    [TO_DOS_FIELDS_NAMES.stage]: INIT_STAGE_FILTER_STATE,
  };
  isPageActive: boolean = false;
  overdueFilter: OverdueFilter = INIT_OVERDUE_FILTER_STATE as OverdueFilter;
  predefinedTags: Array<NoteTagItem> = [];
  previousTodoToggleState: boolean = false;
  table: CommonTableStore<TodoItem>;
  todoPopupSettings: TodoPopupSettings = {} as TodoPopupSettings;

  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  onFilterChangeReaction: IReactionDisposer;
  onCategoryFilterChangeReaction: IReactionDisposer;
  onOverdueFilterChangeReaction: IReactionDisposer;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow.bound,
      getTodos: flow.bound,
      updateFlags: flow.bound,
      updateFromGrid: flow.bound,
      onSave: flow.bound,
      onDelete: flow.bound,
      setCategoryFilter: action.bound,
    });
    this.coreStore = coreStore;
    this.table = new CommonTableStore<TodoItem>({
      onGlobalFlaggedChangeReactionCallback: this.getTodos,
      onPageChangeReactionCallback: this.getTodos,
      onSortReactionCallback: this.getTodos,
    });

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      coreStore.NotificationsStore,
      ENTITY_NAMES.task
    );

    this.onCategoryFilterChangeReaction = this.createOnCategoryFilterChangeReaction();
    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onOverdueFilterChangeReaction = this.createOnOverdueFilterChangeReaction();
  }

  *getTodos(contactId?: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const currentContactId = contactId ? contactId : contactDetailsStore.currentContact!.id;
    contactDetailsStore.toggleLoadState(true);

    this.onFilterChangeReaction();
    this.setFilters({
      [TODOS_FILTER_NAMES.linkedContactId]: currentContactId
    });
    this.onFilterChangeReaction = this.createOnFilterChangeReaction();

    try {
      const start = async () => {
        const response: GridResponse = await getContactsTodos({
          primaryContactId: currentContactId,
          page: this.table.currentPage,
          ...getFilterParams(this.filters),
          ...getFilterParams(this.overdueFilter.params ?? null),
          ...getFilterParams(this.categoryFilter),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
          customparams: [{
            overdueFilterType: this.overdueFilter.type,
          }],
        });

        this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.contactProfileToDos], {
          page: this.table.currentPage,
          ...setWhereNameAndOrderNameFilterParams({
            whereFilters: {
              ...this.filters,
              [TODOS_FILTER_NAMES.category]: [this.categoryFilter?.category],
              [TODOS_FILTER_NAMES.request]: [this.categoryFilter?.request],
              flagged: [Number(this.table.globalFlagged)]
            },
            orderFilters: this.table.multipleSorting
          }),
          customparams: [JSON.stringify({
            overdueFilterType: this.overdueFilter.type,
          })],
        });

        const currentUserId = this.coreStore.SettingsStore.profile.id;
        const normalized = normalizer(response.data.data.data, currentUserId);

        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.table.items = normalized.items.map((item: TodoItem) => todoItemNormalizer(item));
        this.filterData = normalized.filterData;
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });

    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onDelete(params: DeleteTodoParams) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteTodo(params),
        onError: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getTodos();

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onSave(data: BackendTodoFormFields) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      if(data.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateTodo(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: true,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: true,
            uniqueKey: data.id
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveTodo(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: false,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: false,
            uniqueKey: data.id
          }),
        });
      }
      yield this.getTodos();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean) {
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield massFlagUpdate(params);

      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, { flagged: state });
      });
      if(this.table.globalFlagged) {
        this.getTodos();
      }
    } catch (error) {
      console.log(error);
    }
  }

  *updateFromGrid(data: TodoGridFormField) {
    const isNeedToReloadPage = isNeedToUpdatePage({
      updateData: data,
      tableItems: this.table.items,
      sortingState: this.table.multipleSorting,
      overDueFilter: this.overdueFilter,
      previousCompleteToggleState: this.previousTodoToggleState,
      filtersState: this.filters
    });

    const contactDetailsStore = this.coreStore.ContactDetailsStore;

    if(isNeedToReloadPage){
      contactDetailsStore.toggleLoadState(true);
    }

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => updateFromGrid(data),
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: data.id
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          uniqueKey: data.id
        }),
      });

      if(isNeedToReloadPage){
        yield this.getTodos();

        this.table.checkAndSetIfPageOutOfRange();
      } else {
        this.table.updateItemById(data.id, data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if(isNeedToReloadPage){
        contactDetailsStore.toggleLoadState(false);
      }

      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  async *init(contactId: IdType){
    this.isPageActive = true;
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData: ContactItem = yield await contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }

      const todoPopupSettingsResp: TodoPopupSettingsResponse = yield getPopupSettings();
      this.todoPopupSettings = todoPopupSettingsNormalizer(todoPopupSettingsResp.data.data);

      this.setFiltersFromServer();

      yield this.getTodos(contactId);

    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.coreStore.ContactDetailsStore.setPermission(false);
        this.coreStore.ContactDetailsStore.currentContact = null;
      }
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.contactProfileToDos]
    ))?.value;

    this.onCategoryFilterChangeReaction();
    this.onFilterChangeReaction();
    this.onOverdueFilterChangeReaction();
    this.table.onPageChangeReaction();
    this.table.onGlobalFlaggedChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);

      if(serverFilterValue.filters?.where) {
        const { stage, assignId, category, request, flagged } = serverFilterValue.filters.where;

        this.filters = {
          [TODOS_FILTER_NAMES.stage]: stage,
          [TODOS_FILTER_NAMES.users]: assignId 
        };
        this.categoryFilter = {
          [TODOS_FILTER_NAMES.category]: category?.[0],
          [TODOS_FILTER_NAMES.request]: request?.[0],
        };
        const toggleState = stage?.some((stage: any) => (
          stage === STAGES.complete || stage === STAGES.skipped
        ));
        this.setPreviousTodoToggleState(toggleState);
        this.table.setGlobalFlaggedFilters(Boolean(flagged?.[0]) || false);
      }

      if(serverFilterValue.customparams) {
        const customparams = JSON.parse(serverFilterValue.customparams);
        const overdueFilterType = customparams?.overdueFilterType || DEFAULT_OVERDUE_FILTER_TYPE;
        this.setOverdueFilter(
          overdueFilterType,
          getOverdueFilterParamsObject(overdueFilterType)
        );
      }
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    } else {
      this.filters = {
        [TODOS_FILTER_NAMES.stage]: INIT_STAGE_FILTER_STATE,
      };
      this.table.multipleSorting = isEmpty(this.table.multipleSorting) && {
        [TODOS_COLUMNS_IDS.dueDate]: SortDirectionNames.Asc
      };
      this.overdueFilter = INIT_OVERDUE_FILTER_STATE as OverdueFilter;
      this.previousTodoToggleState = false;
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onGlobalFlaggedChangeReaction = this.table.createOnGlobalFlaggedChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
    this.onCategoryFilterChangeReaction = this.createOnCategoryFilterChangeReaction();
    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onOverdueFilterChangeReaction = this.createOnOverdueFilterChangeReaction();
  }

  createOnCategoryFilterChangeReaction(){
    return reaction(
      () => this.categoryFilter,
      () => {
        if(this.isPageActive) {
          this.table.setCurrentPage(1);
          this.getTodos(this.contact?.id);
        }
      }
    );
  }

  createOnFilterChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive) {
          this.table.setCurrentPage(1);
          this.getTodos(this.contact?.id);
        }
      }, 1500)
    );
  }

  createOnOverdueFilterChangeReaction(){
    return reaction(
      () => this.overdueFilter.type,
      () => {
        if(this.isPageActive) {
          this.table.setCurrentPage(1);
          this.getTodos(this.contact?.id);
        }
      }
    );
  }


  reset() {
    this.onFilterChangeReaction();
    this.onOverdueFilterChangeReaction();

    this.isPageActive = false;

    this.contact = null;
    this.categoryFilter = null;
    this.filters = {
      [TODOS_FILTER_NAMES.stage]: INIT_STAGE_FILTER_STATE,
    };
    this.filterData = {} as FilterData;
    this.table.resetTable();
    this.todoPopupSettings = {} as TodoPopupSettings;
    this.overdueFilter = INIT_OVERDUE_FILTER_STATE as OverdueFilter;
    this.previousTodoToggleState = false;

    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onOverdueFilterChangeReaction = this.createOnOverdueFilterChangeReaction();
  }

  setCategoryFilter(newCategory: CategoryFilter | null) {
    this.categoryFilter = newCategory;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setOverdueFilter(type: string, params?: {[x: string]: string | boolean}) {
    this.overdueFilter = {
      type,
      params,
    };
  }

  setPreviousTodoToggleState(state: boolean){
    this.previousTodoToggleState = state;
  }
}
