import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getActiveClientByName } from '@services/api/common/common';
import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { LABEL_WIDTH } from '@modules/TodoPopup/data';
import {
  DEFAULT_STAGE,
  DUE_DATE_TYPES,
  DUE_DATE_TYPES_OPTIONS,
  STAGES,
  TO_DOS_FIELDS_NAMES
} from '@constants/todosData';
import { LISTBOX_PROPS } from './data';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { getAssignedToCurrentUserSelectProps, getAssignedToOtherUserSelectProps } from './utils';
import { getIsDueDateExpired } from '@modules/TodoPopup/utils';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getPriorityColor } from '@/shared/utils/getPriorityColor';
import { getRecurringOptions } from '@/shared/utils/getRecurringOptions';

import FormBlock from '@components/FormBlock';
import HFCheckbox from '@modules/HookFormComponents/HFCheckbox';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import UserItem from '@components/NewUserItem';

import {
  DateWrapper,
  InfoIconWrapper,
  LeftColumnWrapper,
  RequrringWrapper,
  RightColumnWrapper
} from '../styles';

import { Client, ValueLabelObj } from '@/shared/types/commonTypes';
import { AdditionalDetailsProps } from './types';


export const AdditionalDetails = observer(({
  isAssignedToEqualCurrentUser
}: AdditionalDetailsProps) => {

  const { control, resetField, setValue, clearErrors } = useFormContext();
  const [
    requirring,
    dueDate,
    assignId,
    stage,
    dueDateType,
    reviewDate,
    isAssigned
  ] = useWatch({
    control,
    name: [
      TO_DOS_FIELDS_NAMES.requirring,
      TO_DOS_FIELDS_NAMES.dueDate,
      TO_DOS_FIELDS_NAMES.assignId,
      TO_DOS_FIELDS_NAMES.stage,
      TO_DOS_FIELDS_NAMES.dueDateType,
      TO_DOS_FIELDS_NAMES.reviewDate,
      TO_DOS_FIELDS_NAMES.isAssigned,
    ]
  });

  const {
    id,
    isGlobalDisabled,
    createdByData,
    assignedToData,
    todoPopupSettings: {
      priority,
      recurringFrequency
    }
  } = useTodoPopupLocalStore();

  const recurringOptionsBasedOnDate = useMemo(() => (
    getRecurringOptions(dueDate, recurringFrequency)
  ), [ dueDate, recurringFrequency ]);

  const isDueDateExpired = useMemo(() => getIsDueDateExpired(dueDate), [ dueDate ]);
  const isReviewDateExpired = useMemo(() => getIsDueDateExpired(reviewDate), [ reviewDate ]);
  const isRecurringDisabled = Boolean(id) || isGlobalDisabled || stage === STAGES.forNextMeeting;

  const selectStageProps = useMemo(() => {
    if(isAssignedToEqualCurrentUser || !assignId) {
      return getAssignedToCurrentUserSelectProps(id);
    }
    if(!isAssignedToEqualCurrentUser && assignId) {
      return getAssignedToOtherUserSelectProps(id);
    }
    return {
      options: [],
      resettable: true,
      resetValue: '',
    };
  }, [isAssignedToEqualCurrentUser, assignId]);

  useEffect(() => {
    if((!stage || !id) && !isAssignedToEqualCurrentUser && assignId) {
      resetField(TO_DOS_FIELDS_NAMES.stage, { defaultValue: DEFAULT_STAGE });
    }
    if(!assignId) {
      resetField(TO_DOS_FIELDS_NAMES.stage, { defaultValue: null });
    }
  }, [assignId, isAssignedToEqualCurrentUser, resetField]);

  useEffect(() => {
    if(stage === STAGES.forNextMeeting) {
      setValue(TO_DOS_FIELDS_NAMES.priority, null);
      clearErrors(TO_DOS_FIELDS_NAMES.priority);
      setValue(TO_DOS_FIELDS_NAMES.requirring, false);
    }
  }, [stage]);

  useEffect(() => {
    if(dueDateType === DUE_DATE_TYPES.mustDoToday) {
      const field = isAssigned ? TO_DOS_FIELDS_NAMES.reviewDate : TO_DOS_FIELDS_NAMES.dueDate;
      setValue(field, format(new Date(), YEAR_MONTH_DAY));
      clearErrors(field);
    } 
  }, [dueDateType, isAssigned]);

  return (
    <FormBlock marginBottom={ 15 } paddingBottom={ 15 }>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            isRequired={ true }
            label={ 'Assigned to' }
          >
            <HFLinkedContact
              AutocompleteProps={ {
                ListboxProps: LISTBOX_PROPS
              } }
              FetchProps={ {
                getParams: getNameParam,
                requestSender: getActiveClientByName,
                responseConverter: usersResponseConverter,
              } }
              UserItemProps={ {
                disabled: isGlobalDisabled,
              } }
              control={ control }
              disabled={ isGlobalDisabled }
              initLinkedContact={ assignedToData }
              name={ TO_DOS_FIELDS_NAMES.assignId }
              withoutQueryMode={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.rightColumn }
            isRequired={ true }
            label={ 'Stage' }
          >
            <HFSelect
              control={ control }
              disabled={ isGlobalDisabled || (!isAssignedToEqualCurrentUser && assignId) }
              name={ TO_DOS_FIELDS_NAMES.stage }
              { ...selectStageProps }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            isRequired={ true }
            label={ 'Created by' }
          >
            <UserItem
              disabled={ isGlobalDisabled }
              innerPadding={ '0px' }
              user={ createdByData ?? {} as Client }
              withCoreClassification={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.rightColumn }
            isRequired={ true }
            label={ 'Priority' }
          >
            <HFSelect
              control={ control }
              disabled={ isGlobalDisabled || stage === STAGES.forNextMeeting }
              name={ TO_DOS_FIELDS_NAMES.priority }
              options={ priority || [] }
              placeholder={ 'Select Priority' }
              renderItem={ (option) => {
                if(!option) {
                  return '';
                }
                const { value, label } = option as ValueLabelObj;
                return (
                  <ListItemWithColorIndicator
                    indicatorColor={ getPriorityColor(value) }
                    label={ label }
                  />
                );
              } }
              resetLabel={ 'Select Priority' }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            isRequired={ true }
            label={ 'Due/Review Date' }
          >
            { isAssigned ?
              <DateWrapper>
                <HFDatePicker
                  control={ control }
                  disabled={ isGlobalDisabled || dueDateType === DUE_DATE_TYPES.mustDoToday }
                  name={ TO_DOS_FIELDS_NAMES.reviewDate }
                />
                {
                  isReviewDateExpired &&
                  <InfoIconWrapper>
                    <InfoIcon
                      colorScheme={ INFO_COLOR_SCHEME.onlyRed }
                      disabled={ isGlobalDisabled }
                      helpText={ 'Date is expired' }
                      type={ INFO_ICON_TYPES.alertInfo }
                    />
                  </InfoIconWrapper>
                }
              </DateWrapper> : 
              <DateWrapper>
                <HFDatePicker
                  control={ control }
                  disabled={ isGlobalDisabled || dueDateType === DUE_DATE_TYPES.mustDoToday }
                  name={ TO_DOS_FIELDS_NAMES.dueDate }
                />
                {
                  isDueDateExpired &&
                  <InfoIconWrapper>
                    <InfoIcon
                      colorScheme={ INFO_COLOR_SCHEME.onlyRed }
                      disabled={ isGlobalDisabled }
                      helpText={ 'Date is expired' }
                      type={ INFO_ICON_TYPES.alertInfo }
                    />
                  </InfoIconWrapper>
                }
              </DateWrapper>
            }
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.rightColumn }
            label={ 'Due Date Type' }
          >
            <HFSelect
              ControllerProps={ {
                shouldUnregister: true
              } }            
              control={ control }
              disabled={ isGlobalDisabled }
              name={ TO_DOS_FIELDS_NAMES.dueDateType }
              options={ DUE_DATE_TYPES_OPTIONS || [] }
              placeholder={ 'Select Due Date Type' }
              resetLabel={ 'Select Due Date Type' }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>

      { requirring && stage !== STAGES.forNextMeeting &&
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABEL_WIDTH.common }
              isRequired={ true }
              label={ 'Frequency' }
            >
              <HFSelect
                ControllerProps={ {
                  shouldUnregister: true
                } }
                placeholder={ 'Select frequency' }
                control={ control }
                disabled={ isRecurringDisabled }
                name={ TO_DOS_FIELDS_NAMES.frequency }
                options={ recurringOptionsBasedOnDate || [] }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>

          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LABEL_WIDTH.rightColumn }
              isRequired={ true }
              label={ 'End Date' }
            >
              <HFDatePicker
                ControllerProps={ {
                  shouldUnregister: true
                } }
                control={ control }
                disabled={ isRecurringDisabled }
                name={ TO_DOS_FIELDS_NAMES.endDate }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
      }

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <RequrringWrapper>
            <HFCheckbox
              ControllerProps={ {
                defaultValue: false
              } }
              control={ control }
              disabled={ isRecurringDisabled || isGlobalDisabled }
              label={ 'Recurring' }
              name={ TO_DOS_FIELDS_NAMES.requirring }
            />
          </RequrringWrapper>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});
