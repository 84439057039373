import styled from 'styled-components';

import { IconWrapperProps } from './types';

export const ButtonsGroupWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
export const BtnWrapper = styled.div`
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

export const BubbleFiltersWrapper = styled.div`
  margin-left: 20px;
  margin-right: auto;

  & > div > button {
    margin-right: 5px;
  }
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  height: 18px;

  & > div {
    min-width: auto;
    margin-right: 6px;
  }
`;

